import React, { useEffect } from 'react';
import UserHeader from '../../components/Headers/UserHeader'
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    Table,
    Container,
    Row,
    Col,
    Button,
    Modal,
    Input,
    Form,
  } from "reactstrap";
  import { useParams, Link } from "react-router-dom";
import { useState } from 'react';
import axios from 'axios';
import { useForm } from "react-hook-form";
import { ReactSession } from 'react-client-session';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ChromePicker } from 'react-color';
import toast from 'react-hot-toast';

  const SchoolProfile = () => {
    const { handleSubmit, register, reset } = useForm();
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const { id  } = useParams()
    let  [school, setSchool] = useState({})
    let  [loading, setLoader] = useState(true)
    let [user, setUser] = React.useState([])
    let [orders, setOrders] = useState([])
    let [classCampus, setCampus] = useState('')
    let [teacherCampus, setTeacherCampus] = useState('')
    let [teacherClass, setClassroom] = useState('')
    let [teacherModalOpen, setTeacherModal] = useState(false)
    let [campusesModalOpen, setCampusModal] = useState(false)
    let [gradesModalOpen, setGradeModal] = useState(false)
    let [classroomModalOpen, setClassroomModal] = useState(false)
    let [schoolEditModalOpen, setSchoolEditModal] = useState(false)
    let [createCampusError, setCreateCampusError] = useState(false)
    let [createGradeError, setCreateGradeError] = useState(false)
    let [createClassroomError, setCreateClassroomError] = useState(false)
    let [createTeacherError, setCreateTeacherError] = useState(false)
    let [editSchoolError, setEditSchoolError] = useState(false)
    const [schoolYear, setSchoolYear] = useState([])
    const [allSchoolYears, setAllSchoolYears] = useState([])
    let [schoolYearDropDownOpen, setSchoolYearDropDown] = useState(false)
    // for classroom in teacher creation form
    const [isDisabled, setIsDisabled] = useState(true);
    // Holiday Stuff
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [addNewHoliday, toggleHolidayButton] = useState(false)
    const [addNewEmail, toggleNewEmail] = useState(false)
    const [schoolHolidays, setHolidays] = useState([])
    const [holidayLabel, setHolidayLabel] = useState('')
    let [ colorPickerOpen, setColorPicker ] = useState(false)
    let [ pickerColor, setColor  ] = useState('#ffffff')
    const token = ReactSession.get("token");
    let [missingLabel, setMissingLabel] = useState(false)

    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }

    const getSchoolYears = async () => {
      let res = await axios.get(BASE_URL + '/school_years/', config)
      if (res.status === 200) {
        setAllSchoolYears(res.data)
        if (localStorage.getItem('selectedSchoolYear') != null) {
          let oldYear = JSON.parse(localStorage.getItem('selectedSchoolYear'))
          for (let sy of res.data ) {
            if (sy.id === oldYear.id) {
              setSchoolYear(sy)
            }
          }
        } else {
          for (let sy of res.data ) {
            if (sy.yearStatus.status_name === 'Active') {
              setSchoolYear(sy)
              localStorage.setItem('selectedSchoolYear', JSON.stringify(sy))
            }
          }
        }
      }
    }

    const getSchoolInfo = async (year = {id: undefined}) => {
      if (localStorage.getItem('selectedSchoolYear') != null) {
        const oldYear = JSON.parse(localStorage.getItem('selectedSchoolYear'))
        let data = await axios.get(BASE_URL + '/schools/'  + id + `?year=${oldYear.id}`)
        setSchool(data.data)
        setColor(data.data.color)
        setLoader(false)
      } else {
        let data = await axios.get(BASE_URL + '/schools/'  + id + `?year=${year.id}`)
        setSchool(data.data)
        setColor(data.data.color)
        setLoader(false)
      }
    }

    const createSchoolEmail = async (data) => {
      setLoader(true)
      if (!data.email || !data.name) return
      let emailData = {
        name: data.name,
        email: data.email,
        schoolID: school.id,
      }
      let res = await axios.post(BASE_URL + '/schools/email', emailData, config)
      reset({ email: '', name: '' })
      toggleNewEmail(!addNewEmail)
      if (res.status === 200) {
        getSchoolInfo()
      } else {
        setLoader(false)
      }
    }

    const deleteSchoolEmail = async (id) => {
      setLoader(true)
      await axios.delete(BASE_URL + '/schools/email?schoolEmailID=' + id, config)
      getSchoolInfo()
      setLoader(false)
    }
  
    useEffect(() => {
      getSchoolInfo()
      getSchoolYears()
     }, [])

    const createCampus = async (data, e) => {
      let campusData = {
        school_id: school.id,
        name: data.campus_name,
        address: data.campus_address,
        city: data.campus_city,
        state: data.campus_state,
        zip_code: data.campus_zip_code,
        year_id: schoolYear.id
      }

      try {
        await axios.post(BASE_URL + '/campuses', campusData, config)
        setCampusModal(false)
        getSchoolInfo()
        reset({
          campus_name: '',
          campus_address: '',
          campus_city: '',
          campus_state: '',
          campus_zip_code: ''
        })
      } catch (error) {
        setCreateCampusError('createCampus: ' + error.message)
      }
    }

     const createGrade = async (data, e) => {
       let gradeData = {
         school_id: school.id,
         name: data.grade_name,
         year_id: schoolYear.id,
       }
       try{
        await axios.post(BASE_URL + '/grades/create', gradeData, config)
        setGradeModal(false)
        getSchoolInfo()
        reset({ grade_name: '' })
       } catch (error) {
        setCreateGradeError('createGrade: ' + error.message)
       }
     }

     const createClassroom = async (data, e) => {
       let classData = {
         schoolId: school.id,
         campusId: parseInt(classCampus),
         deliveryStartTime: data.start_time ,
         deliveryEndTime: data.end_time,
         classNumber: data.class_number,
         year_id: schoolYear.id
       }
       try {
          await axios.post(BASE_URL + '/classrooms', classData, config)
          setClassroomModal(false)
          getSchoolInfo()
          reset({ start_time: '', end_time: '', class_number: '' })
       } catch (error) {
          setCreateClassroomError('createClassroom: ' + error.message)
       }
     }

     const createTeacher = async (data, e ) => {
       if (!teacherClass) {
         // missing data handling
          setCreateTeacherError('createTeacher: Make sure your information is complete')
         return 
       }

       let teacherData = {
        schoolId: school.id,
        classroomId: parseInt(teacherClass),
        firstName: data.teacher_first_name,
        lastName: data.teacher_last_name,
        email: data.teacher_email,
        phoneNumber: data.teacher_phone,
        year_id: schoolYear.id
       }
       try {
        await axios.post(BASE_URL + '/teachers', teacherData, config)
        setTeacherModal(false)
        getSchoolInfo()
        reset({
          teacher_first_name: '',
          teacher_last_name: '',
          teacher_email: '',
          teacher_phone: ''
        })
       } catch (error) {
        setCreateTeacherError('createTeacher: ' + error.message)
       }
     }
     
     const updateSchool = async (data, e ) => {
       console.log('updating school')
       let schoolData = {}
       if(data.school_address) {
        schoolData['address'] = data.school_address
       }
       if (data.school_code) {
        schoolData['school_code'] = data.school_code
       }
       if (data.school_email) {
        schoolData['email'] = data.school_email
       }
       if (data.school_name) {
        schoolData['name'] = data.school_name
       }
       if (data.school_phone) {
        schoolData['phone_number'] = data.school_phone
       }

       try{
        await axios.put(BASE_URL + '/schools/'+ school.id, schoolData, config)
        setSchoolEditModal(false)
        getSchoolInfo()
       }catch(error){
        setEditSchoolError('updateSchool' + error.message)
       }
     }

     const toggleSchoolYear = () => {
      setSchoolYearDropDown(!schoolYearDropDownOpen)
     }

     const selectSchoolYear = (schYear) => {
        setSchoolYear(schYear)
        localStorage.setItem('selectedSchoolYear', JSON.stringify(schYear))
        getSchoolInfo(schYear)
     }

    function groupDates(dates) {
      const sortedDates = [...dates].sort((a, b) => new Date(a.date) - new Date(b.date));
      const dateRanges = [];
      let currentRange = null;
      for (const date of sortedDates) {
        if (currentRange === null || new Date(date.date) - new Date(currentRange.end.date) > 86400000) {
          // Dates are not contiguous, start a new range
          currentRange = { start: date, end: date };
          dateRanges.push(currentRange);
        } else {
          // Dates are contiguous, extend current range
          currentRange.end = date;
        }
      }
      for (const range of dateRanges) {
        if (range.start.date === range.end.date) {
          range.label = `${formatDate(range.start.date)}`;
        } else {
          range.label = `${formatDate(range.start.date)} - ${formatDate(range.end.date)}`;
        }
      }
      return dateRanges;
    }
    
    function formatDate(date) {
      return Intl.DateTimeFormat('en-US').format(new Date(Date.parse(date)));
    }

      const onDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
      };
  
      const getDatesBetween = (start, end) => {
        const dates = [];
        console.log(start, end)
        let zeroSeconds = 'T00:00:00.000Z'
        for(var arr=[],dt=new Date(start); dt <= new Date(end); dt.setDate(dt.getDate()+1)) {
          arr.push(new Date(dt).toISOString());
        }
        console.log(arr)
        return arr;

      }
  
    const createNewHoliday = async () => {
      if (!holidayLabel) {
        setMissingLabel(true)
        return 
      }
      let allDates = getDatesBetween(startDate, endDate)
      if (!endDate) {
        allDates = getDatesBetween(startDate, startDate)
      }
      if (allDates) {
        let dates = {
          schoolID: school.id,
          dates: allDates,
          label: holidayLabel,
        }
        try {
          let res = await axios.post(BASE_URL + "/dates/create", dates, config)
          if (res.status === 200) {
            getSchoolInfo()
            toggleHolidayButton(false)
          } else {
            console.error('Error creating holiday:', res.data);
          }
        } catch (error) {
          console.error('Error creating holiday:', error);
        }
      }
    }



    //     let res = await axios.post(BASE_URL + "/dates/create", dates, config)
    //     getSchoolInfo()
    //     toggleHolidayButton(false)
    //   }
    // }

    const deleteHolidays = async (dateID) => {
      setLoader(true);
      const dateRange = findDateRange(dateID, school.lockedDates);
      if (dateRange) {
        for (const date of dateRange.dates) {
          await axios.delete(BASE_URL + '/dates/delete/' + date.id, config);
        }
      } else {
        await axios.delete(BASE_URL + '/dates/delete/' + dateID, config);
      }
      getSchoolInfo();
    };
    
    function findDateRange(dateID, dates) {
      const sortedDates = [...dates].sort((a, b) => new Date(a.date) - new Date(b.date));
      for (let i = 0; i < sortedDates.length; i++) {
        if (sortedDates[i].id === dateID) {
          if (i === sortedDates.length - 1 || new Date(sortedDates[i+1].date) - new Date(sortedDates[i].date) > 86400000) {
            // Single date
            return null;
          } else {
            // Date range
            const dateRange = { start: sortedDates[i], end: sortedDates[i+1], dates: [] };
            for (let j = i; j < sortedDates.length; j++) {
              if (new Date(sortedDates[j].date) - new Date(dateRange.start.date) <= (86400000 * (j - i + 1))) {
                dateRange.dates.push(sortedDates[j]);
              } else {
                break;
              }
            }
            return dateRange;
          }
        }
      }
      return null;
    }

    function formatPhoneNumber(phoneNumber) {
      const areaCode = phoneNumber.slice(0, 3);
      const firstPart = phoneNumber.slice(3, 6);
      const secondPart = phoneNumber.slice(6, 10);
      return `${areaCode}-${firstPart}-${secondPart}`;
    }

    const changeColor = async () => {
      setColorPicker(!colorPickerOpen)
      let schoolData ={ 
        color: pickerColor
      }
      await axios.put(BASE_URL + '/schools/'+ school.id, schoolData, config)
      setLoader(true)
      getSchoolInfo()
    }

    const hideSchool = async () =>  {
      setLoader(true)
      let res = await axios.delete(BASE_URL + '/schools/' + school.id, config)
      if (res.status === 200) {
        toast.success('School updated')
      }
      getSchoolInfo()
    }

    return (
      <>
      <UserHeader user={user}/>
      {/* Page content */}
      <Container className="mt--7" fluid>
    { !loading &&   
       <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
          <Card className="card-profile shadow">
            <CardBody className="pt-0 pt-md-4">
            <div className="text-left">
                  <Row className="justify-content-between">
                    <Col xs="8">
                      <h3> {school.name}<span className="font-weight-light">, {school.abbreviation}</span></h3>
                    </Col>
                  </Row>
                  <Row >
                      <Button className='primary-color pr-4' onClick={(e) => setSchoolEditModal(true)}>Edit</Button>
                      <Button size="sm" color={school.is_deleted ? 'success' : 'danger'}  onClick={(e) => {
                        if (window.confirm('Are you sure you want to update this school?')) {
                          hideSchool()
                        }
                      }} > {school.is_deleted ? 'Unhide': 'Hide'}  </Button>
                    </Row>
                  <div className="h5 mt-4">
                    <i className="fa-solid fa-phone"> </i> Phone: { formatPhoneNumber(school.phone_number) }                  </div>
                  <div className="h5 mt-4">
                    <i className="ni ni-email-83" />  Email: {school.email}
                  </div>
                  <div className="h5 mt-4">
                    <i className="ni ni-email-83" />  Email: {school.email}
                  </div>
                  <div className="h5 mt-4">
                    <i className="ni ni-briefcase-24" /> Code: {school.school_code}
                  </div>
                  <div className="h5 mt-4">
                    <i className="ni ni-app" /> Status: {school.is_deleted ? 'Deleted' : 'Active'}
                  </div>
                  <div className="h5 mt-4">
                    <i className="fa-solid fa-tag"> </i> Color: {pickerColor} <div style={{ 
                      width: '15px',
                      height: '15px',
                      backgroundColor: pickerColor,
                      display: 'inline-block',
                      borderRadius: '50%',
                      
                      }}></div>
                      <br></br>
                      <br></br>
                    {  colorPickerOpen ? <Row className='align-items-end justify-content-center'>
                      <ChromePicker 
                           color={pickerColor}
                           onChange={ (e) => setColor(e.hex)}
                    /> 
                    <Button size='sm' color='success' style={{ height: '30px', marginLeft: '10px' }} onClick={() => changeColor()}> Save </Button>
                    </Row>: <Button 
                      size='sm' 
                      color='success'
                      onClick={() => {
                        setColorPicker(!colorPickerOpen)
                      }}
                    > 
                     <i className="fa-solid fa-tag"> </i> 
                     </Button> }
                  </div>
                  <hr className="my-4" />
                 
              </div>
            <Row>
              <div className="col">
                <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                    <div>
                    <span className="heading">{school.students.length}</span>
                      <span className="description">Students</span>
                    </div>
                    <div>
                      <span className="heading">{school.teachers.length}</span>
                    <span className="description">Teachers</span>
                    </div>
                    <div>
                      <span className="heading">{school.campuses.length}</span>
                      <span className="description">Campuses</span>
                    </div>
                </div>
                <div className="d-flex justify-content-center card-profile-stats">
                  <div>
                    <span className="heading">{school.grades.length}</span>
                    <span className="description">Grades</span>
                    </div>   <div>
                    <span className="heading">{school.classrooms.length}</span>
                    <span className="description">Classrooms</span>
                    </div>
                  </div>
                </div>
            </Row>
              </CardBody>
          </Card>
          <br></br>
          <Card className="card-profile shadow">
              <CardHeader className="bg-white border-0">
                {/* <Row className="align-items-center justify-content-between"> */}
                <Row className = 'align-items-center justify-content-between pl-5'>
                  <Col>
                    <h3>Email List</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
              <Col>
                <ul>
                  {
                    school && school.schoolEmails.map((email) => {
                       return <li key={email.id}>
                        <Row className='justify-content-around'>
                          <span>{email.name} - {email.email}</span>
                          {/* <Col>
                          </Col> */}
                          <Button size='sm' color='danger' onClick={() => deleteSchoolEmail(email.id)}>X</Button>
                        </Row>
                        <br></br>
                        </li>
                    })
                  }
                </ul>
              { !addNewEmail ? <Button onClick={() => toggleNewEmail(!addNewEmail)}>Add New</Button> : (
                <Col className='justify-content-center'>
                  <Form onSubmit={handleSubmit(createSchoolEmail)}>

                  <label>
                    Name
                  </label>
                  <input  className='form-control  w-50'
                  {...register('name')}
                  ></input>
                  <br></br>
                  <label>
                    Email
                  </label>
                  <input
                  type='email'
                  className='form-control'
                  {...register('email')}
                  ></input>
                <br></br>
                <br></br>
                <Row className='align-items-center justify-content-center'>
                  <Button
                  size="sm"
                  onClick={() => toggleNewEmail(!addNewEmail)}
                  >
                    Cancel
                 </Button>
                  <Button
                   size="sm"
                   type='submit'
                  //  onClick={() => createSchoolEmail()}
                  >
                    Create
                  </Button>
                </Row>
                   </Form>
                </Col>)
              }
              </Col>
              </CardBody>
           </Card>
           <br></br>
          <Card className="card-profile shadow">
              <CardHeader className="bg-white border-0">
                {/* <Row className="align-items-center justify-content-between"> */}
                <Row className = 'align-items-center justify-content-between pl-5'>
                  <Col>
                    <h3>Locked Dates and Holidays</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
              <Col>
              <ul>
                {school && groupDates(school.lockedDates).map((dateRange) => (
                  <li key={dateRange.start.id}>
                    <React.Fragment>
                      <Row className='justify-content-start'>
                        <Col sm={8}>
                          <span>{dateRange.start.label}</span>
                        </Col>
                        <Col sm={1}>
                          <Button size='sm' color='danger' onClick={() => deleteHolidays(dateRange.start.id)}>X</Button>
                        </Col>
                      </Row>
                      <Row className='justify-content-start'>
                        <Col md={9}>
                          <span>{dateRange.label}</span>
                        </Col>
                      </Row>
                      <br></br>
                    </React.Fragment>
                  </li>
                ))}
              </ul>
              { !addNewHoliday ? <Button onClick={() => toggleHolidayButton(!addNewHoliday)}>Add New</Button> : (
                <Col className='justify-content-center'>
                  <Form>
                <label
                className="form-control-label" htmlFor="basic-url"
                >
                  Holiday Label
                </label>
                <input 
                className='form-control w-50' 
                value={holidayLabel} 
                onChange={(e) => {
                  setHolidayLabel(e.target.value)
                }}
                required
                ></input>
                <br></br>
                <DatePicker
                  className='form-control'
                  selectsRange={true}
                  selected={startDate}
                  onChange={onDateChange}
                  startDate={startDate}
                  endDate={endDate}
                  isClearable={true}
                  />
                <br></br>
                <br></br>
                <Row className='align-items-center justify-content-center'>
                  <Button
                  size="sm"
                  onClick={() => toggleHolidayButton(!addNewHoliday)}
                  >
                    Cancel
                 </Button>
                  <Button
                   size="sm"
                   type='submit'
                    onClick={() => createNewHoliday()}
                  >
                    Create
                  </Button>
                </Row>
                   </Form>
                </Col>)
              }
              </Col>
              </CardBody>
           </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
          <Card className="shadow">
              <CardHeader className="bg-white border-0">
              <Row className="align-items-center justify-content-between">
                <Row className = 'align-items-center justify-content-between pl-5'>
                  <Col>
                    <h3>School Info</h3>
                  </Col>
                </Row>
                <Row className='pr-5'>
                <Dropdown
                  toggle={toggleSchoolYear}
                  isOpen={schoolYearDropDownOpen}
                >
                    <DropdownToggle
                      className="btn text-green"
                      role="button"
                      size="sm"
                      >
                        <span>{schoolYear.name}</span>
                    </DropdownToggle>
                    <DropdownMenu>
                    {allSchoolYears.map((schYear) => {
                      return <DropdownItem
                              key={schYear.id}
                              onClick={() => selectSchoolYear(schYear)}
                            >
                              {schYear.name}
                            </DropdownItem>
                    })}
                    </DropdownMenu>
                  </Dropdown>
                </Row>
              </Row>
              </CardHeader>
         </Card>
                  <br></br>
                <Card className='shadow bg-white'>
                <CardHeader className="bg-white border-0">
                  <h2 className="mb-1">
                  Orders
                  </h2>
                </CardHeader>
                <CardBody>
                  <Table>
                  <thead className="thead-light">
                      <tr className='text-center'>
                        <th scope="col" >Name</th>
                        <th scope="col" > Classroom</th>
                        <th scope="col" > Role</th>
                        <th scope="col" > More</th>
                      </tr>
                    </thead>
                  <tbody>
                {school.meals.length > 0 ? school.meals.slice(0,3).map((meal) => (
                  <tr className='text-center'>
                    <td>
                      {meal.receiver_name}
                    </td>
                    <td>
                      {meal.classroom.class_number}
                    </td>
                     <td>
                      {meal.receiver_role}
                    </td>
                    <td>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="btn-icon-only text-light"
                          role="button"
                          size="sm"
                          color="success"
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                                tag={Link}
                                to={"/admin/meal/" + meal.id}
                            >
                              View
                            </DropdownItem>
                        </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                  </tr>
                  )) :
                  <tr>
                    <td>Meals table is empty</td>
                  </tr>
                }
                </tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                </CardFooter>
                </Card>
                  <br />
                  <Card className='shadow'>
                    <CardHeader className="bg-white border-0">
                    <Row className='justify-content-between align-items-center'>
                      <h2 className="mb-1 pl-4">
                      Campuses
                      </h2>
                      <Button className='primary-color pr-4' onClick={(e) => setCampusModal(true)}>Create</Button>
                    </Row>
                    </CardHeader>
                    <CardBody>
                    <Table>
                    <thead className="thead-light">
                      <tr className='text-center'>
                        <th>ID</th>
                        <th>Campus Name</th>
                        <th>Address</th>
                      </tr>
                    </thead>
                    <tbody>
                  {school.campuses.length > 0 ? school.campuses.slice(0,3).map((campus) => (
                      <tr className='text-center'>
                        <td>{campus.id}</td>
                        <td>{campus.name}</td>
                        <td>{campus.address}</td>
                      </tr>
                    )) :
                    <tr>
                     <td>Campuses table is empty</td>
                    </tr>
                    }
                      </tbody>
                    </Table>
                  </CardBody>
                  <CardFooter>
                    {school.campuses.length > 0 ? 
                      <Row className='justify-content-end'>
                          <Link to={'/admin/schools/' + school.id + '/campuses'} className='pr-4'>See More ...</Link>
                      </Row>
                        : 
                      <span></span>
                    }
                  </CardFooter>
                  </Card>
                  <br></br>
                  <Card className='shadow'>
                  <CardHeader>
                    <Row className='justify-content-between align-items-center'>
                        <h2 className="mb-1 pl-4">
                          Grades
                        </h2>
                        <Button className='primary-color pr-4' onClick={(e) => setGradeModal(true)}>Create</Button>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Table>
                        <thead  className="thead-light">
                          <tr className='text-center'>
                            <th>ID</th>
                            <th>Name</th>
                            {/* <th>More</th> */}
                          </tr>
                        </thead>
                        <tbody className='text-center'>
                        {
                          school.grades.length > 0 ? school.grades.slice(0,3).map((grade) => (
                            <tr className='text-center'>
                              <td>{grade.id}</td>
                              <td>
                              {grade.name}
                              </td>
                              {/* <td> */}
                              {/* <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                             
                            </DropdownMenu>
                          </UncontrolledDropdown> */}
                              {/* </td> */}
                            </tr>
                          )) :
                          <tr className='text-left'>
                            <td>Grades table is empty</td>
                          </tr>
                        }
                        </tbody>
                    </Table>
                  </CardBody>
                  <CardFooter>
                    {school.grades.length > 0 ? 
                            <Row className='justify-content-end'>
                                <Link to={'/admin/schools/' + school.id + '/grades' } className='pr-4'>See More ...</Link>
                            </Row>
                            : 
                            <span></span>
                    }  
                  </CardFooter>
                  </Card>
                  <br />
                  <Card className='shadow'>
                  <CardHeader>
                    <Row className='justify-content-between align-items-center'>
                        <h2 className="mb-1 pl-4">
                        Classrooms
                        </h2>
                        <Button className='primary-color pr-4' onClick={(e) => setClassroomModal(true)}>Create</Button>
                    </Row>
                  </CardHeader>
                  <CardBody>
                  <Row>
                    <Table>
                        <thead className='thead-light'>
                          <tr className='text-center'>
                            <th>ID</th>
                            <th>Classroom</th>
                            <th>Campus</th>
                            {/* <th>More</th> */}
                          </tr>
                        </thead>
                        <tbody className='text-center'>
                        {
                          school.classrooms.length > 0 ? school.classrooms.slice(0,3).map((croom) => (
                            <tr>
                              <td>{croom.id}</td>
                              <td>
                              {croom.class_number}
                              </td>
                              <td>{croom.campus.name}</td>
                              {/* <td>
                              <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                              </td> */}
                            </tr>
                          )) :
                          <tr className='text-left'>
                            <td>Classroom table is empty</td>
                          </tr>
                        }
                        </tbody>
                    </Table>
                  </Row>
                  </CardBody>
                  <CardFooter>
                    {school.classrooms.length > 0 ? 
                      <Row className='justify-content-end'>
                          <Link to={'/admin/schools/' + school.id + '/classrooms'}className='pr-4'>See More ...</Link>
                      </Row>
                        : 
                        <span></span>
                      }
                  </CardFooter>
                </Card>
                <br></br>
                <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className='justify-content-between align-items-center'>
                    <h2 className="mb-1 pl-4">
                    Teachers
                    </h2>
                    <Button className='primary-color pr-4' onClick={(e) => setTeacherModal(true)}>Create</Button>
                  </Row>
                </CardHeader>
                <CardBody className="pt-0 pt-md-4 bg-white" >
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr className='text-center'>
                        <th scope="col" >ID</th>
                        <th scope="col" >Name</th>
                        <th scope="col" > Email</th>
                      </tr>
                    </thead>
                    <tbody>
                    {school.teachers.length > 0 ? school.teachers.slice(0,3).map((teacher) => (
                      <tr className='text-center'>
                      <td>{teacher.id}</td>
                      <td>{teacher.first_name} {teacher.last_name}</td>
                      <td>{teacher.email}</td>
                      </tr>
                    )):
                    <tr>
                      <td>Teachers table is empty</td>
                    </tr>
                  }
                  </tbody>
                  </Table>
                </CardBody>
                <CardFooter>
                  {school.teachers.length > 0 ? 
                      <Row className='justify-content-end'>
                          <Link to ={'/admin/schools/' + school.id + '/teachers'} className='pr-4'>See More ...</Link>
                      </Row>
                      : 
                      <span></span>
                    }   
                </CardFooter>
                </Card>
                <br></br>
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                  <Row className='justify-content-between align-items-center'>
                    <h2 className="mb-1 pl-4">
                    Students
                    </h2>
                  </Row>
                  </CardHeader>
                  <CardBody className="pt-0 pt-md-4 bg-white">
                <Table>
                <thead className="thead-light">
                    <tr  className='text-center'>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Is Allowed To Order</th>
                      {/* <th>More</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {school.students.length > 0 ? school.students.slice(0,3).map((student) => (
                      <tr className='text-center'>
                      <td>{student.first_name}</td>
                      <td>{student.last_name}</td>
                      <td>{student.has_permission_to_order}</td>
                      {/* <td className="text-center">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td> */}
                      </tr>
                    )):
                    <tr>
                      <td>Students table is empty</td>
                    </tr>
                  }
                  </tbody>
                </Table>
                </CardBody>
                <CardFooter>
                  {school.students.length > 0 ? 
                      <Row className='justify-content-end'>
                          <Link to ={'/admin/schools/' + school.id + '/students'} className='pr-4'>See More ...</Link>
                      </Row>
                      : 
                      <span></span>
                    }   
                </CardFooter>
                </Card>
          </Col>
      </Row>
      }
    </Container>
    <Modal isOpen={schoolEditModalOpen}>
      <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                Edit School
                </h5>
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => setSchoolEditModal(false)}
                >
                <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="text-warning ml-3">{(editSchoolError) ? <p>{editSchoolError}</p> : ''}</div>

            <Form onSubmit={handleSubmit(updateSchool)}>
            <div className="modal-body">
                <Row>
                <Col >
                        <label
                        className="form-control-label"
                        htmlFor="school_name"
                        >
                        Name
                        </label>
                        <input
                        className="form-control"
                        id="school_name"
                        placeholder={school.name}
                        type="text"
                        {...register('school_name')}
                        
                        />
                    </Col>
                    
                </Row>
              <br />
              <Row>
                <Col >
                        <label
                        className="form-control-label"
                        htmlFor="school_address"
                        >
                        Address
                        </label>
                        <input
                        className="form-control"
                        id="school_address"
                        placeholder={school.address}
                        type="text"
                        {...register('school_address')}
                        />
                    </Col>
                    
                </Row>
              <br />
                <Row>
                <Col lg="6">
                      <label
                      className="form-control-label"
                      htmlFor="school_phone"
                      >
                      Phone Number
                      </label>
                      <input
                      className="form-control"
                      id="school_phone"
                      placeholder={school.phone_number}
                      type="phone"
                      {...register('school_phone')}
                      />
                </Col>
                <Col lg="6">
                    <label
                    className="form-control-label"
                    htmlFor="school_email"
                    >
                    Email 
                    </label>
                    <input
                    className="form-control"
                    id="school_email"
                    placeholder={school.email}
                    type="email"
                    {...register('school_email')}
                    />
                </Col>
                </Row>
                <br/>
                <Row>
                <Col lg="6">
                      <label
                      className="form-control-label"
                      htmlFor="school_code"
                      >
                      Code
                      </label>
                      <input
                      className="form-control"
                      id="school_code"
                      placeholder={school.school_code}
                      type="phone"
                      {...register('school_code')}
                      />
                </Col>
                
                </Row>
                
            </div>
            <div className="modal-footer">
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={(e) => setSchoolEditModal(false)}
                >
                Close
                </Button>
                <Button
                    color="primary"
                    data-dismiss="modal"
                    type="submit"
                >
                Save
                </Button>
            </div>
          </Form>
      </Modal>
      <Modal isOpen={teacherModalOpen}>
      <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                Create A Teacher
                </h5>
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => setTeacherModal(false)}
                >
                <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="text-warning ml-3">{(createTeacherError) ? <p>{createTeacherError}</p> : ''}</div>

            <Form onSubmit={handleSubmit(createTeacher)}>
            <div className="modal-body">
                <Row>
                <Col lg="6">
                        <label
                        className="form-control-label"
                        htmlFor="teacher_first_name"
                        >
                        Name
                        </label>
                        <input
                        className="form-control"
                        id="teacher_first_name"
                        placeholder="Name"
                        type="text"
                        {...register('teacher_first_name')}
                        required
                        />
                    </Col>
                    <Col lg="6">
                        <label
                        className="form-control-label"
                        htmlFor="teacher_last_name"
                        >
                        Last name 
                        </label>
                        <input
                        className="form-control"
                        id="teacher_last_name"
                        placeholder="Last Name"
                        type="text"
                        {...register('teacher_last_name')}
                        required
                        />
                    </Col>
                </Row>
              <br />
                <Row>
                <Col lg="6">
                      <label
                      className="form-control-label"
                      htmlFor="teacher_phone"
                      >
                      Phone Number
                      </label>
                      <input
                      className="form-control"
                      id="teacher_phone"
                      placeholder="(123) 435-1234"
                      type="phone"
                      {...register('teacher_phone')}
                      required
                      />
                </Col>
                <Col lg="6">
                    <label
                    className="form-control-label"
                    htmlFor="teacher_email"
                    >
                    Email 
                    </label>
                    <input
                    className="form-control"
                    id="teacher_phone"
                    placeholder="teacher@edu.org"
                    type="email"
                    {...register('teacher_email')}
                    required
                    />
                </Col>
                </Row>
                <br />
                <Row>
                  <Col lg="6">
                        <label
                        className="form-control-label"
                        htmlFor="teacher_campus"
                        >
                        Campus 
                        </label>
                        <Input
                          className="form-control"
                          id="teacher_campus"
                          type="select"
                          {...register('teacher_campus')}
                          required
                          onChange={(e) => {
                            setTeacherCampus(`${e.target.value}`)
                            setIsDisabled(false);
                          }}
                          value={teacherCampus}
                        >
                          <option value=''>Select a campus</option>
                          {school.campuses && school.campuses.map((campus) => <option key={campus.id} value={campus.id}>{campus.name}</option>)}
                      </Input>
                    </Col>
                    
                    <Col lg="6">
                        <label
                        className="form-control-label"
                        htmlFor="teacher_classroom"
                        >
                        Classroom 
                        </label>
                        <Input
                          className="form-control"
                          id="teacher_classroom"
                          type="select"
                          {...register('teacher_classroom')}
                          required
                          onChange={(e) => setClassroom(e.target.value)}
                          value={teacherClass}
                          disabled={isDisabled}
                        >
                          <option value=''>Select a classroom</option>
                          {school.classrooms && school.classrooms.filter(classroom => classroom.campus_id == teacherCampus).map((filteredClassroom) => <option key={filteredClassroom.id} value={filteredClassroom.id}>{filteredClassroom.class_number}</option>)}
                          {/* {teacherCampus && teacherCampus.map((classroom) => <option key={classroom.id} value={classroom.id}>{classroom.class_number}</option>)} */}
                      </Input>
                    </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={() =>  setTeacherModal(false)}
                >
                Close
                </Button>
                <Button
                    color="primary"
                    data-dismiss="modal"
                    type="submit"
                >
                Save
                </Button>
            </div>
          </Form>
      </Modal>
      <Modal isOpen={campusesModalOpen}>
      <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                Create A Campus
                </h5>
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => setCampusModal(false)}
                >
                <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="text-warning ml-3">{(createCampusError) ? <p>{createCampusError}</p> : ''}</div>

            <Form onSubmit={handleSubmit(createCampus)}>
            <div className="modal-body">
                <Row>
                <Col lg="6">
                    <label
                    className="form-control-label"
                    htmlFor="campus_name"
                    >
                    Name
                    </label>
                    <input
                    className="form-control"
                    id="campus_name"
                    placeholder="Name"
                    type="text"
                    {...register('campus_name')}
                    required
                    />
                  </Col>
                    <Col lg="6">
                      <label
                      className="form-control-label"
                      htmlFor="campus_address"
                      >
                      Address 
                      </label>
                      <input
                      className="form-control"
                      id="mod-price"
                      placeholder="Campus Address"
                      type="text"
                      {...register('campus_address')}
                      required
                      />
                    </Col>
                </Row>
              <br />
                <Row>
                <Col lg="4">
                      <label
                      className="form-control-label"
                      htmlFor="campus_city"
                      >
                      Campus City
                      </label>
                      <input
                      className="form-control"
                      id="campus_city"
                      placeholder="City"
                      type="text"
                      {...register('campus_city')}
                      required
                      />
                </Col>
                  <Col lg="4">
                      <label
                      className="form-control-label"
                      htmlFor="campus_state"
                      >
                      Campus State 
                      </label>
                      <input
                      className="form-control"
                      id="campus_state"
                      placeholder="FL"
                      type="text"
                      value='FL'
                      {...register('campus_state')}
                      required
                      />
                  </Col>
                  <Col lg="4">
                      <label
                      className="form-control-label"
                      htmlFor="campus_zip_code"
                      >
                      Zip Code 
                      </label>
                      <input
                      className="form-control"
                      id="campus_zip_code"
                      placeholder="ZipCode"
                      type="text"
                      {...register('campus_zip_code')}
                      required
                      />
                  </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={(e) => setCampusModal(false)}
                >
                Close
                </Button>
                <Button
                    color="primary"
                    data-dismiss="modal"
                    type="submit"
                >
                Save
                </Button>
            </div>
          </Form>
      </Modal>
      <Modal isOpen={gradesModalOpen}>
      <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                Create a School Grade
                </h5>
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => setGradeModal(false)}
                >
                <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="text-warning ml-3">{(createGradeError) ? <p>{createGradeError}</p> : ''}</div>

            <Form onSubmit={handleSubmit(createGrade)}>
            <div className="modal-body">
                <Row>
                  <Col lg="8">
                          <label
                            className="form-control-label"
                            htmlFor="grade_name"
                          >
                          Grade Name
                          </label>
                          <input
                            className="form-control"
                            id="campus_name"
                            placeholder="Name"
                            type="text"
                            {...register('grade_name')}
                            required
                          />
                      </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  type="button"
                  onClick={(e) => setGradeModal(false)}
                >
                Close
                </Button>
                <Button
                  color="primary"
                  data-dismiss="modal"
                  type="submit"
                >
                Save
                </Button>
            </div>
          </Form>
      </Modal>
      <Modal isOpen={classroomModalOpen}>
        <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
            Create a Classroom
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => setClassroomModal(false)}
            >
            <span aria-hidden={true}>×</span>
            </button>
        </div>
        <div className="text-warning ml-3">{(createClassroomError) ? <p>{createClassroomError}</p> : ''}</div>

        <Form onSubmit={handleSubmit(createClassroom)}>
            <div className="modal-body">
                <Row>
                <Col lg="6">
                      <label
                      className="form-control-label"
                      htmlFor="class_number"
                      >
                      Class Number
                      </label>
                      <input
                      className="form-control"
                      id="class_number"
                      placeholder="Name"
                      type="text"
                      {...register('class_number')}
                      required
                      />
                    </Col>
                    <Col lg="6">
                        <label
                        className="form-control-label"
                        htmlFor="classroom_campus"
                        >
                        Campus 
                        </label>
                        <Input
                        className="form-control"
                        id="classroom_campus"
                        type="select"
                        {...register('classroom_campus')}
                        onChange={(e) => setCampus(e.target.value)}
                        value={classCampus}
                        required
                        >
                          <option value="" hidden>Select Campus</option>
                          {school.campuses && school.campuses.length > 0 ? school.campuses.map((campus) => <option key={campus.id} value={campus.id} >{campus.name}</option>) : <option>No Campuses Available</option> }
                        </Input>
                    </Col>
                </Row>
              <br />
                <Row className='justify-content-center align-items-betweeen'>
                <Col lg="4">
                      <label
                      className="form-control-label"
                      htmlFor="start_time"
                      >
                      Delivery Start Time
                    </label>
                    <input
                    className="form-control"
                    id="start_time"
                    type="time"
                    required
                    {...register('start_time')}
                    />
                </Col>
                  <Col lg="4">
                  <label
                      className="form-control-label"
                      htmlFor="end_time"
                      >
                      Delivery End Time
                    </label>
                    <input
                    className="form-control"
                    id="end_time"
                    type="time"
                    {...register('end_time')}
                    />
                  </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={(e) => setClassroomModal(false)}
                >
                Close
                </Button>
                <Button
                    color="primary"
                    data-dismiss="modal"
                    type="submit"
                >
                Save
                </Button>
            </div>
          </Form>
      </Modal>
   </>
    )
  }


  export default SchoolProfile