import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Container, 
    Row,
    Form,
    Col,
    FormGroup,
    Button,
    Modal,
    ListGroup,
    ListGroupItem,
    Alert,
    UncontrolledDropdown,
    DropdownItem
    } from "reactstrap";
    // core components
    import UserHeader from "components/Headers/UserHeader.js";
    import React, { useState, useEffect }  from 'react';
    import { useParams } from "react-router-dom";
    import { useForm } from "react-hook-form";
    import axios from 'axios';
    import { ReactSession } from 'react-client-session';
    import { RotatingLines } from 'react-loader-spinner';
    
    const SnackProfile = () => {
        const { handleSubmit, register, reset } = useForm();
        const BASE_URL = process.env.REACT_APP_BASE_URL
        // Things get filtered and set for the modals, except for the modifiers
        let [snack, setSnack] = useState([])
        let [allergies, setAllergies] = useState([])
        let [tags, setTags] = useState([])
        let [menus, setMenus] = useState([])
        let [menusOpen, setMenusOpen] = useState(false)
        // This are to toggle the modals open and close.
        let [isLoaded, setIsLoaded] = useState(false);
        let [allergiesOpen, openAllergies] = useState(false)
        let [schoolsOpen, openSchools] = useState(false)
        let [tagsOpen, openTags] =useState(false)
        let [updateLoad, setUpdate] =useState(false)
        let [schools, setSchools] = useState([])
        let [categories, setCategories] = useState([])
        let [types, setTypes] = useState([])
        let [newType, setNewType] = useState(0)
        let [typeModalOpen, setTypeModal] = useState(false)
        const [error, setError] = useState(null);
        const { id } = useParams();
    
        let [alertOpen, setAlert] = useState(false)
        let [alertError, setErrorAlert] = useState(false)
    
        let [moneyFormatMessage, setMoneyFormatMessage] = useState('')
        let moneyRegExp = /^\-?\d+\.\d\d$/
    
        const token = ReactSession.get("token")
        let config = {
          headers: {
            'Authorization': `Bearer ${token}` 
          }
        }
    
    
        const getSchools = async (snack) => {
            try {
                let res = await axios.get(BASE_URL + '/schools/mobile ', config) 
                let filteredSchools = filterAvailableSchools(snack, res.data)
                setSchools(filteredSchools)
            } catch(error) {
                console.log(error)
                setError('getSchools: ' + error.message)
                setSchools([])
            }
          }
    
        const getMenus = async (snack) => {
              try {
                let res = await axios.get(BASE_URL + '/menus ', config) 
                let filteredMenus = filterAvailableMenus(snack, res.data )
                setMenus(filteredMenus)
              } catch(error) {
                console.log(error)
                setError('getSchools: ' + error.message)
                setSchools([])
              }
          }
    
        const getData = async () => {
            try {
                let res = await axios.get(BASE_URL + '/addons/snacks/' + id, config)
                setSnack(res.data)
                // getTags(res.data)
                getAllergies(res.data)
                getSchools(res.data)
                getMenus(res.data)
                setIsLoaded(true)
            } catch (error) {
                setError(error)
            }
        }
    
        useEffect(() => {
            getData()
            getCategories()
            getTypes()
        }, [])
    
        const editSnackForm = async (data, e) => {
            try {
                if (data.snack_price === undefined || data.name === undefined || data.snack_order === undefined) return
                let editData =  {
                        name : data.name,
                        price: data.snack_price,
                        sort_order: data.snack_order,               
                }
                if (moneyRegExp.test(data.snack_price )) {
                    let res = await axios.put(BASE_URL + `/addons/snacks/${snack.id}/edit`, editData, config)
                    if (res.status === 200) {
                        getData()
                        setUpdate(false)
                        setAlert(true)
                    } else {
                        setUpdate(false)
                        setErrorAlert(true)
                    }
                } else { 
                    setUpdate(false)
                    setErrorAlert(true)
                    setMoneyFormatMessage('Make sure to follow US Money Convensions')
                }
            } catch (error) {
                console.log(error)
            }
        }
    
        const filterAvailableAlgs = (allergies, currentSnack) =>  {
            let snackAlgs = currentSnack.allergies.map((alg) => alg.id)
            let filteredAllergies = allergies.filter((alg) => !snackAlgs.includes(alg.id))
            return filteredAllergies
        }
    
        const addAllergy = async (event) => {
            event.preventDefault()
            let allergyID = event.target.value
            await axios.put(BASE_URL + `/allergy/${allergyID}/associate/snack`, {snack_id: snack.id}, config)
            allergies.find((a) => a.id === parseInt(allergyID))
            openAllergies(false)
            getData()
        }
    
         const removeAllergy = async (event) => {
            event.preventDefault()
            let allergyID = event.target.value
             await axios.put(BASE_URL + `/allergy/${allergyID}/detach/snack`, {snack_id: snack.id}, config)
            getData()
         }
    
        const getAllergies = async (snack) => {
            let res = await axios.get(BASE_URL + '/allergy/mobile', config)
            let algs = filterAvailableAlgs(res.data, snack)
            setAllergies(algs)
        }
    
    
        // const filterAvailableTags = (tags, currentProduct) => {
        //     let productAlgs = currentProduct.tags.map((tag) => tag.id)
        //     let filteredTags = tags.filter((alg) => !productAlgs.includes(alg.id))
        //     return filteredTags
        // }
    
        // const getTags = async (currentProduct) => {
        //     let res = await axios.get(BASE_URL + '/tags', config)
        //     let availableTags = filterAvailableTags(res.data, currentProduct)
        //     setTags(availableTags)
        // }
    
        const addTag = async (e) => {
            let tagID = e.target.value
            await axios.put(BASE_URL + '/tags/associate/snack', {tagId: parseInt(tagID), snackID: snack.id}, config)
            getData()
            openTags(false)
        }
    
        // const removeTag = async (e) => {
        //     let tagID = parseInt(e.target.value)
        //     await axios.put(BASE_URL + '/tags/detach/snack', {tagId: tagID, productId: snack.id }, config)
        //     getData()
        // }
    
        const filterAvailableSchools = (snack, allSchools) => {
            let schoolsID = snack.schools.map((school) => school.id)
            let filteredSchools = allSchools.filter((school) => !schoolsID.includes(school.id))
            return filteredSchools
        }
        const filterAvailableMenus = (snack, allMenus) => {
            let menusIDs = snack.menus.map((menu) => menu.id)
            let filteredSchools = allMenus.filter((menu) => !menusIDs.includes(menu.id))
            return filteredSchools
        }
    
        const assignNewSchool = async (e) => {
            let schoolID = e.target.value
            await axios.put(BASE_URL + `/schools/${schoolID}/attach/snack`, { snackID: snack.id }, config)
            getData()
            openSchools(false)
        }
    
        const removeSchool = async (e) => {
            let menuID = e.target.value
            await axios.put(BASE_URL + `/schools/${menuID}/detach/snack`, { snackID: snack.id }, config)
            getData()
            openSchools(false)
        }
    
        const toggleOutOfStock = async () => {
            let res = await axios.put(BASE_URL + `/addons/snacks/${snack.id}/toggle`, {snackID: snack.id}, config)
            if (res.status === 200) {
                getData()
            }
        }
    
        const assignMenu = async (menuID) => {
           await axios.put(BASE_URL + '/menus/' + menuID + '/attach/snack', {snackID: snack.id}, config)
           setMenusOpen(false)
           getData()
        }
    
        const removeMenu = async (menuID) => {
            await axios.put(BASE_URL + '/menus/' + menuID + '/detach/snack', {snackID: snack.id}, config)
            setMenusOpen(false)
            getData()
        }
    
    
        const getCategories = async () => {
            try {
                let res = await axios.get(BASE_URL + '/categories', config)
                if (res.status === 200) {
                    setCategories(res.data)
                }
            } catch (error) {
                setError('getCategories: ' + error.message)
                setCategories([])
            }
        }
    
        const getTypes = async () => {
            try {
                let res = await axios.get(BASE_URL + '/types', config)
                if (res.status === 200) {
                    setTypes(res.data)
                }
            } catch (error) {
                setTypes([])
            }
        }
    
        const changeSnackType = async () => {
            await axios.put(BASE_URL + "/addons/snacks/" + snack.id + '/type', {
                typeID: newType,
            }, config)
            setTypeModal(false)
            setNewType(0)
            getData()
        }
     
    if (!isLoaded)
         return (
            <>
            <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="40"
            visible={true}
            />
            </>
        )
     else return (
            <>
            <UserHeader/>
            <Container className="mt--7" fluid>
            <Alert color="success" isOpen={alertOpen}  fade={true} toggle={(e) => setAlert(false)}>
                  Info updated!
            </Alert>
            <Alert color="danger" isOpen={alertError}  fade={true} toggle={(e) => setErrorAlert(false)}>
                Error updating customer information.
            </Alert>
                {/*Actual UI - SEE modals down below*/}
                {/* one Row with two columns */}
                <Row>
                    {/* Big Tables */}
                    <Col xl='8'>
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                        <Row className="align-items-center">
                            <Col xs="6">
                            <h3 className="mb-0">Snack Information.</h3>
                            </Col>
                            <Col className="text-right" xs="3">
                            </Col>
                            <Col className="text-right" xs="3">
                            <Row className='align-items-center justify-content-evenly'>
                            <hr></hr>
                                <h3>
                                    Type
                                </h3>
                                <hr></hr>
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                    className="btn"
                                    role="button"
                                    size="lg"
                                    //    color="primary"
                                    onClick={(e) => e.preventDefault()}
                                    >
                                    {snack.type.type_name}
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                    {types.map((type) => 
                                            <DropdownItem onClick={(e) => {
                                                // Do the thing
                                                e.preventDefault()
                                                setTypeModal(true)
                                                setNewType(type.id)
                                            }}>
                                                {type.type_name}
                                            </DropdownItem>
                                        )}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Row>
                            </Col>
                        </Row>
                        </CardHeader>
                        <CardBody>
                        <Form onSubmit={handleSubmit(editSnackForm)}>
                            <div className="pl-lg-4">
                            <Row>
                                <Col lg="6">
                                <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="input-first-name"
                                    >
                                    Name
                                    </label>
                                    <input
                                    className="form-control"
                                    defaultValue={snack.name}
                                    id="input-first-name"
                                    placeholder="First name"
                                    type="text"
                                    {...register('name')}
                                    required
                                    />
                                </FormGroup>
                                </Col>
                                <Col lg="4">
                                <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="product_price"
                                    >
                                    Price
                                    </label>
                                    <input
                                    className="form-control"
                                    defaultValue={snack.price}
                                    id="input-last-name"
                                    placeholder="snack Price"
                                    type="text"
                                    required
                                    {...register('snack_price')}
                                    />
                                </FormGroup>
                                </Col>
                                <Col lg="2">
                                <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="product_order"
                                    >
                                    Rank
                                    </label>
                                    <input
                                    className="form-control"
                                    defaultValue={snack.sort_order}
                                    id="input-snack-order"
                                    placeholder=""
                                    type="text"
                                    {...register('snack_order')}
                                    />
                                </FormGroup>
                                </Col>
                            </Row>
                            <Row className='p-3 alert-red'>
                            {moneyFormatMessage && <span>{moneyFormatMessage}</span>}
                            </Row>
                            <Row>
                               { updateLoad ? <span>...</span>: <Col lg="6">
                                <FormGroup>
                                    <Button type="submit" color="success" >
                                        Update
                                    </Button>
                                </FormGroup>
                                </Col>}
                            </Row>
                            </div>
                        </Form>
                        </CardBody>
                    </Card>
                    <br></br>
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                            <Row className="justify-content-between align-items-baseline">
                                <h3 className="ml-4">Offered In Schools Below</h3>
                                <DropdownToggle
                                    className="btn text-green mr-4"
                                    role="button"
                                    size="sm"
                                    onClick={(e) => {
                                        openSchools(true)
                                    }}
                                >
                            <span>Add School</span>
                            </DropdownToggle>
                            </Row>
                        </CardHeader>
                        <CardBody>
                        <div className="text-center">
                            <ListGroup>
                            {snack.schools.map((school) =>(
                                 <>
                                    <ListGroupItem key={school.id}>
                                        <Row className='justify-content-between' key={school.id}>
                                         <span className='ml-4'>{school.name}</span> <Button size='sm' color='danger' className='mr-4' value={school.id} onClick={removeSchool}>x</Button>
                                        </Row> 
                                    </ListGroupItem>
                                </> 
                                )
                            )}
                            </ListGroup>
                        </div>
                        </CardBody>
                    </Card>
                <br></br>
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                            <Row className="justify-content-between align-items-baseline">
                                <h3 className="ml-4">Offered In Daily Menus</h3>
                                <DropdownToggle
                                    className="btn text-green mr-4"
                                    role="button"
                                    size="sm"
                                    onClick={(e) => {
                                        setMenusOpen(true)
                                    }}
                                >
                            <span>Add Menu</span>
                            </DropdownToggle>
                            </Row>
                        </CardHeader>
                        <CardBody>
                        <div className="text-center">
                            <ListGroup>
                            {snack.menus.map((menu) =>(
                                 <>
                                    <ListGroupItem key={menu.id}>
                                        <Row className='justify-content-between' key={menu.id}>
                                         <span className='ml-4'>{menu.name}</span> <Button size='sm' color='danger' className='mr-4' value={menu.id} onClick={() => removeMenu(menu.id)}>x</Button>
                                        </Row> 
                                    </ListGroupItem>
                                </> 
                                )
                            )}
                            </ListGroup>
                        </div>
                        </CardBody>
                    </Card>
                    </Col>
                    <br></br>
                    <Col xl='4'>
                        <Card className="card-profile shadow">
                            <CardHeader>
                            <Row className='justify-content-around align-items-center'>
                                <h3>{snack.name}</h3>
                                <Button 
                                    sm
                                    onClick={() => toggleOutOfStock()} 
                                    color={snack.out_of_stock ? 'danger' : 'success'}
                                >
                                    {snack.out_of_stock ? 'Out of Stock' : 'Available'}
                                </Button>
                            </Row> 
                            </CardHeader>
                        <CardBody className="pt-0 pt-md-4">
                        <div className="text-center">
                            {/* <br></br>
                                {snack.tags.map((tag) => <>
                                    <ListGroup key={tag.id}>
                                    <Row className='justify-content-between' key={tag.id}>
                                    <span className='ml-4'>{tag.name}</span> <Button size='sm' color='danger' className='mr-4' value={tag.id} onClick={removeTag}>x</Button>
                                    </Row> 
                                    </ListGroup>
                            <br></br>
                            </>)}
                            <br></br> */}
                            <Row className='justify-content-left'>
                            {/* <DropdownToggle
                                className="btn text-green ml-4"
                                role="button"
                                size="sm"
                                onClick={(e) => {
                                    openTags(true)
                                }}
                            >
                            <span>Add Tag</span>
                            </DropdownToggle> */}
                            </Row>
                        </div>
                        </CardBody>
                        </Card>
                        <br />
        
                        <br></br>
                        <Card className="card-profile shadow">
                            <CardHeader>
                            <div className="text-center">
                                <h3>
                                Allergies 
                                <span className="font-weight-light"></span>
                                </h3>
                            </div>
                            </CardHeader>
                            <CardBody className="pt-0 pt-md-4">
                            <div className="text-center">
                            {snack.allergies.length > 0 ?
                            <ListGroup>
                            {snack.allergies.map((alg) => {
                            return<> 
                            <ListGroup key={alg.id}>
                                <Row className='justify-content-between' key={alg.id}>
                                <span className='ml-4'>{alg.name}</span> <Button size='sm' color='danger' className='mr-4' value={alg.id} onClick={removeAllergy}>x</Button>
                                </Row> 
                            </ListGroup>
                                <br></br>
                                </>
                            })}
                            </ListGroup>
                            : <span>This snack has no allergies</span>}
                            </div>
                            <br></br>
                            <Dropdown>
                                <DropdownToggle
                                    className="btn text-green"
                                    role="button"
                                    size="sm"
                                    onClick={(e) => {
                                        openAllergies(true)
                                    }}
                                >
                                <span>Add</span>
                                </DropdownToggle>
                            </Dropdown>
                            </CardBody>
                        </Card>
                        <br></br>
                    </Col>
                </Row>
                {/* MODALS */}
                <br></br>
              
                <Modal isOpen={allergiesOpen}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="alg-modal">
                        Assign Allergies
                        </h5>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={(e) => openAllergies(false)}
                        >
                        <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                <div className="modal-body">
                    <ListGroup >
                        {allergies.map((alg) =>  {
                            return  <ListGroupItem key={alg.id}> 
                                <Row className='justify-content-between'>
                                <span className='ml-4'>{alg.name}</span> 
                                <Button size='sm' color='success' className='mr-4' value={alg.id} onClick={addAllergy} >
                                    Add
                                    </Button>
                                </Row> 
                                </ListGroupItem>
                        })}
                    </ListGroup>
                </div>
                    <div className="modal-footer">
                        <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={(e) => openAllergies(false)}
                        >
                        Close
                        </Button>
                    </div>
                </Modal>
                  <Modal isOpen={schoolsOpen}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="menu-modal">
                        Assign Schools
                        </h5>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={(e) => openSchools(false)}
                        >
                        <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <ListGroup>
                            {schools.map((school) =>  {
                                return  <ListGroupItem key={school.id}> 
                                    <Row className='justify-content-between'>
                                    <span className='ml-4'>{school.name}</span> 
                                    <Button size='sm' color='success' className='mr-4' value={school.id} onClick={assignNewSchool} >
                                        Add
                                    </Button>
                                    </Row> 
                                    </ListGroupItem>
                            })}
                        </ListGroup>
                    </div>
                    <div className="modal-footer">
                        <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={(e) => openSchools(false)}
                        >
                        Close
                        </Button>
                     </div>
                </Modal>
                <Modal isOpen={menusOpen}>
                    <div className="modal-header">
                        <h5 className="modal-title" id="menu-modal">
                        Assign Menus
                        </h5>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={(e) => setMenusOpen(false)}
                        >
                        <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <ListGroup>
                            {menus.map((menu) =>  {
                                return  <ListGroupItem key={menu.id}> 
                                    <Row className='justify-content-between'>
                                    <span className='ml-4'>{menu.name}</span> 
                                    <Button size='sm' color='success' className='mr-4' value={menu.id} onClick={() => assignMenu(menu.id)} >
                                        Add
                                    </Button>
                                    </Row> 
                                    </ListGroupItem>
                            })}
                        </ListGroup>
                    </div>
                    <div className="modal-footer">
                        <Button
                        color="secondary"
                        data-dismiss="modal"
                        type="button"
                        onClick={(e) => setMenusOpen(false)}
                        >
                        Close
                        </Button>
                     </div>
                </Modal>
                <Modal isOpen={tagsOpen}>
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                    Assign Tags
                    </h5>
                    <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={(e) => openTags(false)}
                    >
                    <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                <ListGroup >
                    {tags.map((tag) =>  {
                        return  <ListGroupItem key={tag.id}> 
                            <Row className='justify-content-between'>
                             <span className='ml-4'>{tag.name}</span> 
                             <Button size='sm' color='success' className='mr-4' value={tag.id} onClick={addTag} >
                                Add
                                </Button>
                            </Row> 
                            </ListGroupItem>
                    })}
                    </ListGroup>
                </div>
                <div className="modal-footer">
                </div>
                </Modal>
                {/* <Modal
                    isOpen={catModalOpen}
                    className="modal-dialog-centered"
                    size="sm"           
                >
                   <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                            <CardHeader>
                            <h2>Change snack Category</h2>
                            </CardHeader>
                            <CardBody>
                            <p>Click "save" to confirm you are changing the snack category.</p>
                            </CardBody>
                            <CardFooter>
                                <Row className='justify-content-center'>
                                    <Button onClick={() => {
                                        setCatModal(false)
                                        setNewCat(0)
                                        }}> Cancel</Button>
                                    <Button
                                    color='success'
                                    onClick={() => changeProductCat()}
                                    >Save</Button>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Modal> */}
    
                <Modal 
                    isOpen={typeModalOpen}
                    className="modal-dialog-centered"
                    size="sm"  
                >
                    <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                            <CardHeader>
                            <h2>Change snack Type</h2>
                            </CardHeader>
                            <CardBody>
                            <p>Click "save" to confirm you are changing the snack type.</p>
                            {/* <Input value={activationString} onChange={(e) => setString(e.target.value)}></Input> */}
                            </CardBody>
                            <CardFooter>
                                <Row className='justify-content-center'>
                                    <Button onClick={() => {
                                        setTypeModal(false)
                                        setNewType(0)
                                        }}> Cancel</Button>
                                    <Button
                                    color='success'
                                    onClick={() => changeSnackType()}
                                    >Save</Button>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Modal>
            </Container>
            </>
        )
    }
    
    
    export default SnackProfile