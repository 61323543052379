
// reactstrap components
import {
Card,
CardHeader,
CardBody,
ListGroup,
ListGroupItem,
Button,
Modal,
Col,
Container,
Row,
Form,
Alert,
CardFooter,
Input
} from "reactstrap";
// core components
import React, { useState, useEffect }  from 'react';
import UserHeader from "components/Headers/UserHeader.js";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { ReactSession } from 'react-client-session';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Switch } from "@mui/material";




const Settings = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL
    let [ isLoading, setLoading ] = useState(true)
    let [schoolYears, setSchoolYears]  = useState([])
    let [error, setError] = useState(null);
    let [alertOpen, setAlert] = useState(false)
    let [alertOpenModOn, setAlertModOn] = useState(false)
    let [alertOpenModOff, setAlertModOff] = useState(false)
    let [alertError, setErrorAlert] = useState(false)
    let [resError, setResError] = useState([])
    let [schoolYearFormOpen, openSYForm] = useState(false)
    let [schoolYearEdit, setYearEdit] = useState([])
    let [newYearName, setYearName] = useState('')
    let [yearToActivate, setYearToActivate] = useState(0)
    let [activationString, setString] = useState('')
    let [activateYearModal, setActivateYearModal] = useState(false)
    let [lateFee, setFee] = useState(0)
    let [editFee, setEditFee] = useState(false)
    let [feeError, setFeeError] = useState('')
    let [page, setPage] = useState(1);
    let [pageLimit, setPageLimit] = useState(5)
    let [ products, setProducts ] = useState([])
    let [ productSwitch, setProdudctSwitch] = useState(false)
    // let [ modCountTrue, setModCountTrue] = useState(0)
    // let [ modCountFalse, setModCountFalse] = useState(0)
    const { handleSubmit, register, formState: { errors } } = useForm();

    const token = ReactSession.get("token");
    let config = {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
    }

    useEffect(() => {
        getData()
        getLateFee()
        getModifierSetting()
        getProducts()
    }, [])

    const getData = async () => {
        await getSchoolYears()
    }
    const getSchoolYears = async () => {
        let res = await axios.get(BASE_URL + '/school_years/', config)
        if (res.status === 200) {
            setSchoolYears(res.data)
        }
        setLoading(false)
    }

    const getProducts = async () => {
        try {
            let res = await axios.get(BASE_URL + '/product/paginate?' + pageLimit + '&page=' + page,config) 
            let prods = res.data.data
            setProducts(prods)
        } catch(error) {
            setError('getData: ' + error.message)
        }
    }

    const activateSchoolYear =  async () => {
        setActivateYearModal(false)
        setLoading(true)
        if (yearToActivate) {
            let res = await axios.post(BASE_URL + '/school_years/'+yearToActivate+'/activate', {}, config)
            if (res.status === 200) {
                getData()
                setYearToActivate(false)
                setLoading(false)
            }
        }
    }

    const createSchoolYear = async () => {
        setLoading(true)
        let res = await axios.post(BASE_URL + '/school_years', { name: newYearName }, config)
        if (res.status === 200) {
            setLoading(false)
            openSYForm(false)
            getData()
        } else {
            //error
        }
    }
    const getLateFee = async ()  => {
        let res = await axios.get(BASE_URL + "/settings/latefee", config)
        if (res.status === 200) {
            setFee(res.data)
        }
    }
    const getModifierSetting = async ()  => {
        let res = await axios.get(BASE_URL + "/settings/modifier", config)
        if (res.status === 200) {
            setProdudctSwitch(res.data)
        }
    }

    const editLateFee = async (data) => {
        let moneyRegExp = /^\-?\d+\.\d\d$/

        let newFee = data.late_fee
        if (moneyRegExp.test(newFee)) {
            let res = await axios.put(BASE_URL + '/settings/latefee',{ newFee: newFee }, config)
            setEditFee(false)
            setFeeError('')
            getLateFee()
        } else {
            setFeeError('Make sure you use correct US Money format, EX: 10.99, 9.99, 9.00')
        }
    }

    // Custom Switch Colors
    const theme = createTheme({
    palette: {
        themeGreen: {
        // This is green.A700 as hex.
        main: '#11cb5f',
        },
    },
    });

    // Possible future use for modifier count
    // const modCount = async () => {
    //     console.log('modCount')
    //         products.map(async (product) => {
    //             console.log('is_modifier_required')
    //             if (product.is_modifier_required === true) {
    //                 console.log('true MODCOUNT')
    //                 console.log(1)
    //                 // let modCountT = product.is_modifier_required.count()
    //                 // setModCountTrue(modCountT)
    //             } else if (product.is_modifier_required === false) {
    //                 console.log('false MODCOUNT')
    //             //     let modCountF = product.is_modifier_required.count()
    //             // setModCountFalse(modCountF)
    //             }
    //     })  
    // }

    const toggleModfierItem = async () => {
        products.map(async (product) => {
            if(productSwitch === false) {
                let res = await axios.put(BASE_URL + `/product/${product.id}/toggle/modifier/on`, {}, config)
                let res2 = await axios.put(BASE_URL + '/settings/modifier',{ isModifierRequired: true }, config)
                if (res.status === 200 && res2.status === 200) {
                    setProdudctSwitch(true)
                    getData()
                    setAlertModOn(true)
                }
            } else if (productSwitch === true)  {
                let res = await axios.put(BASE_URL + `/product/${product.id}/toggle/modifier/off`, {}, config)
                let res2 = await axios.put(BASE_URL + '/settings/modifier',{ isModifierRequired: false }, config)
                if (res.status === 200 && res2.status === 200) {
                    setProdudctSwitch(false)
                    getData()
                    setAlertModOff(true)
                } 
            }
        })
    }

    const testDailySchoolReport = async () => {
        let res = await axios.get(BASE_URL + "/health/dailyschoolreport", config)
        console.log(res)
    }
    if (isLoading) {
        return (<>
        <UserHeader />
        <h1>Loading...</h1>
        </>)
    } else {
        return (
    <>
        <UserHeader />
        <Container className='mt--7' fluid>
            <Alert color="success" isOpen={alertOpen}  fade={true} toggle={(e) => setAlert(false)}>
            Info updated!
            </Alert>
            <Alert color="danger" isOpen={alertError}  fade={true} toggle={(e) => setErrorAlert(false)}>
            Error updating customer information.
            </Alert>
            <Alert color="success" isOpen={alertOpenModOn}  fade={true} toggle={(e) => setAlertModOn(false)}>
            Product modifiers required is turned on!
            </Alert>
            <Alert color="danger" isOpen={alertOpenModOff}  fade={true} toggle={(e) => setAlertModOff(false)}>
            Product modifiers required is turned off!
            </Alert>
            <Row>
            <Col xl='6'>
                <Card className='shadow'>
                    <CardHeader className="bg-white border-0">
                        <Row className='justify-content-between'>
                            <h2 className='ml-4'>School Years</h2>
                            <Button size='sm' color='success' className='mr-4' onClick={(e) => {
                                e.preventDefault()
                                openSYForm(true)
                                }} >
                                Add
                            </Button>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div className="text-warning ml-3">{(resError) ? <p>{resError}</p> : ''}</div>

                        <ListGroup>
                        {schoolYears.map((schYear) => {
                            return  <ListGroupItem key={schYear.id}> 
                                    {schoolYearEdit === schYear.id ?(
                                    <Form onSubmit={handleSubmit(activateSchoolYear)} value={schYear.id}>
                                        <Row className='justify-content-between align-items-center'>
                                        <Col lg='6'><input className="form-control h-25" placeholder={schYear.name}type='text' {...register('cat_name')} /></Col>
                                        <Button size='sm' type='submit' color='success' className='mr-4 h-25' value={schYear.id}> Save </Button> 
                                        </Row>
                                    </Form>
                                   )
                                    : (<Row className='justify-content-between'>
                                    <span className='ml-4'>
                                        {schYear.name} - ID: {schYear.id} -  
                                        <strong style={{ color:schYear.yearStatus.status_name === 'Active'? '#2dce89': 'black'}}>
                                            {schYear.yearStatus.status_name} 
                                        </strong>
                                    </span>
                                    <Col></Col>
                                    {schYear.yearStatus.status_name === 'Draft' ? <Button 
                                    size='sm' 
                                    color='danger' 
                                    className='mr-5'
                                    onClick={() => {
                                        setYearToActivate(schYear.id)
                                        setActivateYearModal(true)
                                    }} 
                                    >
                                        Activate
                                    </Button> : null}
                                    <Row> 
                                    {
                                    /* <Button size='sm' color='normal' className='mr-4' value={schYear.id} onClick={(e) => toggleCategory(schYear.id)} >
                                       {schYear.status === 'Active' ?  "Hide" : "Show"}
                                    </Button>
                                    <Button size='sm' color='success' className='mr-4' value={schYear.id} onClick={(e) => setCat(cat.id)} >
                                        Edit
                                    </Button> */
                                    }
                                    </Row>
                                    </Row>
                                    )
                                    }
                                </ListGroupItem>
                        })}
                        </ListGroup>
                    </CardBody>
                </Card>
            </Col>
            <Col xl='6'>
                <Card className='shadow'>
                    <CardHeader className="bg-white border-0">
                        <Row className='justify-content-between'>
                            <h2 className='ml-4'>Late Fee</h2>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div className="text-warning ml-3">{(feeError) ? <p>{feeError}</p> : ''}</div>
                        {editFee ?     
                        <Form onSubmit={handleSubmit(editLateFee)} >
                            <Row className='justify-content-between align-items-center'>
                            <Col lg='3'> <input 
                            className="form-control h-25" 
                            type='text' 
                            defaultValue={lateFee} 
                                {...register('late_fee')
                            }
                            >
                            </input>
                            </Col>
                                <Button size='sm' type='submit' color='success' className='mr-4 h-25'> Save </Button> 
                            </Row>
                        </Form>
                                : 
                            (
                            <Row className='justify-content-between'>
                                <span className='ml-4'>${lateFee}</span>
                                <Row> 
                                <Button size='sm' color='success' className='mr-4' value={lateFee} onClick={(e) => setEditFee(true)} >
                                    Edit
                                </Button>
                                </Row>
                            </Row>
                            )
                        }
                    </CardBody>
                    <CardHeader className="bg-white border-0">
                        <Row className='justify-content-between'>
                            <h2 className='ml-4'>Modifiers</h2>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row className='justify-content-between'>
                            <span className='ml-4'>Product Modifier Required:</span>
                            <ThemeProvider theme={theme}>  
                            <Switch 
                            className='mb-4'
                                checked={productSwitch}
                                onChange={toggleModfierItem}
                                inputProps={{ 'aria-label': 'controlled' }}
                                // onClick={() => toggleModfierItemOn()}
                                color= 'themeGreen'
                            />
                            </ThemeProvider>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            </Row>
            <br></br>
            {/* <Row>
            <Col xl='12'>
                <Card className='shadow'>
                    <CardHeader className="bg-white border-0">
                        <Row className='justify-content-between'>
                            <h2 className='ml-4'>System Health</h2>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Col xl="6">
                            <ListGroup>
                                <ListGroupItem>
                                    <Row className='justify-content-between align-items-center px-2'> 
                                        Daily School Report Status
                                        <Button size='sm' color='success' className='mr-4' value={lateFee} onClick={(e) => testDailySchoolReport()} >
                                            Test
                                        </Button>
                                    </Row>
                                </ListGroupItem>
                            </ListGroup>
                        </Col>
                    </CardBody>
                </Card>
            </Col>
            </Row> */}
            <Modal
                isOpen={schoolYearFormOpen}
                className="modal-dialog-centered"
                size="sm"
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader>
                        <h2>Create a new School Year</h2>
                        </CardHeader>
                        <CardBody>
                        <label
                            className="form-control-label"
                            htmlFor="input-yearname"
                        >
                        Year Name
                        </label>
                        <Input value={newYearName} onChange={(e) => setYearName(e.target.value)}></Input>
                        </CardBody>
                        <CardFooter>
                            <Row className='justify-content-center'>
                                <Button onClick={() => openSYForm(false)}> Cancel</Button>
                                <Button color='success' onClick={() => createSchoolYear()}> Create</Button>
                            </Row>
                        </CardFooter>
                    </Card>
                </div>
            </Modal>
            <Modal 
                isOpen={activateYearModal}
                className="modal-dialog-centered"
                size="sm"
            >
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardHeader>
                        <h2>ACTIVATE A SCHOOL YEAR</h2>
                        </CardHeader>
                        <CardBody>
                        <p>Attention, you are about to activate a new School Year.</p>
                        <p>You should only perform this action when you are ready to start a new school cicle, this action cannot be undone.</p>
                        <label
                            className="form-control-label"
                            htmlFor="input-yearname"
                        >
                        Write 'activate school year' to continue 
                        </label>
                        <Input value={activationString} onChange={(e) => setString(e.target.value)}></Input>
                        </CardBody>
                        <CardFooter>
                            <Row className='justify-content-center'>
                                <Button onClick={() => setActivateYearModal(false)}> Cancel</Button>
                                <Button
                                color='success'
                                disabled={!(activationString === 'activate school year')}
                                onClick={() => activateSchoolYear()}
                                >Activate</Button>
                            </Row>
                        </CardFooter>
                    </Card>
                </div>
            </Modal>
        </Container>
    </>
        )
    }
}

export default Settings