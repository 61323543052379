// reactstrap components
import {
  FormGroup,
  Form,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";


const ResultsPerPage = ({page, setPage, pageLimit, setPageLimit, rowAmount}) => {
  return (
<>
    {/* Form to change number of result per page */}
    <Form>
        <div >
            <Row className="mx-2">
                <Col >
                    {/* <FormGroup row> */}
                        {/* <Col > */}
                            <Input
                            id="exampleSelect"
                            name="select"
                            type="select"
                            onChange = {e => {
                                setPageLimit(e.target.value)
                                setPage(page)
                            }}
                            // defaultValue={rowAmount}
                            >
                            { rowAmount === 0 || !rowAmount ?    <option value="25">
                                25
                            </option> : <option value={rowAmount}>
                              {rowAmount}
                            </option>
                            }
                            {/* <option value="25">
                                25
                            </option> */}
                            <option value="50">
                                50
                            </option>
                            <option value="75">
                                75
                            </option>
                            <option value="100">
                                100
                            </option>
                            </Input>
                        {/* </Col>   */}
                       
                    {/* </FormGroup> */}
           
                </Col>  
                <Label for="exampleSelect" className='mt-2'>
                        Rows per page
                </Label>
            </Row>
        </div>
    </Form>
    </>
  );
};



export default ResultsPerPage;
