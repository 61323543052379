/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
// reactstrap components
import ResultsPerPage from "components/ResultsPerPage.js";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Table,
  PaginationItem,
  Pagination,
  PaginationLink,
  CardFooter,
  Button,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,

} from "variables/charts.js";
import { ReactSession } from 'react-client-session';
// import Header from "components/Headers/Header.js";
import axios from "axios";
const Index = (props) => {
 
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }


  useEffect(() => {
  }, [])

  return (
    <>
      <Card className="shadow">
      <CardHeader className="border-0">
          Reports
      </CardHeader>
       <iframe
        title="Reports"
        width="1150"
        height="1500"
        src="https://lookerstudio.google.com/embed/reporting/e6c21901-a1f4-442b-b94d-7e1a9807f069/page/p_swdpc9t30c"
        frameborder="0"
        allowfullscreen
        >
        </iframe>
      </Card>
    </>
  );
};

      

export default Index;
