

import UsersTableHeader from "components/Headers/UsersTableHeader.js"
import {
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    Col,
    Button,
    Modal
  } from "reactstrap"
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useForm, Controller, get } from "react-hook-form";
import ResultsPerPage from "components/ResultsPerPage.js";
import Skeleton from 'react-loading-skeleton'
import axios from 'axios'
import { ReactSession } from 'react-client-session';
import { Link } from "react-router-dom";


const SchoolCampusesTable = () => {
    let { id } = useParams()
    let  [school, setSchool] = useState({})
    const [ campuses, setCampuses ] = useState([])
    let [ isLoading, setLoad ] = useState(true)
    let [createCampusError, setCreateCampusError] = useState(false)
    let  [loading, setLoader] = useState(true)
    const BASE_URL = process.env.REACT_APP_BASE_URL

    const [schoolYear, setSchoolYear] = useState("");
    const [schoolYearId, setSchoolYearId] = useState("");
    const [campusName, setName] = useState("");
    const [campusID, setClassID] = useState("");

    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(25);
    const [numberPages, setNumberPages] = useState(null);
    const [schoolYears, setSchoolYears] = useState([]);
    const { register, handleSubmit, reset, formState, control } = useForm();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState([""]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const token = ReactSession.get("token");


    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }

  const getData = async () => {
    if (localStorage.getItem('selectedSchoolYear') != null && schoolYear === "") {
      //     console.log('local storage is not null')
      
          setSchoolYear(JSON.parse(localStorage.getItem('selectedSchoolYear')))
          setSchoolYearId(JSON.parse(localStorage.getItem('selectedSchoolYear')).id)
    } 
      getSchoolYears()
      let params = + "page=" + page + "&limit=" + pageLimit + "&school_year_id=" + schoolYearId + "&name=" + campusName   +  "&id=" + campusID 
      let data = await axios.get(BASE_URL  + '/schools/' + id + '/campus/?' + params)
      if (data.status === 200) {
        console.log('here is the data')
        console.log(data.data)
        setCampuses(data.data)
        setNumberPages(data.data.last_page)
        setLoad(false)
      }
  }

    const getSchoolYears = async () => {
      let res = await axios.get(BASE_URL + '/school_years/', config)
      if (res.status === 200) {
        setSchoolYears(res.data)
      }
    }

    useEffect(() => {
        getData()
    }, [page, pageLimit, schoolYear, campusName, campusID])
    return (
        <>
        <UsersTableHeader />
        <Container className="mt--7" fluid>
        <Card>
            <CardHeader>
              <Row className='align-items-center justify-content-between'>
              <h1 className="ml-4 mr-4">School Campuses</h1>
                {/* <Row className='pr-4'>
                  <Button 
                  color="success"
                  className='success' onClick={(e) => setCampusModal(true)}>Create</Button>
                </Row> */}
              </Row>
            </CardHeader>
            <br></br>
            <Form>
                    <div className="pl-lg-4">
                        <Row>
                                <FormGroup row className="col">
                                <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        School Year
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="schoolYear"
                                        type="select"
                                        value={schoolYear ? schoolYear.id : ""}
                                        onChange = {e => {
                                          setSchoolYear(e.target.value)
                                          setSchoolYearId(e.target.value)
                                          setPage(1)
                                          console.log('here is the value')
                                          console.log(e.target.value)
                                        }}
                                        >
                                          <option disabled value="">
                                          {"Select a School Term"}
                                          </option>
                                        {schoolYears.map(year => (
                                          <option key={year.id} value={year.id}>
                                              {year.name}
                                          </option>
                                        ))}

                                        </Input>
                                      </InputGroup>
                                    </Col>

                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Name
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="campusName"
                                        type="text"
                                        placeholder="Enter Campus Name"
                                        value={campusName}

                                        onChange = {e => {
                                          setName(e.target.value)
                                        }}
                                        />
                                      </InputGroup>
                                    </Col>
                                    <Col>
                                    <label className=" form-control-label" htmlFor="basic-url">
                                    &#8205; 
                                      </label>
                                      <ResultsPerPage pageLimit={pageLimit} setPageLimit={setPageLimit} page={page} setPage={setPage}/>
                                    </Col>
                                </FormGroup>
                            {/* </Col>   */}
                        </Row>
                    </div>
                  </Form>
                 
            <CardBody>
            {
            isLoading ?
            <>
             <Skeleton count={5}/>
            </>
            :   
             <Table>
                <thead className='thead-light'>
                    <tr className='text-center'>
                        <th>ID</th>
                        <th>Name</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                    campuses.map((campus) => (
                         <tr key={campus.id} className='text-center'>
                            <td>
                                {campus.id}
                            </td>
                            <td>{campus.name}</td>
                            <td>{campus.year_id}</td>
                            <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              color="success"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={() => {
                                    setModalData(campus); 
                                    setModalOpen(!modalOpen)}}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => {
                                  setModalData(campus)
                                    fetch(BASE_URL  + '/campuses/' + campus.id + "/edit", {
                                      method: 'PUT',
                                      body: JSON.stringify({isDeleted: true}),
                                      headers: { 'Content-Type': 'application/json' },
                                    })
                                        .then(res => res.json())
                                        .then(
                                            (data) => {
                                                setPage(1)
                                                setIsLoaded(true);
                                                getData()
                                            },
                                            (error) => {
                                                setIsLoaded(true);
                                                setError(error);
                                            }
                                        )
                                }}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                        </tr>
                    ))
                    }
                    <Modal
                            className="modal-dialog-centered"
                            size="md"
                            // toggle={() => setModalOpen(!modalOpen)} 
                            isOpen={modalOpen} 
                            >
                            <div className="modal-body p-0">
                                <Card className="bg-secondary shadow border-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <Form role="form" onSubmit={handleSubmit(data => {
                                        fetch(BASE_URL  + '/campuses/' + modalData.id + "/edit", {
                                            method: 'PUT',
                                            body: JSON.stringify({name: data.newName, address: data.newAddress, city: data.newCity, state: data.newState, zip_code: data.newZipCode}),
                                            headers: { 'Content-Type': 'application/json' },
                                          })
                                          .then(res => res.json())
                                            .then(
                                                (data) => {
                                                    fetch(BASE_URL + '/schools/' + id + '/campus/?' + "page=" + page + "&limit=" + pageLimit + "&school_year_id=" + schoolYear + "&name=" + campusName  + "&id=" + campusID, config)
                                                        .then(res => res.json())
                                                        .then(
                                                            (data) => {
                                                                setCampuses(data);
                                                                setIsLoaded(true);
                                                                reset({"name":""});
                                                                setModalOpen(!modalOpen);
                                                            },
                                                            (error) => {
                                                                setIsLoaded(true);
                                                                setError(error);
                                                            }
                                                        )
                                                    setIsLoaded(true);
                                                },
                                                (error) => {
                                                    setIsLoaded(true);
                                                    setError(error);
                                                }
                                            )
                                    })}>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                        >
                                        Edit Campus: {modalData.name}
                                        </label>
                                        <div className="modal-body">
                                            <Row>
                                            <Col>
                                                <label
                                                className="form-control-label"
                                                htmlFor="campus_name"
                                                >
                                                Name
                                                </label>
                                                <Controller
                                                name="newName"
                                                control={control}
                                                render={({ field }) => <Input {...field}  placeholder={modalData.name} />}
                                                 />
                                              </Col>
                                              <Col lg="6">
                                                <label
                                                className="form-control-label"
                                                htmlFor="campus_address"
                                                >
                                                Address 
                                                </label>
                                                <Controller
                                              name="newAddress"
                                              control={control}
                                              render={({ field }) => <Input {...field}  placeholder={modalData.address} />}
                                                />
                                              </Col>
                                            </Row>
                                          <br />
                                            <Row>
                                            <Col lg="4">
                                                  <label
                                                  className="form-control-label"
                                                  htmlFor="campus_city"
                                                  >
                                                  Campus City
                                                  </label>
                                                  <Controller
                                                name="newCity"
                                                control={control}
                                                render={({ field }) => <Input {...field}  placeholder={modalData.city} />}
                                                 />
                                            </Col>
                                              <Col lg="4">
                                                  <label
                                                  className="form-control-label"
                                                  htmlFor="campus_state"
                                                  >
                                                  Campus State 
                                                  </label>
                                                  <Controller
                                                name="newState"
                                                control={control}
                                                render={({ field }) => <Input {...field}  placeholder={modalData.state} />}
                                                 />
                                              </Col>
                                              <Col lg="4">
                                                  <label
                                                  className="form-control-label"
                                                  htmlFor="campus_zip_code"
                                                  >
                                                  Zip Code 
                                                  </label>
                                                 <Controller
                                                name="newZipCode"
                                                control={control}
                                                render={({ field }) => <Input {...field}  placeholder={modalData.zip_code} />}
                                                 />
                                              </Col>
                                            </Row>
                                        </div>
                                        <div className="modal-footer">
                                            <Button
                                                color="secondary"
                                                data-dismiss="modal"
                                                type="button"
                                                onClick={(e) => setModalOpen(!modalOpen)}
                                            >
                                            Close
                                            </Button>
                                            <Button
                                                color="primary"
                                                data-dismiss="modal"
                                                type="submit"
                                            >
                                            Save
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                                </Card>
                            </div>
                            </Modal>
                </tbody>
            </Table>
               }
            </CardBody>
            <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem className="disabled">
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>

                        
                        {[...Array(numberPages)].map((x, i) =>
                            <PaginationItem key={i} className={`${page === (i+1) ? "active" : ""}`}>
                            {/* <PaginationItem className="active"> */}
                            <PaginationLink
                              onClick={(e) => { 
                                  setPage(e.target.innerHTML)
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        )}
                        <PaginationItem>
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                </CardFooter>
        </Card>
        </Container>
        </>
    ) 
}

export default SchoolCampusesTable
