import {
Card,
CardBody,
CardHeader,
CardFooter,
Dropdown,
DropdownToggle,
DropdownMenu,
Container, 
Row,
Form,
Col,
FormGroup,
Button,
Modal,
ListGroup,
ListGroupItem,
Alert,
UncontrolledDropdown,
DropdownItem,
Input
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect }  from 'react';
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { RotatingLines } from "react-loader-spinner";

const ProductProfile = () => {
    const { handleSubmit, register, reset } = useForm();
    const BASE_URL = process.env.REACT_APP_BASE_URL
    // Things get filtered and set for the modals, except for the modifiers
    let [product, setProduct] = useState([])
    let [allergies, setAllergies] = useState([])
    let [tags, setTags] = useState([])
    let [menus, setMenus] = useState([])
    let [menusOpen, setMenusOpen] = useState(false)
    // This are to toggle the modals open and close.
    let [isLoaded, setIsLoaded] = useState(false);
    let [allergiesOpen, openAllergies] = useState(false)
    let [schoolsOpen, openSchools] = useState(false)
    let [modifierFormOpen, openModsForm] = useState(false)
    let [modifierEditFormOpen, openModsEditForm] = useState(false)
    let [modalData, setModalData] = useState([""]);
    let [tagsOpen, openTags] =useState(false)
    let [updateLoad, setUpdate] =useState(false)
    let [schools, setSchools] = useState([])
    let [categories, setCategories] = useState([])
    let [types, setTypes] = useState([])
    let [newCat, setNewCat] = useState(0)
    let [catModalOpen, setCatModal] = useState(false)
    let [newType, setNewType] = useState(0)
    let [typeModalOpen, setTypeModal] = useState(false)
    const [error, setError] = useState(null);
    const { id } = useParams();

    let [alertOpen, setAlert] = useState(false)
    let [alertError, setErrorAlert] = useState(false)

    let [imageUpdate, setImageUpdate] = useState(false)
    let [newProductImage, setProductImage] = useState(false)
    let [moneyFormatProductMessage, setMoneyFormatProductMessage] = useState('')
    let [moneyFormatMessage, setMoneyFormatMessage] = useState('')
    let moneyRegExp = /^\-?\d+\.\d\d$/

    const updateImage = async () => {
        let productID = product.id
        const formData = new FormData()
        formData.append('product_img', newProductImage)
        await axios.put(BASE_URL + `/product/${productID}/image`, formData, config)
        getData()
        setImageUpdate(false)
    }

    const token = ReactSession.get("token")
    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }

    const handleFileChange = (e) => {
        let file = e.target.files[0]
        setProductImage(file)
    }


    const getSchools = async (product) => {
        try {
            let res = await axios.get(BASE_URL + '/schools/mobile ', config) 
            let filteredSchools = filterAvailableSchools(product, res.data)
            setSchools(filteredSchools)
        } catch(error) {
            console.log(error)
            setError('getSchools: ' + error.message)
            setSchools([])
        }
      }

    const getMenus = async (product) => {
          try {
            let res = await axios.get(BASE_URL + '/menus ', config) 
            let filteredMenus = filterAvailableMenus(product, res.data )
            setMenus(filteredMenus)
          } catch(error) {
            console.log(error)
            setError('getSchools: ' + error.message)
            setSchools([])
          }
      }

    const getData = async () => {
        try {
            let res = await axios.get(BASE_URL + '/product/' + id, config)
            setProduct(res.data)
            getTags(res.data)
            getAllergies(res.data)
            getSchools(res.data)
            getMenus(res.data)
            setIsLoaded(true)
        } catch (error) {
            setError(error)
        }
    }

    useEffect(() => {
        getData()
        getCategories()
        getTypes()
    }, [])

    const editProductForm = async (data, e) => {
        try {
            if (data.product_price === undefined || data.name === undefined || data.description === undefined) return
            let editData =  {
                name : data.name,
                price: data.product_price,
                cost: data.cost,
                description: data.description,
                carbs: data.carbs,
                fats: data.fats,
                proteins: data.proteins,
            }
            if (moneyRegExp.test(data.product_price && data.cost)) {
                let res = await axios.put(BASE_URL + `/product/${product.id}/edit`, editData, config)
                if (res.status === 200) {
                getData()
                setUpdate(false)
                setAlert(true)
                setMoneyFormatProductMessage('')
                }
            } else {
                setUpdate(false)
                setMoneyFormatProductMessage('Make sure to follow US Money Convensions')
            }
        } catch (error) {
            console.log(error)
        }
    }

    const editProductExtraPrice = async (data, e) => {
        try {
            if (data.extra_price === undefined) return
            if (!moneyRegExp.test(data.extra_price)) return
            let editData =  {
                extraPrice : data.extra_price,
            }
            let res = await axios.put(BASE_URL + `/product/${product.id}/extraprice`, editData, config)
            if (res.status === 200) {
                getData()
                setUpdate(false)
                setAlert(true)
            } else {
                setUpdate(false)
                setErrorAlert(true)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const filterAvailableAlgs = (allergies, currentProduct) =>  {
        let productAlgs = currentProduct.allergies.map((alg) => alg.id)
        let filteredAllergies = allergies.filter((alg) => !productAlgs.includes(alg.id))
        return filteredAllergies
    }

    const addAllergy = async (event) => {
        event.preventDefault()
        let allergyID = event.target.value
        await axios.put(BASE_URL + `/allergy/${allergyID}/associate`, {product_id: product.id}, config)
        allergies.find((a) => a.id === parseInt(allergyID))
        openAllergies(false)
        getData()
    }

     const removeAllergy = async (event) => {
        event.preventDefault()
        let allergyID = event.target.value
         await axios.put(BASE_URL + `/allergy/${allergyID}/detach`, {product_id: product.id}, config)
        getData()
     }

    const getAllergies = async (product) => {
        let res = await axios.get(BASE_URL + '/allergy/mobile', config)
        let algs = filterAvailableAlgs(res.data, product)
        setAllergies(algs)
    }

    const modifierFormHandling = async (data) => {
        let editData =  {
                name : data.mod_name, price : data.mod_price, sort_order : data.mod_order, product_id: product.id
            }
        if (moneyRegExp.test(data.mod_price)) {
            let res = await axios.post(BASE_URL + '/modifiers/create', editData, config)
            reset({ mod_name: '', mod_price: '', mod_order: ''})
            if (res.status === 200) {
            getData()
            setMoneyFormatMessage('')
            openModsForm(false)
            } else {
                setErrorAlert(true)
            }
        } else {
            setMoneyFormatMessage('Make sure to follow US Money Convensions')
        }
    }

    const modifierEditFormHandling = async (data, e) => {
        try {
            if (data.mod_price === undefined ) return
            let editData =  {
                name : data.mod_name, price : data.mod_price, sort_order : data.mod_order
            }
            if (moneyRegExp.test(data.mod_price)) {
                let res = await axios.put(BASE_URL + `/modifiers/${modalData.id}/edit`, editData, config)
                reset({ mod_name: '', mod_price: '', mod_order: ''})
                if (res.status === 200) {
                    getData()
                    setMoneyFormatMessage('')
                    setAlert(true)
                    openModsEditForm(false)
                } else {
                    setUpdate(false)
                    setErrorAlert(true)
                }
            } else {
                setMoneyFormatMessage('Make sure to follow US Money Convensions')
            }
        } catch (error) {
            console.log(error)
    }}


    const filterAvailableTags = (tags, currentProduct) => {
        let productAlgs = currentProduct.tags.map((tag) => tag.id)
        let filteredTags = tags.filter((alg) => !productAlgs.includes(alg.id))
        return filteredTags
    }

    const getTags = async (currentProduct) => {
        let res = await axios.get(BASE_URL + '/tags', config)
        let availableTags = filterAvailableTags(res.data, currentProduct)
        setTags(availableTags)
    }

    const addTag = async (e) => {
        let tagID = e.target.value
        await axios.put(BASE_URL + '/tags/associate/product', {tagId: parseInt(tagID), productId: product.id}, config)
        getData()
        openTags(false)
    }

    const removeTag = async (e) => {
        let tagID = parseInt(e.target.value)
        await axios.put(BASE_URL + '/tags/detach/product', {tagId: tagID, productId: product.id }, config)
        getData()
    }

    const filterAvailableSchools = (product, allSchools) => {
        let schoolsID = product.schools.map((school) => school.id)
        let filteredSchools = allSchools.filter((school) => !schoolsID.includes(school.id))
        return filteredSchools
    }
    const filterAvailableMenus = (product, allMenus) => {
        let menusIDs = product.menus.map((menu) => menu.id)
        let filteredSchools = allMenus.filter((menu) => !menusIDs.includes(menu.id))
        return filteredSchools
    }

    const assignNewSchool = async (e) => {
        let schoolID = e.target.value
        await axios.put(BASE_URL + `/schools/${schoolID}/attach/product`, { productID: product.id }, config)
        getData()
        openSchools(false)
    }

    const removeSchool = async (e) => {
        let menuID = e.target.value
        await axios.put(BASE_URL + `/schools/${menuID}/detach/product`, { productID: product.id }, config)
        getData()
        openSchools(false)
    }

    const toggleOutOfStock = async () => {
        let res = await axios.put(BASE_URL + `/product/${product.id}/toggle`, {}, config)
        if (res.status === 200) {
            getData()
        }
    }
    const toggleExtraItem = async () => {
        let res = await axios.put(BASE_URL + `/product/${product.id}/extra`, {}, config)
        if (res.status === 200) {
            getData()
        }
    }
    const toggleModfierItem = async () => {
        let res = await axios.put(BASE_URL + `/product/${product.id}/toggle/modifier`, {}, config)
        if (res.status === 200) {
            getData()
        }
    }

    const assignMenu = async (menuID) => {
       await axios.put(BASE_URL + '/menus/' + menuID + '/attach/product', {productID: product.id}, config)
       setMenusOpen(false)
       getData()
    }

    const removeMenu = async (menuID) => {
        await axios.put(BASE_URL + '/menus/' + menuID + '/detach/product', {productID: product.id}, config)
        setMenusOpen(false)
        getData()
    }


    const getCategories = async () => {
        try {
            let res = await axios.get(BASE_URL + '/categories', config)
            if (res.status === 200) {
                setCategories(res.data)
            }
        } catch (error) {
            setError('getCategories: ' + error.message)
            setCategories([])
        }
    }

    const getTypes = async () => {
        try {
            let res = await axios.get(BASE_URL + '/types', config)
            if (res.status === 200) {
                setTypes(res.data)
            }
        } catch (error) {
            setTypes([])
        }
    }

    const changeProductType = async () => {
        await axios.put(BASE_URL + "/product/" + product.id + '/type', {
            typeID: newType,
        }, config)
        setTypeModal(false)
        setNewType(0)
        getData()
    }
    const changeProductCat = async () => {
        await axios.put(BASE_URL + "/product/" + product.id + '/category', {
            catID: newCat,
        }, config)
        setCatModal(false)
        setNewCat(0)
        getData()
    }
if (!isLoaded)
     return (
        <>
        <RotatingLines
            strokeColor="grey"
            strokeWidth="5"
            animationDuration="0.75"
            width="40"
            visible={true}
            />
        </>
    )
 else return (
        <>
        <UserHeader/>
        <Container className="mt--7" fluid>
        <Alert color="success" isOpen={alertOpen}  fade={true} toggle={(e) => setAlert(false)}>
              Info updated!
        </Alert>
        <Alert color="danger" isOpen={alertError}  fade={true} toggle={(e) => setErrorAlert(false)}>
            Error updating customer information.
        </Alert>
            {/*Actual UI - SEE modals down below*/}
            {/* one Row with two columns */}
            <Row>
                {/* Big Tables */}
                <Col xl='8'>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                        <Col xs="6">
                        <h3 className="mb-0">Product Information.</h3>
                        </Col>
                        {/* <Col className="text-right" xs="3">
                            <h4>Type: {product.type.type_name}</h4>
                        </Col>
                        <Col className="text-right" xs="3">
                            <h4>Category: {product.category.name}</h4>
                        </Col>
                        <Col className="text-right" xs="3"> */}
                        <Col className="text-right" xs="3">
                        <Row className='align-items-center justify-content-evenly'>
                            <h3>
                            Category
                            </h3>
                            <hr></hr>
                            <UncontrolledDropdown>
                                <DropdownToggle
                                className="btn"
                                role="button"
                                size="lg"
                                //    color="primary"
                                onClick={(e) => e.preventDefault()}
                                >
                                     {product.category.name}
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                    {categories.map((cat) => 
                                        <DropdownItem onClick={(e) => {
                                            // Do the thing
                                            e.preventDefault()
                                            setCatModal(true)
                                            setNewCat(cat.id)
                                        }}>
                                            {cat.name}
                                        </DropdownItem>
                                    )}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Row>
                        </Col>
                        <Col className="text-right" xs="3">
                        <Row className='align-items-center justify-content-evenly'>
                        <hr></hr>
                            <h3>
                                Type
                            </h3>
                            <hr></hr>
                            <UncontrolledDropdown>
                                <DropdownToggle
                                className="btn"
                                role="button"
                                size="lg"
                                //    color="primary"
                                onClick={(e) => e.preventDefault()}
                                >
                                {product.type.type_name}
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                {types.map((type) => 
                                        <DropdownItem onClick={(e) => {
                                            // Do the thing
                                            e.preventDefault()
                                            setTypeModal(true)
                                            setNewType(type.id)
                                        }}>
                                            {type.type_name}
                                        </DropdownItem>
                                    )}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Row>
                        </Col>
                    </Row>
                    </CardHeader>
                    <CardBody>
                    <Form onSubmit={handleSubmit(editProductForm)}>
                        <div className="pl-lg-4">
                        <Row className='p-3 alert-red'>
                            {moneyFormatProductMessage && <span>{moneyFormatProductMessage}</span>}
                        </Row>
                        <Row>
                            <Col lg="6">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                                >
                                Name
                                </label>
                                <input
                                className="form-control"
                                defaultValue={product.name}
                                id="input-first-name"
                                placeholder="First name"
                                type="text"
                                {...register('name')}
                                required
                                />
                            </FormGroup>
                            </Col>
                            <Col lg="6">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="product_price"
                                >
                                Price
                                </label>
                                <input
                                className="form-control"
                                defaultValue={product.price}
                                id="input-last-name"
                                placeholder="Product Price"
                                type="text"
                                required
                                {...register('product_price')}
                                />
                            </FormGroup>
                            </Col>
                            <Col lg="6">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="calories"
                                >
                                Calories
                                </label>
                                <input
                                className="form-control"
                                defaultValue={product.calories}
                                id="calories"
                                placeholder=""
                                type="text"
                                {...register('calories')}
                                />
                            </FormGroup>
                            </Col>
                            <Col lg="6">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="cost"
                                >
                                Base Cost
                                </label>
                                <input
                                className="form-control"
                                defaultValue={product.cost}
                                id="cost"
                                placeholder="$0.0"
                                type="text"
                                {...register('cost')}
                                />
                            </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4">
                                <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="input-last-name"
                                    >
                                    Carbs
                                    </label>
                                    <input
                                    className="form-control"
                                    defaultValue={product.carbs}
                                    id="carbs"
                                    placeholder="Calculated Carb Macros"
                                    type="text"
                                    {...register('carbs')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="4">
                                <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="input-last-name"
                                    >
                                    Fats
                                    </label>
                                    <input
                                    className="form-control"
                                    defaultValue={product.fats}
                                    id="fats"
                                    placeholder="Calculated Fat Macros"
                                    type="text"
                                    {...register('fats')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="4">
                                <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="input-last-name"
                                    >
                                    Proteins
                                    </label>
                                    <input
                                    className="form-control"
                                    defaultValue={product.proteins}
                                    id="proteins"
                                    placeholder="Calculated Protein Macros"
                                    type="text"
                                    {...register('proteins')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                        <Col lg="8">
                        <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                                >
                                Description
                                </label>
                                <input
                                className="form-control"
                                id="proteins"
                                placeholder="Calculated Protein Macros"
                                type="text"
                                required
                                defaultValue={product.description}
                                {...register('description')}
                                />
                        </FormGroup>
                        </Col>
                        </Row>
                        <Row>
                           { updateLoad ? <span>...</span>: <Col lg="6">
                            <FormGroup>
                                <Button type="submit" color="success" >
                                    Update
                                </Button>
                            </FormGroup>
                            </Col>}
                        </Row>
                        </div>
                    </Form>
                    </CardBody>
                </Card>
                <br></br>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                        <Row className="justify-content-between align-items-baseline">
                            <h3 className="ml-4">Offered In Schools Below</h3>
                            <DropdownToggle
                                className="btn text-green mr-4"
                                role="button"
                                size="sm"
                                onClick={(e) => {
                                    openSchools(true)
                                }}
                            >
                        <span>Add School</span>
                        </DropdownToggle>
                        </Row>
                    </CardHeader>
                    <CardBody>
                    <div className="text-center">
                        <ListGroup>
                        {product.schools.map((school) =>(
                             <>
                                <ListGroupItem key={school.id}>
                                    <Row className='justify-content-between' key={school.id}>
                                     <span className='ml-4'>{school.name}</span> <Button size='sm' color='danger' className='mr-4' value={school.id} onClick={removeSchool}>x</Button>
                                    </Row> 
                                </ListGroupItem>
                             </> 
                            )
                        )}
                        </ListGroup>
                    </div>
                    </CardBody>
                </Card>
            <br></br>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                        <Row className="justify-content-between align-items-baseline">
                            <h3 className="ml-4">Offered In Daily Menus</h3>
                            <DropdownToggle
                                className="btn text-green mr-4"
                                role="button"
                                size="sm"
                                onClick={(e) => {
                                    setMenusOpen(true)
                                }}
                            >
                        <span>Add Menu</span>
                        </DropdownToggle>
                        </Row>
                    </CardHeader>
                    <CardBody>
                    <div className="text-center">
                        <ListGroup>
                        {product.menus.map((menu) =>(
                             <>
                                <ListGroupItem key={menu.id}>
                                    <Row className='justify-content-between' key={menu.id}>
                                     <span className='ml-4'>{menu.name}</span> <Button size='sm' color='danger' className='mr-4' value={menu.id} onClick={() => removeMenu(menu.id)}>x</Button>
                                    </Row> 
                                </ListGroupItem>
                            </> 
                            )
                        )}
                        </ListGroup>
                    </div>
                    </CardBody>
                </Card>
                </Col>
                <br></br>
                <Col xl='4'>
                    <Card className="card-profile shadow">
                        <CardHeader>
                        <Row className='justify-content-around align-items-center'>
                            <h3>{product.name}</h3>
                        </Row> 
                        <br></br>
                        <Row className='justify-content-center align-items-center'>
                                <Button 
                                    sm
                                    onClick={() => toggleOutOfStock()}
                                    color={product.out_of_stock ? 'danger' : 'success'}
                                    >
                                    {product.out_of_stock ? 'Out of Stock' : 'Available'}
                                </Button>
                                <Button 
                                    sm
                                    onClick={() => toggleExtraItem()}
                                    color={product.is_extra_item ? 'danger' : 'success'}
                                    >
                                    {product.is_extra_item ? 'Extra Item' : 'Regular'}
                                </Button>
                            </Row>
                            <br></br>
                            <Row className='justify-content-center align-items-center'>
                            <Button 
                                    sm
                                    onClick={() => toggleModfierItem()}
                                    color={product.is_modifier_required ? 'danger' : 'success'}
                                    >
                                    {product.is_modifier_required? 'Modifier Required' : 'No Modifier Required'}
                                </Button>
                            </Row>
                        </CardHeader>
                    <CardBody className="pt-0 pt-md-4">
                    <div className="text-center">
                        <br></br>
                            {product.tags.map((tag) => <>
                                <ListGroup key={tag.id}>
                                <Row className='justify-content-between' key={tag.id}>
                                <span className='ml-4'>{tag.name}</span> <Button size='sm' color='danger' className='mr-4' value={tag.id} onClick={removeTag}>x</Button>
                                </Row> 
                                </ListGroup>
                        <br></br>
                        </>)}
                        <br></br>
                        <Row className='justify-content-left'>
                        <DropdownToggle
                            className="btn text-green ml-4"
                            role="button"
                            size="sm"
                            onClick={(e) => {
                                openTags(true)
                            }}
                        >
                        <span>Add Tag</span>
                        </DropdownToggle>
                        </Row>
                    </div>
                    </CardBody>
                    </Card>
                    <br />
                    {
                    product && product.is_extra_item && <Card className="card-profile shadow">
                        <CardBody>
                            <Form onSubmit={handleSubmit(editProductExtraPrice)}>
                                
                                <Row>
                                <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                                >
                                Price as Extra Item
                                </label>
                                <br></br>
                                <input 
                                    className="form-control"
                                    id="extra_item_price"
                                    placeholder="Price as Extra"
                                    type="text"
                                    required
                                    defaultValue={product.extra_item_price}
                                    {...register('extra_price')}
                                />
                                </Row>
                                <br></br>
                                <Row className='justify-content-center'>
                                <Button className='px-4' type="submit" sm color='success'>Send</Button>
                                </Row>
                            </Form>
                        </CardBody>
                    </Card>
                    }
                    <br></br>
                    <Card className="card-profile shadow">
                            <img src={product.image_url} alt='product-pic' className='py-4 px-4'/>
                        <CardFooter>
                            {/* Change the cancel button for a save button that submits a request if there is a file selected */}
                        {imageUpdate ? <>
                        <input type='file' accept='.jpg,.png,.jpeg' onChange={handleFileChange}></input>
                        {newProductImage ? <Button type ='button' color='success' size='sm' onClick={updateImage}> Save </Button> :  <Button type='button' color='danger' size='sm' onClick={(e) => setImageUpdate(false)}>Cancel</Button> }
                       
                        </>
                        :
                        <Button type="button" color="success" size='sm' onClick={(e) => setImageUpdate(true)}>
                            Update
                        </Button>}
                        </CardFooter>
                    </Card>
                    <br></br>
                    <Card className="card-profile shadow">
                        <CardHeader>
                        <div className="text-center">
                            <h3>
                            Allergies 
                            <span className="font-weight-light"></span>
                            </h3>
                        </div>
                        </CardHeader>
                        <CardBody className="pt-0 pt-md-4">
                        <div className="text-center">
                        {product.allergies.length > 0 ?
                        <ListGroup>
                        {product.allergies.map((alg) => {
                        return<> 
                        <ListGroup key={alg.id}>
                            <Row className='justify-content-between' key={alg.id}>
                            <span className='ml-4'>{alg.name}</span> <Button size='sm' color='danger' className='mr-4' value={alg.id} onClick={removeAllergy}>x</Button>
                            </Row> 
                        </ListGroup>
                            <br></br>
                            </>
                        })}
                        </ListGroup>
                        : <span>This product has no allergies</span>}
                        </div>
                        <br></br>
                        <Dropdown>
                            <DropdownToggle
                                className="btn text-green"
                                role="button"
                                size="sm"
                                onClick={(e) => {
                                    openAllergies(true)
                                }}
                            >
                            <span>Add</span>
                            </DropdownToggle>
                        </Dropdown>
                        </CardBody>
                    </Card>
                    <br></br>
                    <Card className="card-profile shadow">
                        <CardHeader>
                        <div className="text-center">
                            <h3>
                            Modifiers
                            <span className="font-weight-light"></span>
                            </h3>
                        </div>
                        </CardHeader>
                        <CardBody className="pt-0 pt-md-4">
                        <div className="text-center">
                         {product.modifiers.length > 0 ?  
                          <ListGroup>
                          {product.modifiers.map((mod) => {
                        return<> 
                        <ListGroup key={mod.id}>
                            <Row className='justify-content-between' key={mod.id}>
                            <Col>{mod.name}</Col>
                            <Col>${mod.price}</Col>
                            <Col>{mod.sort_order}</Col>
                             <UncontrolledDropdown>
                                <DropdownToggle
                                className="btn-icon-only text-light"
                                role="button"
                                size="sm"
                                color="success"
                                >
                                <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                    
                                <DropdownItem
                                    onClick={(e) => {
                                    e.preventDefault();
                                    setModalData(mod); 
                                    openModsEditForm(true)
                                    }}>
                                    Edit
                                </DropdownItem>
                                <DropdownItem
                                onClick={(e) => {
                                    fetch(BASE_URL + "/modifiers/" + mod.id,  {
                                        method: 'DELETE',
                                    })
                                        .then(res => res.json())
                                        .then(
                                            (data) => {
                                                setIsLoaded(true);
                                                getData()
                                            },
                                            (error) => {
                                                setIsLoaded(true);
                                                setError(error);
                                            }
                                        )
                                    }}
                                >
                                Delete
                                </DropdownItem>
                                </DropdownMenu>
                             </UncontrolledDropdown>
                            </Row>
                             </ListGroup>
                              <br></br>
                              </>
                           })}
                        </ListGroup>
                         
                         : <span>This product has no modifiers yet</span>}
                        </div>
                        <br></br>
                        <Dropdown>
                        <DropdownToggle
                            className="btn text-green"
                            role="button"
                            size="sm"
                            onClick={(e) => {
                                openModsForm(true)
                            }}
                        >
                        <span>Add</span>
                        </DropdownToggle>
                        </Dropdown>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {/* MODALS */}
            <br></br>
          
            <Modal isOpen={allergiesOpen}>
                <div className="modal-header">
                    <h5 className="modal-title" id="alg-modal">
                    Assign Allergies
                    </h5>
                    <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={(e) => openAllergies(false)}
                    >
                    <span aria-hidden={true}>×</span>
                    </button>
                </div>
            <div className="modal-body">
                <ListGroup >
                    {allergies.map((alg) =>  {
                        return  <ListGroupItem key={alg.id}> 
                            <Row className='justify-content-between'>
                            <span className='ml-4'>{alg.name}</span> 
                            <Button size='sm' color='success' className='mr-4' value={alg.id} onClick={addAllergy} >
                                Add
                                </Button>
                            </Row> 
                            </ListGroupItem>
                    })}
                </ListGroup>
            </div>
                <div className="modal-footer">
                    <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={(e) => openAllergies(false)}
                    >
                    Close
                    </Button>
                </div>
            </Modal>
              <Modal isOpen={schoolsOpen}>
                <div className="modal-header">
                    <h5 className="modal-title" id="menu-modal">
                    Assign Schools
                    </h5>
                    <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={(e) => openSchools(false)}
                    >
                    <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <ListGroup>
                        {schools.map((school) =>  {
                            return  <ListGroupItem key={school.id}> 
                                <Row className='justify-content-between'>
                                <span className='ml-4'>{school.name}</span> 
                                <Button size='sm' color='success' className='mr-4' value={school.id} onClick={assignNewSchool} >
                                    Add
                                </Button>
                                </Row> 
                                </ListGroupItem>
                        })}
                    </ListGroup>
                </div>
                <div className="modal-footer">
                    <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={(e) => openSchools(false)}
                    >
                    Close
                    </Button>
                 </div>
            </Modal>
            <Modal isOpen={menusOpen}>
                <div className="modal-header">
                    <h5 className="modal-title" id="menu-modal">
                    Assign Menus
                    </h5>
                    <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={(e) => setMenusOpen(false)}
                    >
                    <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <ListGroup>
                        { menus.map((menu) =>  {
                            return  <ListGroupItem key={menu.id}> 
                                <Row className='justify-content-between'>
                                <span className='ml-4'>{menu.name}</span> 
                                <Button size='sm' color='success' className='mr-4' value={menu.id} onClick={() => assignMenu(menu.id)} >
                                    Add
                                </Button>
                                </Row> 
                                </ListGroupItem>
                        })}
                    </ListGroup>
                </div>
                <div className="modal-footer">
                    <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={(e) => setMenusOpen(false)}
                    >
                    Close
                    </Button>
                 </div>
            </Modal>
            <Modal isOpen={tagsOpen}>
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                Assign Tags
                </h5>
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => openTags(false)}
                >
                <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="modal-body">
            <ListGroup >
                {tags.map((tag) =>  {
                    return  <ListGroupItem key={tag.id}> 
                        <Row className='justify-content-between'>
                         <span className='ml-4'>{tag.name}</span> 
                         <Button size='sm' color='success' className='mr-4' value={tag.id} onClick={addTag} >
                            Add
                            </Button>
                        </Row> 
                        </ListGroupItem>
                })}
                </ListGroup>
            </div>
            <div className="modal-footer">
            </div>
            </Modal>
            <Modal isOpen={modifierFormOpen}>
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                Create Modifiers
                </h5>
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => openModsForm(false)}
                >
                <span aria-hidden={true}>×</span>
                </button>
            </div>
            <Form onSubmit={handleSubmit(modifierFormHandling)}>
            <div className="modal-body">
                <Row>
                    <Col lg="6">
                        <label
                        className="form-control-label"
                        htmlFor="mod_name"
                        >
                        Name
                        </label>
                        <input
                        className="form-control"
                        id="mod-name"
                        placeholder="Name"
                        type="text"
                        {...register('mod_name')}
                        required
                        />
                    </Col>
                    <Col lg="4">
                        <label
                        className="form-control-label"
                        htmlFor="mod_price"
                        >
                        Price
                        </label>
                        <input
                        className="form-control"
                        id="mod-price"
                        placeholder="$1.50"
                        type="string"
                        title='Please use correct formatting'
                        {...register('mod_price')}
                        required
                        />
                    </Col>
                     <Col lg="2">
                        <label
                        className="form-control-label"
                        htmlFor="mod_order"
                        >
                        Rank
                        </label>
                        <input
                        className="form-control"
                        id="mod-order"
                        placeholder= ""
                        type="string"
                        title='Please use correct formatting'
                        {...register('mod_order')}
                        />
                    </Col>
                </Row>
                <Row className='p-3' alert-red>
                   {moneyFormatMessage && <span>{moneyFormatMessage}</span>}
                </Row>
            </div>
            <div className="modal-footer">
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={(e) => openModsForm(false)}
                >
                Close
                </Button>
                <Button
                    color="primary"
                    data-dismiss="modal"
                    type="submit"
                    // onClick={(e) => openModsForm(false)}
                >
                Save
                </Button>
            </div>
            </Form>
            </Modal>
            <Modal isOpen={modifierEditFormOpen}>
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                Edit Modifier
                </h5>
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => openModsEditForm(false)}
                >
                <span aria-hidden={true}>×</span>
                </button>
            </div>
            <Form onSubmit={handleSubmit(modifierEditFormHandling)}>
            <div className="modal-body">
                <Row>
                    <Col lg="6">
                        <label
                        className="form-control-label"
                        htmlFor="mod_name"
                        >
                        Name
                        </label>
                        <input
                        className="form-control"
                        id="mod-name"
                        placeholder= {modalData.name}
                        type="text"
                        {...register('mod_name')}
                        />
                    </Col>
                    <Col lg="4">
                        <label
                        className="form-control-label"
                        htmlFor="mod_price"
                        >
                        Price
                        </label>
                        <input
                        className="form-control"
                        id="mod-price"
                        placeholder= {modalData.price}
                        type="string"
                        {...register('mod_price')}
                        />
                    </Col>
                    <Col lg="2">
                        <label
                        className="form-control-label"
                        htmlFor="mod_order"
                        >
                        Rank
                        </label>
                        <input
                        className="form-control"
                        id="mod-order"
                        placeholder= {modalData.sort_order}
                        type="string"
                        {...register('mod_order')}
                        />
                    </Col>
                </Row>
                <Row className='p-3 alert-red'>
                   {moneyFormatMessage && <span>{moneyFormatMessage}</span>}
                </Row>
            </div>
            <div className="modal-footer">
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={(e) => openModsEditForm(false)}
                >
                Close
                </Button>
                <Button
                    color="primary"
                    data-dismiss="modal"
                    type="submit"
                    // onClick={(e) => openModsEditForm(false)}
                >
                Save
                </Button>
            </div>
            </Form>
            </Modal>
            <Modal 
                isOpen={catModalOpen}
                className="modal-dialog-centered"
                size="sm"           
            >
               <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                        <CardHeader>
                        <h2>Change Product Category</h2>
                        </CardHeader>
                        <CardBody>
                        <p>Click "save" to confirm you are changing the product category.</p>
                        {/* <Input value={activationString} onChange={(e) => setString(e.target.value)}></Input> */}
                        </CardBody>
                        <CardFooter>
                            <Row className='justify-content-center'>
                                <Button onClick={() => {
                                    setCatModal(false)
                                    setNewCat(0)
                                    }}> Cancel</Button>
                                <Button
                                color='success'
                                onClick={() => changeProductCat()}
                                >Save</Button>
                            </Row>
                        </CardFooter>
                    </Card>
                </div>
            </Modal>

            <Modal 
                isOpen={typeModalOpen}
                className="modal-dialog-centered"
                size="sm"  
            >
                <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                        <CardHeader>
                        <h2>Change Product Type</h2>
                        </CardHeader>
                        <CardBody>
                        <p>Click "save" to confirm you are changing the product type.</p>
                        {/* <Input value={activationString} onChange={(e) => setString(e.target.value)}></Input> */}
                        </CardBody>
                        <CardFooter>
                            <Row className='justify-content-center'>
                                <Button onClick={() => {
                                    setTypeModal(false)
                                    setNewType(0)
                                    }}> Cancel</Button>
                                <Button
                                color='success'
                                onClick={() => changeProductType()}
                                >Save</Button>
                            </Row>
                        </CardFooter>
                    </Card>
                </div>
            </Modal>
        </Container>
        </>
    )
}


export default ProductProfile