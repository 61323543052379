import {
Card,
CardBody,
CardHeader,
Container, 
Row,
Form,
Col,
FormGroup,
Button,
InputGroup,
Input,
} from "reactstrap";
  // core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect }  from 'react';
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { ReactSession } from 'react-client-session';

const CreateProductForm = () => {
    const { handleSubmit, register, control } = useForm();
    const BASE_URL = process.env.REACT_APP_BASE_URL
    // Things get filtered and set for the modals, except for the modifiers
    let [categories, setCategories] = useState([]);
    let [types, setTypes] = useState([]);
    let [type, setType] = useState('')
    let [category, setCategory] = useState([]);
    let [isLoaded, setIsLoaded] = useState(false)
    let [updateLoad, setUpdate] =useState(false)
    const [error, setError] = useState(null);
    const { id } = useParams()
    const  history  = useHistory()
    let [fileName, setFileName] = useState('')
    let [file, setFile] = useState('')
    let [invalidFile, setInvalidFile] = useState(false)

    const token = ReactSession.get("token");
    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }
  

    useEffect(() => {
        getCateogryData()
        getTypes()
    }, [])

    const getCateogryData = async () => {
        try {
            let res = await axios.get(BASE_URL + '/categories', config)
            setCategories(res.data)
        } catch (error) {
            setCategories([])
        }
    }

    const getTypes = async () => {
        try {
            let res = await axios.get(BASE_URL + '/types', config)
            setTypes(res.data)
        } catch (error) {
            setTypes([])
        }
    }

    const createProduct = async (data) => {
        if (category === []) {
            // Cateogry not selected
            return
        }
        let productData = {
            name: data.name,
            price: data.price,
            cost: data.cost,
            categoryId: category,
            typeId: type,
            description:data.description,
            calories: data.calories,
            carbs: data.carbs,
            fats: data.fats,
            proteins: data.proteins,
        }
        if (file) {
            var res = await axios.post(BASE_URL + '/product/create', productData)
            if (res.status === 200) {
                // IF file was created add the image to it
                let productID = res.data.id
                const formData = new FormData()
                formData.append('product_img', file)
                await axios.put(BASE_URL + `/product/${productID}/image`, formData)
                history.push(`/admin/product/${res.data.id}`)
            } else {
                // Add some error handling.
            }
        } else {
            setInvalidFile(true)
        }
    }

    const handleFileChange = async (evt) => {
        setFile(evt.target.files[0])
    }
    return (
        <>
        <UserHeader/>
        <Container fluid className="mt--7" >
        <Row className='align-items-center justify-content-center'>
        <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                    <Col xs="8">
                    <h3 className="mb-0">Create Product</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                    {/* <h4>Category: {product.category.name}</h4> */}
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Form onSubmit={handleSubmit(createProduct)}>
                    <div className="pl-lg-4">
                        <Row>
                            <Col lg="6">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                                >
                                Name
                                </label>
                                <input
                                className="form-control"
                                id="input-first-name"
                                placeholder="Product name"
                                type="text"
                                {...register('name')}
                                required
                                />
                            </FormGroup>
                            </Col>
                            <Col lg="6">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="product_price"
                                >
                                Price
                                </label>
                                <input
                                className="form-control"
                                id="input-last-name"
                                placeholder="Product Price"
                                type="text"
                                required
                                {...register('price')}
                                />
                            </FormGroup>
                            </Col>
                            <Col lg="6">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="calories"
                                >
                                Calories
                                </label>
                                <input
                                className="form-control"
                                id="calories"
                                placeholder=""
                                type="text"
                                {...register('calories')}
                                />
                            </FormGroup>
                            </Col>
                            <Col lg="6">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="cost"
                                >
                                Base Cost
                                </label>
                                <input
                                className="form-control"
                                id="cost"
                                placeholder=""
                                type="text"
                                {...register('cost')}
                                />
                            </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                        <Col lg="4">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                                >
                                Carbs
                                </label>
                                <input
                                className="form-control"
                                id="carbs"
                                placeholder="Calculated Carb Macros"
                                type="text"
                                {...register('carbs')}
                                />
                            </FormGroup>
                            </Col>
                            <Col lg="4">
                                <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="input-last-name"
                                    >
                                    Fats
                                    </label>
                                    <input
                                    className="form-control"
                                    id="fats"
                                    placeholder="Calculated Fat Macros"
                                    type="text"
                                    {...register('fats')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="4">
                                <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="input-last-name"
                                    >
                                    Proteins
                                    </label>
                                    <input
                                    className="form-control"
                                    id="proteins"
                                    placeholder="Calculated Protein Macros"
                                    type="text"
                                    {...register('proteins')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                        <Col lg="7">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                                >
                                Description
                                </label>
                                <input
                                className="form-control"
                                id="proteins"
                                placeholder="Your Product Description"
                                type="text"
                                required
                                {...register('description')}
                                />
                            </FormGroup>
                        </Col>
                        <Col >
                        <label className="form-control-label" htmlFor="basic-url">
                         Category
                        </label>
                        <InputGroup>
                            <Input
                                id="category-dropdown"
                                name="category"
                                type="select"
                                required
                                value={category.name}
                                onChange = {e => {
                                e.preventDefault()
                                setCategory(e.target.value)
                                }}
                            >
                                <option value="0">
                                    Select a Category
                                </option>
                            {categories.map((cat) => <option key={cat.id} value={cat.id}>{cat.name}</option>)}
                            </Input>
                        </InputGroup>
                        </Col>
                        <Col >
                        <label className="form-control-label" htmlFor="basic-url">
                         Type
                        </label>
                            <InputGroup>
                                <Input
                                    id="category-dropdown"
                                    name="category"
                                    type="select"
                                    required
                                    value={type.name}
                                    onChange = {e => {
                                        e.preventDefault()
                                        setType(e.target.value)
                                    }}
                                >
                                    <option value="0">
                                        Select a Type
                                    </option>
                                {types.map((typ) => <option key={typ.id} value={typ.id}>{typ.type_name}</option>)}
                                </Input>
                            </InputGroup>
                        </Col>
                        <Row>
                            <Col>
                            <label
                            className="form-control-label ml-3"
                            htmlFor="productImage"
                            >
                                Product File
                            </label>
                        
                            <br />
                                <input className='ml-3' type='file' accept='.png,.jpg,.jpeg' onChange={handleFileChange}></input>
                                <br />
                            <span className='ml-4 my-4' color="danger">{ invalidFile ? 'Make sure to provide a valid file' : '' }</span>
                            </Col>
                        </Row>
                        </Row>
                        <br></br>
                        <Row>
                           { updateLoad ? <span>...</span>: <Col lg="6">
                            <FormGroup>
                                <Button type="submit" color="success" >
                                    Create
                                </Button>
                            </FormGroup>
                            </Col>}
                        </Row>
                        </div>
                    </Form>
                    </CardBody>
                </Card>
        </Row>
        </Container>
        </>
    )
}

export default CreateProductForm