import {
Card,
CardBody,
CardHeader,
CardFooter,
Container, 
Row,
Form,
Col,
FormGroup,
Button,
Alert,
Input,
Dropdown,
DropdownItem,
DropdownToggle,
DropdownMenu,
Modal,
Label,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect }  from 'react';
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { DateTime } from 'luxon'
import 'react-loading-skeleton/dist/skeleton.css'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const CreateOrder = () => {
    // Deffining state functinality, page behaves differently according to these
    const [customer, setCustomer] = useState([]);
    const BASE_URL = process.env.REACT_APP_BASE_URL
    let [customerRole, setRole] = useState('')
    let [loading, setLoading] = useState(true)
    // Customer State
    let [students, setStudents] = useState([])
    let [selectedStudent, setStudent]  = useState('')
    let [studentSelected, setStudentSelected] = useState(false)
    let [studentsOpen, setStudentsOpen] = useState(false)
    let [cardsOpen, setCardsOpen] = useState(false)
    let [selectedCard, setCard] = useState(false)
    //
    let [drinks, setDrinks] = useState([])
    let [snacks, setSnacks] = useState([])
    // Product State
    let [products, setProducts] = useState([])
    let [productsOpen, setProdOpen] = useState(false)
    let [selectedProduct, setProduct] = useState({ product: null })
    let [productSelected, setProductSelected] = useState(false)
    let [productName, setProductName] = useState('')
    let [snackName, setSnackName] = useState('')
    let [drinkName, setDrinkName] = useState('')
    let [modsOpen, setModsOpen] = useState(false)
    let [selectedMod, setMod] = useState([])
    //
    let [drinkSelected, setDrinkSelected] = useState(false)
    let [selectedDrink, setDrink] = useState({ drink: null })
    let [snackSelected, setSnackSelected] = useState(false)
    let [selectedSnack, setSnack] = useState({ snack: null })
    let [bagTotal, setBagTotal] = useState(0)
    // Alert stuff
    let [creatingOrder, setCreatingOrder] = useState(false)
    let [alertSuccess, setSuccessAlert] = useState(false)
    let [alertError, setAlertError] = useState(false)
    let [alertErrorMessage, setAlertMessage] = useState('')
    let [startDate, setStartDate] = useState(new Date());
    let [emptyCart, setEmptyCart] = useState('')
    //
    let [instructions, setInstruction] = useState('')
    let [kidMessage, setKidMessage] = useState('')
    // Cart stuff
    let [cart, setCart] = useState('')
    let [subTotal, setSubTotal] = useState(0)
    let [lateFee, setFee] = useState(0)
    let [total, setTotal] = useState(0)
    let [creditApplied, setCredit] = useState(0)
    let [chargeAmount, setChargeAmount] = useState(0)
    let [receiverRole, setReceiverRole] = useState('')
    let [deleteCartMealModal, setModalOpen] = useState(false)
    let [mealIDOnDelete, setMealID] = useState(0)
    const { id } = useParams();
    const { handleSubmit, register, control } = useForm();

    // About the isLate state
    let [isLate, setIsLate] = useState(true)

    const token = ReactSession.get("token");
    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }
  
    const createOrder = async () => {
      
        if (cart.cartMeals.length === 0) {
             setEmptyCart('Your cart is empty.')
        } else {
            setCreatingOrder(true)
            setLoading(true)
            try {
                let res = await axios.post(BASE_URL + '/orders/create', {cartID: cart.id, isLate: isLate}, config)
                if (res.status === 200) {
                    getCart()
                    setSuccessAlert(true)
                    setCreatingOrder(false)
                    setLoading(false)
                }
            } catch (error) {
                console.log(error.response)
                if (error.response && error.response.data.message) {
                    setAlertMessage(error.response.data.message)
                } else {
                    setAlertMessage('There was an error creating your order.')
                }
                setAlertError(true)
                setCreatingOrder(false)
                setLoading(false)
            }
        
        }
    }

    const getProducts = async (student,) => {
        let res = await axios.get(BASE_URL + '/product/admin?&name=' + productName, config) 
        if (res.status === 200) {
            let prods = filterAllergies(res.data)
            setProducts(prods)
        }
    }

    const filterAllergies = (itemList) => {
        // Compare student allergies vs each product allergy 
        // if student or customer is allergic should not return the product as available.
        let studentAllergies = selectedStudent ? selectedStudent.allergies.map((alg) => alg.id) : []
        let finalList = []
        itemList.forEach((item) => {
            let itemAlgs = item.allergies.map((alg) => alg.id)
            let isItemAllergicToCustomer = itemAlgs.some(i => studentAllergies.includes(i))
            if (!isItemAllergicToCustomer) {
                finalList.push(item)
            } 
        })
        return finalList
    }

    const getCustomerInfo = async () => {
        let data = await axios.get(BASE_URL + '/customers/' + id, config)
        setCustomer(data.data)
        setStudents(data.data.parent.students)
        setRole(data.data.role)
        setLoading(false)
        // setAllergies(data.data.allergies);
        // setStudents(data.data.parent.students);
    }


    const getDrinks = async () => {
        let res = await axios.get(BASE_URL + '/addons/drinks/admin?name=' + drinkName, config)
        let filteredDrinks = filterAllergies(res.data)
        setDrinks(filteredDrinks)
    }

    const getSnacks = async () => {
        let res = await axios.get(BASE_URL + '/addons/snacks/admin?name=' + snackName, config)
        let filteredSnacks = filterAllergies(res.data)
        setSnacks(filteredSnacks)
    }

    const selectProduct = (pr) => {
        let newProduct = pr
        setProduct({ product: newProduct })
        setProductSelected(true)
        // calculatePrice()
    }

    const selectSnack = (sn) => {
        let newSnack = sn
        setSnack({snack: newSnack})
        setSnackSelected(true)
        // calculatePrice()
    }
    
    const selectDrink = (dr) => {
        let newDrink = dr
        setDrink({ drink: newDrink })
        setDrinkSelected(true)
        // calculatePrice()
    }

    const selectModfifier = (mod) => {
        selectedMod.push(mod)
        setMod(selectedMod)
        calculatePrice()
    }

    const selectPM = async (card) => {
        setLoading(true)
        await axios.put(BASE_URL + '/cart/' + cart.id + '/pm/' + card.id + '/admin', {
            userId: customer.user_id
        }, config)
        getCart()
        setLoading(false)
    }

    const getCart = async () => {
        let res = await axios.get(BASE_URL + '/cart/' + id + '/admin', config)
        if (res.status === 200) {
            setCart(res.data)
            setSubTotal(res.data.sub_total)
            setTotal(res.data.cart_total)
            setCredit(res.data.credit)
            setChargeAmount(parseFloat(res.data.cart_total - res.data.credit))
            setCard(res.data.lastPaymentUsed)
        } else {
            setCart({
                    cartMeals: [],
                    sub_total: '0.00',
                    cart_total: '0.00',
                    credit: '0.00'
                })
        }
    }

    const getLateFee = async ()  => {
        let res = await axios.get(BASE_URL + "/settings/latefee", config)
        if (res.status === 200) {
            if (isLate) {
                setFee(res.data)
            } else {
                //  HI :)
                setFee('0.0')
            }
        }
    }

    const clearForm = () => {
        setStudent('')
        setStudentSelected(false)
        setProductSelected(false)
        setDrink({ drink: null })
        setProduct({ product: null })
        setSnack({ snack: null })
        setDrinkSelected(false)
        setSnackSelected(false)
        setMod([])
        setInstruction('')
        setKidMessage('')
    }
    const addToCart = async () => {
        if (
            selectedStudent &&
            selectedProduct.product
            && selectedDrink.drink
            && selectedSnack.snack
            && selectedMod.length >= 0) {
                let modIds = selectedMod.map((mod) => mod.id)
                //  Plus one becase this functions return from 0 to 11 January being 0
                //  Day being the same thing
                const [month, day, year] = [(startDate.getMonth() + 1).toString(),( startDate.getDate()).toString(), startDate.getFullYear()];
                let monthDate = month.length === 1 ? '0' + month : month 
                let  dayDate = day.length === 1 ? '0' + day : day
                let deliveryDate = year.toString() + '-' +  monthDate  + '-' + dayDate
                let cartMealContent = {
                    customerId: id,
                    receiverRole: receiverRole,
                    receiverId: selectedStudent.id,
                    productId: selectedProduct.product.id,
                    drinkId: selectedDrink.drink.id,
                    snackId: selectedSnack.snack.id,
                    mealDate: deliveryDate,
                    instructions: instructions,
                    message: kidMessage,
                    modifiers: modIds,
                }

                try {
                    setLoading(true)
                    await axios.post( BASE_URL + '/cart/create', cartMealContent, config )
                    clearForm()
                    getCart()
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                } 
    
                // All good to add
        } else {
            // Missing info
            let message = ''
            if (!selectedProduct.product) {
                message += '\n Please make sure to select a product.\n'
            }
            if (!selectedStudent) {
                message += '\n Please make sure to select a student.\n\n'
            }
            if (!selectedDrink.drink) {
                message += '\n Please select your lunchbag drink.\n'
            }
            if (!selectedSnack.snack) {
                message += '\n Please select your lunchbag snack.\n'
            }
            setAlertMessage(message)
            setAlertError(true)
        }
    }

    const selectStudent = (student) => {
        setStudent(student)
        setStudentSelected(true)
        setReceiverRole('student')
        setStudentsOpen(false)
    }

    const selectCustomer = (customer) => {
        setStudent(customer)
        setStudentSelected(true)
        setReceiverRole('teacher') 
        setStudentsOpen(false)
    }

    

    const calculatePrice = () => {
        let productPrice = productSelected ? parseFloat(selectedProduct.product.price) : 0
        let modsPrice = selectedMod.map((mod) => parseFloat(mod.price))
        let totalMods = 0
        for (let modPrice of modsPrice) {
            totalMods += modPrice
        }
        let snackPrice = selectedSnack.snack ? parseFloat(selectedSnack.snack.price) : 0
        let drinkPrice = selectedDrink.drink ? parseFloat(selectedDrink.drink.price): 0
        let currentTotal = productPrice + totalMods + snackPrice + drinkPrice

        setBagTotal(currentTotal.toFixed(2))
    }

    const deleteCartMeal = async () => {
        setModalOpen(false)
         await axios.put(BASE_URL + '/cart/remove/meal' ,
        { mealID: mealIDOnDelete }, config
        )
        getCart()
    }



    useEffect(() => {
            getProducts()
            getSnacks()
            getDrinks()
    }, [selectedStudent, 
        productName,
        snackName,
        drinkName,
    ])


    useEffect(() => {
        if (!creatingOrder) {
            getCustomerInfo()
            calculatePrice()
            getLateFee()
            getCart()
        }
    }, [
        selectedStudent,
        loading,
        bagTotal, 
        selectedProduct,
        selectedMod,
        selectedSnack,
        selectedDrink,
        isLate])
     
    if (loading) {
        return (
        <>
        <UserHeader></UserHeader>
        <Container fluid className="mt--7">
        <Row className='align-items-center justify-content-center'>
        <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                    <Col xs="8">
                    <h3 className="mb-0">Create New Order</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                   
                    </Col>
                </Row>
            </CardHeader>
            <CardBody >
              <SkeletonTheme
                    baseColor="#5294e0"
                    highlightColor="#96c7ff"
                    borderRadius="0.5rem"
                    duration={4}
                >
            </SkeletonTheme>
              <Skeleton count={10} width="100%"/>
            </CardBody>
        </Card>
        </Row>
    </Container>
        </>
        )
    } else return (
    <>
    <UserHeader></UserHeader>
    <Container fluid className="mt--7">
        <Alert color="success" isOpen={alertSuccess}  fade={true} toggle={(e) => setSuccessAlert(false)}>
              Order Created!
        </Alert>
        <Alert color="danger" isOpen={alertError}  fade={true} toggle={(e) => setAlertError(false)}>
            {alertErrorMessage}
        </Alert>
        <Row className='align-items-start justify-content-center'>
        <Col lg='8'>
        <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                    <Col xs="8">
                    <h3 className="mb-0">Create New Order</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                    <h3 className="mb-0">Total: ${bagTotal}</h3>

                    {/* <h4>Category: {product.category.name}</h4> */}
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
            <Form onSubmit={handleSubmit(addToCart)}>
                <Row>
                <Col lg='8'>
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="input-students"
                        >
                        Student
                        </label>
                        <br></br>
                        <Dropdown toggle={() => setStudentsOpen(!studentsOpen)} isOpen={studentsOpen}>
                        <DropdownToggle>
                            { selectedStudent ? <span>{selectedStudent.first_name} {selectedStudent.last_name}</span> : <span>Select a student</span>}
                        </DropdownToggle>
                        <DropdownMenu>
                            {students.map((student) => (
                            <div key={student.id}>
                                <DropdownItem onClick={() => selectStudent(student)}>{student.first_name} {student.last_name}</DropdownItem>
                            </div>
                            ))}

                            {customerRole === 'teacher' ? (   <div key={customer.id}>
                                <DropdownItem onClick={() => selectCustomer(customer)}>Yourself (Only for teachers)</DropdownItem>
                            </div>) : null }
                        </DropdownMenu>
                    </Dropdown>
                    </FormGroup>
                </Col>
                <Col lg='4'>
                        <label
                            className="form-control-label"
                            htmlFor="input-students"
                        >
                        Select A Date
                        </label>
                        <br></br>
                    <DatePicker selected={startDate} onChange={(date) => {
                        setStartDate(date)
                    }}
                    className='form-control'
                    />
                </Col>
            </Row>
                <Row>
                    <Col lg='6'>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                    >
                    Select a product    
                    </label>
                    <br></br>
                    <Dropdown toggle={() => setProdOpen(!productsOpen)} isOpen={productsOpen} flip={'true'} >
                       <Row className='px-2'>
                            <DropdownToggle disabled={!studentSelected}>
                            {selectedProduct.product ? <span>{selectedProduct.product.name} ${selectedProduct.product.price}</span>:  <Input disabled={!studentSelected} onChange={(e) => setProductName(e.target.value)} placeholder="Search for a product" />}
                            </DropdownToggle>
                            {productSelected && <Button onClick={() => {
                                setProductSelected(false)
                                setProduct({product: null})
                                setMod([])
                                calculatePrice()
                            }}><i className='ni ni-fat-remove'></i></Button>}
                        </Row>
                        <DropdownMenu children>
                            {/* Just display a list of 9 products */}
                            {products.slice(0,8).map((prod) => (
                            <div key={prod.id}>
                                <DropdownItem onClick={() => selectProduct(prod)}>{prod.name} ${prod.price}</DropdownItem>
                             </div>
                            ))}
                            <div key={0}>
                                <DropdownItem >Search to find more...</DropdownItem>
                             </div>
                        </DropdownMenu>
                    </Dropdown>
                    </Col>
                    <Col lg='6'>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                    >
                    Select your Modifiers    
                    </label>
                    <br></br>
                    <Dropdown toggle={() => {
                        if (productSelected) {
                            setModsOpen(!modsOpen)
                        }
                    }} isOpen={modsOpen}>
                        <DropdownToggle>
                        { selectedProduct.product ? selectedMod.length > 0 ? <ul className='p-0 mx-2 my-0'>{selectedMod.map((mod) => <li key={mod.id}>{mod.name} ${mod.price}</li>)}</ul> :<span>Select a modifier</span>: <span>Choose your product before selecting a modifier</span>}
                        </DropdownToggle>
                        <DropdownMenu children>
                            { selectedProduct.product && selectedProduct.product.modifiers.map((mod) => {
                                if(selectedMod.includes(mod)) {
                                    return null
                                } else {
                                    return (
                                    <div key={mod.id}>
                                        <DropdownItem onClick={() => selectModfifier(mod)}>{mod.name} ${mod.price}</DropdownItem>
                                    </div>
                                 )
                                }
                            })}
                        </DropdownMenu>
                    </Dropdown>
                    </Col>
                </Row>
                <br></br>
                <Row>
                <Col lg='6'>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                        >
                    Select a Snack    
                    </label>
                    <br></br>
                    <Dropdown toggle={() => {
                            setSnackSelected(!snackSelected)
                    }} isOpen={snackSelected}>
                        <DropdownToggle disabled={!studentSelected}>
                        { selectedSnack.snack ? <span>{selectedSnack.snack.name} - ${selectedSnack.snack.price}</span>: <Input 
                        disabled={!studentSelected}
                        onChange={(e) => setSnackName(e.target.value)} placeholder="Search for a snack" />}
                        </DropdownToggle>
                        <DropdownMenu children>
                            { snacks.map((snack) => (
                            <div key={snack.id}>
                                <DropdownItem onClick={ (e) =>{
                                    e.preventDefault()
                                    selectSnack(snack)}
                                    }>{snack.name} - ${snack.price}</DropdownItem>
                             </div>
                            ))}
                            <div key={0}>
                                <DropdownItem >Search to find more...</DropdownItem>
                             </div>
                        </DropdownMenu>
                    </Dropdown>
                    </Col>
                    <Col lg='6'>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                        >
                    Select a Drink    
                    </label>
                    <br></br>
                    <Dropdown toggle={() => {
                            setDrinkSelected(!drinkSelected)
                    }} isOpen={drinkSelected}>
                        <DropdownToggle disabled={!studentSelected}>
                        { selectedDrink.drink ? <span>{selectedDrink.drink.name} - ${selectedDrink.drink.price}</span>: <Input 
                        disabled={!studentSelected} 
                        onChange={(e) => setDrinkName(e.target.value)} placeholder="Search for a drink" />}
                        </DropdownToggle>
                        <DropdownMenu>
                            { drinks.map((drink) => (
                            <div key={drink.id}>
                                <DropdownItem onClick={() => selectDrink(drink)}>{drink.name} - ${drink.price}</DropdownItem>
                             </div>
                            ))}
                             <div key={0}>
                                <DropdownItem >Search to find more...</DropdownItem>
                             </div>
                        </DropdownMenu>
                    </Dropdown>
                    </Col>
                </Row>
                <br></br>
                <Row>
                    <Col  md='10' lg='6' >
                    <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="input-instructions"
                        >
                        Lunch Instructions
                        </label>
                        <br></br>
             
                    <Input 
                                value={instructions}
                                onChange={(e) => setInstruction(e.target.value)}
                               type='textarea'
                               rows='5'
                               cols='40'
                            ></Input>
                    </FormGroup>
                    </Col>
                    <Col md='10' lg='6' >
                    <FormGroup>
                        <label
                        className="form-control-label"
                        htmlFor="input-meal-message"
                        >
                        Kid's Message
                        </label>
                        <br></br>
                        <Input 
                               value={kidMessage}
                               onChange={(e) => setKidMessage(e.target.value)}
                               type='textarea'
                               rows='5'
                               cols='40'
                            ></Input>
                    </FormGroup>
                    </Col>
                </Row>
                <Row className='pl-3'>
                    <Button color='primary' onClick={() => addToCart()}> Add To Cart</Button>
                </Row>
            </Form>
            </CardBody>
        </Card>
        </Col>
        <Col lg='4'>
            <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <Row className="align-items-center justify-content-between">
                    <Col xs="4">
                    <h3 className="mb-0">Cart</h3>
                    </Col>
                    <Col className="text-right" xs="6">
                    {/* <h4>Category: {product.category.name}</h4> */}
                    <Label check>
                    <Input type="checkbox" onChange={(e) => setIsLate(!isLate)} defaultChecked={isLate}></Input>
                        Is customer late ?
                    </Label>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Row className='justify-content-between'>
                <Col lg='4'>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                        >
                        Name
                    </label>
                    <br></br>
                    <span>{customer.first_name} {customer.last_name}</span>
                </Col>
                <hr />
                <Col lg='6'>
                    <FormGroup>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                    >
                        Payment Method
                    </label>
                    <Dropdown toggle={() => setCardsOpen(!cardsOpen)} isOpen={cardsOpen}>
                        <DropdownToggle>
                            { selectedCard ? <span> {selectedCard.brand} - {selectedCard.last4}</span>  : <span>Select a payment Method</span>}
                        </DropdownToggle>
                        <DropdownMenu>
                        {
                            customer.wallet.paymentMethods.map((card) => (
                                <div key={card}>
                                    <DropdownItem onClick={() => selectPM(card)}>{card.brand} - {card.last4}</DropdownItem>
                                </div>
                            ))
                        }
                        </DropdownMenu>
                    </Dropdown>
                    </FormGroup>
                </Col>
                </Row>
            {customerRole === 'teacher' ? <span className='' style={{'color': 'green'}}>
             30% Teacher Discount Automatically Applied
                </span> : null}
            <hr className='py-2 my-2'></hr>
            <div>

            {cart && cart.cartMeals.map((cartMeal) => {
                return (
                    <Card className='shadow my-2'>
                    <CardBody className='p-4'>
                        <Row className='justify-content-between'>
                            <Col>
                            {cartMeal.receiver_name} 
                            </Col>
                    
                            <Row className='px-4 align-items-center justify-content-between'>
                            ${cartMeal.sub_total}
                            <Col>
                            </Col>
                            <Button size="sm" color='danger' onClick={(e) => {
                                setModalOpen(true)
                                setMealID(cartMeal.id)
                            }}><i className='ni ni-fat-remove' /></Button>
                            </Row>
                        </Row>
                        <hr className='p-0 my-2'/>
                        <Row className='px-3 justify-content-between'>
                        {cartMeal.product.name} 
                        <span>
                        {Intl.DateTimeFormat('en-US').format(DateTime.fromSQL(cartMeal.delivery_date))}
                        </span>
                        </Row>
                        <Row className='px-3'>
                        {cartMeal.drink.name} - {cartMeal.snack.name} 
                        </Row>
                    </CardBody>
                </Card>
                )
            })}
            </div>
            </CardBody>
            <CardFooter>
                <Row>
                    <Col>
                        <p>SubTotal: ${subTotal}</p>
                        <p>Tax : ${cart.current_tax}</p>
                        <p>Late Fee: ${lateFee}</p>
                        <p>Total: ${(parseFloat(total) + parseFloat(lateFee)).toFixed(2)}</p>
                    </Col>
                    <Col>
                        <p>Credit Applied: ${creditApplied}</p>
                        <p>Charge amount: ${(parseFloat(total) + parseFloat(lateFee) - parseFloat(creditApplied)).toFixed(2)}</p>
                       
                    </Col>
                </Row>
                <Row className='justify-content-between align-items-center pr-4 pl-4'>
                    <Button color='primary' onClick={() => createOrder()}> Submit </Button>
                    {emptyCart ?? <span>{emptyCart}</span>}
                </Row>
            </CardFooter>
            </Card>
        </Col>
        </Row>
        <Modal
        isOpen={deleteCartMealModal} 
        className="modal-dialog-centered"
        size="sm"
        >

        <div className="modal-body p-0">
            <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                    <p>Are you sure you want to delete this cart meal?</p>
                    <Row className='justify-content-center align-items-center'>
                        <Button onClick={() => setModalOpen(false)}>
                            No
                        </Button>
                        <Button color='danger' onClick={() => deleteCartMeal()}>
                            Yes
                        </Button>
                    </Row>
                </CardBody>
            </Card>
        </div>
        </Modal>
    </Container>
    </>
    )
}
export default CreateOrder