import UsersTableHeader from "components/Headers/UsersTableHeader.js"
import {
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    Col,
    Button,
  } from "reactstrap"
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import ResultsPerPage from "components/ResultsPerPage.js";
import Skeleton from 'react-loading-skeleton'
import axios from 'axios'
import { Link } from 'react-router-dom'

const SchoolStudentsTable = () => {
    let { id } = useParams()
    let [ students, setStudents ] = useState([])
    let [ isLoading, setLoad ] = useState(true)
    const BASE_URL = process.env.REACT_APP_BASE_URL

    const [schoolYear, setSchoolYear] = useState("");
    const [schoolYearId, setSchoolYearId] = useState("");
    const [studentsFirst, setName] = useState("");
    const [studentsLast, setLastname] = useState("");
    const [studentID, setID] = useState("");
    const [address, setAddress] = useState("");
    const [isAllowedToOrder, setIsAllowed] = useState("");
    let [campus, setCampus] = useState([])

    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(25);
    const [numberPages, setNumberPages] = useState(null);
    const [schoolYears, setSchoolYears] = useState([]);
    const { register, handleSubmit, reset, formState, control } = useForm();




    const getData = async () => {
      if (localStorage.getItem('selectedSchoolYear') != null && schoolYear === "") {
            setSchoolYear(JSON.parse(localStorage.getItem('selectedSchoolYear')))
            setSchoolYearId(JSON.parse(localStorage.getItem('selectedSchoolYear')).id)
      }
        let params = + "page=" + page + "&limit=" + pageLimit + "&school_year_id=" + schoolYearId + "&first_name=" + studentsFirst  + '&last_name=' + studentsLast  +  "&id=" + studentID + "&campus_id=" + campus + "&is_allowed_to_order=" + isAllowedToOrder
        let data = await axios.get(BASE_URL  + '/schools/' + id + '/students/?' + params)
        if (data.status === 200) {
            setStudents(data.data.data)
            setNumberPages(data.data.meta.last_page)
            setLoad(false)
        }
    }

    useEffect(() => {
        getData()
    }, [page, pageLimit, schoolYear, studentsFirst, studentsLast, studentID, address, isAllowedToOrder, campus])
    return (
        <>
        <UsersTableHeader />
        <Container className="mt--7" fluid>
        <Card>
            <CardHeader>
                <h1> School Students </h1>
            </CardHeader>
            <Form>
                    <div className="pl-lg-4">
                        <Row>
                            {/* <Col > */}
                                <FormGroup row className="col">
                                    {/* <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        School Year
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="schoolYear"
                                        type="select"
                                        value={schoolYear}
                                        onChange = {e => {
                                          setSchoolYear(e.target.value)
                                          setPage(1)
                                        }}
                                        >
                                          <option disabled value="">
                                              Select a School Term
                                          </option>
                                        {schoolYears.map(year => (
                                          <option key={year.id} value={year.id}>
                                              {year.name}
                                          </option>
                                        ))}

                                        </Input>
                                      </InputGroup>
                                    </Col>   */}
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                       First Name
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="studentName"
                                        type="text"
                                        placeholder="Enter First Name"
                                        value={studentsFirst}

                                        onChange = {e => {
                                          setName(e.target.value)
                                        }}
                                        />
                                      </InputGroup>
                                    </Col>
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Last Name
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="studentLastName"
                                        type="text"
                                        placeholder="Enter Last Name"
                                        value={studentsLast}

                                        onChange = {e => {
                                          setLastname(e.target.value)
                                        }}
                                        />
                                      </InputGroup>
                                    </Col> 
                                    {/* <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        ID
                                      </label>
                                      <InputGroup>
                                        <Input
                                            id="exampleSelect"
                                            name="id"
                                            type="number"
                                            placeholder="Enter Student ID"
                                            value={studentID}

                                            onChange = {e => {
                                             setID(e.target.value)
                                            }}
                                        />
                                    
                                      </InputGroup>
                                    </Col>   */}
                                    <Col>
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Campus
                                      </label>
                                      <InputGroup>
                                        <Input
                                          id="category-dropdown"
                                          name="campus"
                                          type="select"
                                          value={campus}
                                          onChange={(e) => {
                                            e.preventDefault();
                                            setCampus(e.target.value);
                                            setPage(1);
                                          }}
                                        >
                                          <option value="">Select a Campus</option>
                                          {[...new Set(students.map((student) => student.campus.id))].map((id) => {
                                            const campus = students.find((student) => student.campus.id === id).campus;
                                            console.log('these are the custom campus')
                                            return campus;
                                          }).filter((campus, index, arr) => arr.findIndex(c => c.id === campus.id) === index).map((campus) => (
                                            <option key={campus.id} value={campus.id}>
                                              {campus.name}
                                            </option>
                                          ))}
                                        </Input>
                                      </InputGroup>
                                    </Col>
                                    {/* <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Is Allowed To Order
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="weeklyOrders"
                                        type="select"
                                        value={isAllowedToOrder}
                                        onChange = {e => {
                                          setIsAllowed(e.target.value)
                                          setPage(1)
                                        }}
                                        >
                                          <option disabled value="">
                                              All
                                          </option>
                                          <option value="1">
                                              Yes
                                          </option>
                                          <option value="0">
                                              No
                                          </option>
                                        </Input>
                                      </InputGroup>
                                    </Col>         */}
                                </FormGroup>
                            {/* </Col>   */}
                        </Row>
                    </div>
                  </Form>
                  <Row>
                    <Col>
                    <ResultsPerPage pageLimit={pageLimit} setPageLimit={setPageLimit} page={page} setPage={setPage}/>
                    </Col>
                  </Row>
            <CardBody>
            {
            isLoading ?
            <>
             <Skeleton count={5}/>
            </>
            :   
             <Table>
                <thead className='thead-light'>
                    <tr className='text-center'>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Campus</th>
                    <th>Grade</th>
                    <th>Classroom</th>
                    <th>Parent</th>
                    </tr>
                </thead>
                <tbody>
                    {students.map((student) => (
                         <tr className='text-center'>
                            <td>
                                {student.id}
                            </td>
                            <td>{student.first_name + " " +  student.last_name}</td>
                            <td>{student.campus.name}</td>
                            <td>{student.grade.name}</td>
                            <td>{student.classroom.class_number}</td>
                            <td> <Link  to={`/admin/customer/${student.parent.id}`}>View</Link></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
               }
            </CardBody>
            <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem className="disabled">
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>

                        
                        {[...Array(numberPages)].map((x, i) =>
                            <PaginationItem key={i} className={`${page === (i+1) ? "active" : ""}`}>
                            {/* <PaginationItem className="active"> */}
                            <PaginationLink
                              onClick={(e) => { 
                                  setPage(e.target.innerText)
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        )}

                
                        <PaginationItem>
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                   </CardFooter>
        </Card>
        </Container>
        </>
    ) 
}

export default SchoolStudentsTable