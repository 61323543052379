
// import Index from "views/Index.js";
import UserProfile from "views/detail_pages/UserProfile.js";
import CustomerProfile from "views/detail_pages/CustomerProfile.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Logout from "views/examples/Logout.js";
import SchoolProfile from 'views/detail_pages/SchoolProfile'
import SchoolTeachersTable from "views/profile_tables/schools/SchoolTeachers";
import SchoolStudentsTable from "views/profile_tables/schools/SchoolStudents"
import SchoolGradesTable from "views/profile_tables/schools/SchoolGrades";
import SchoolClassroomTable from "views/profile_tables/schools/SchoolClassrooms";
import SchoolCampusesTable from "views/profile_tables/schools/SchoolCampuses";
import SchoolsTable from "views/profile_tables/SchoolsTable.js";
import AllergiesTable from "views/profile_tables/AllergiesTable.js";
import CustomersTable from "views/profile_tables/CustomersTable.js";
import AdminTable from "views/profile_tables/AdminTable.js";
// import UsersTable from "views/profile_tables/UsersTable.js";
import OrdersTable from "views/profile_tables/OrdersTable.js";
import ProductsTable from "views/profile_tables/ProductsTable";
import StudentsTable from "views/profile_tables/StudentsTable";
import MenusTable from "views/profile_tables/MenusTable";
import MenuProfile from "views/detail_pages/MenuProfile";
import ProductProfile from "views/detail_pages/ProductProfile";
import CreateProductForm from "views/create_forms/CreateProduct";
import StudentProfile from "views/detail_pages/StudentProfile";
import OrderProfile from 'views/detail_pages/OrderProfile';
import MealProfile from "views/detail_pages/MealProfile";
import MealsTable from "views/profile_tables/MealsTable";
import TagsCategoriesTable from "views/profile_tables/TagsCategoriesTable";
import SidesTable from 'views/profile_tables/SidesTable'
import CreateSchool from "views/create_forms/CreateSchool";
import ImportsExportsPage from "views/imports_exports/ImportsExports";
import Settings from "views/detail_pages/Settings";
import CreateOrder from "views/create_forms/CreateOrder";
import SnackProfile from "views/detail_pages/SnackProfile";
import DrinkProfile from "views/detail_pages/DrinkProfile";
import Index from 'views/Index'
var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
  },
  {
    path: "/files",
    name: "Imports & Exports",
    icon: "ni ni-archive-2 text-green",
    component: ImportsExportsPage,
    layout: "/admin",
    hide: true,
  },
  {
    path: "/schools/:id/teachers",
    name: "School's Teacher",
    icon: "ni  text-primary",
    component: SchoolTeachersTable,
    layout: "/admin",
    hide: true
  },
  {
    path: "/schools/:id/grades",
    name: "School's Teacher",
    icon: "ni ni-tv-2 text-primary",
    component: SchoolGradesTable,
    layout: "/admin",
    hide: true
  },
  {
    path: "/schools/:id/classrooms",
    name: "School's Teacher",
    icon: "ni ni-tv-2 text-primary",
    component: SchoolClassroomTable,
    layout: "/admin",
    hide: true
  },
  {
    path: "/schools/:id/campuses",
    name: "School's Teacher",
    icon: "ni ni-tv-2 text-primary",
    component: SchoolCampusesTable,
    layout: "/admin",
    hide: true
  },
  {
    path: "/schools/:id/students",
    name: "School's Teacher",
    icon: "ni ni-tv-2 text-primary",
    component: SchoolStudentsTable,
    layout: "/admin",
    hide: true
  },
  {
    path: "/schools/:id",
    name: "School Profile",
    icon: "ni ni-tv-2 text-primary",
    component: SchoolProfile,
    layout: "/admin",
    hide: true

  },
  {
    path: "/schools/:id/teachers",
    name: "School's Teacher",
    icon: "ni ni-tv-2 text-primary",
    component: SchoolTeachersTable,
    layout: "/admin",
    hide: true
  },
  {
    path: "/allergies",
    name: "Allergies",
    icon: "ni ni-ambulance text-red",
    component: AllergiesTable,
    layout: "/admin",
  },
  {
    path: "/tagsncats",
    name: "Tags & Categories",
    icon: "ni ni-books text-blue",
    component: TagsCategoriesTable,
    layout: "/admin",
  },
  // {
  //   path: "/users",
  //   name: "     Users",
  //   icon: "ni ni-single-02 text-red",
  //   component: UsersTable,
  //   layout: "/admin",
  // },
  {
    path: "/admins",
    name: "Admins",
    icon: "ni ni-single-02 text-red",
    component: AdminTable,
    layout: "/admin",
  },
  {
    path: "/customers",
    name: "Customers",
    icon: "ni ni-single-02 text-yellow",
    component: CustomersTable,
    layout: "/admin",
  },
  {
    path: "/students",
    name: "Students",
    icon: "ni ni-badge text-blue",
    component: StudentsTable,
    layout: "/admin",
  },
  {
    path: "/student/:id",
    name: "Students",
    component: StudentProfile,
    layout: "/admin",
    hide: true
  },
  // {
  //   path: "/teachers",
  //   name: "Teachers",
  //   icon: "ni ni-books text-teal",
  //   component: TeachersTable,
  //   layout: "/admin",
  // },
  {
    path: "/promos",
    name: "Promotions",
    icon: "ni ni-archive-2 text-pink",
    component: CustomersTable,
    layout: "/admin",
    hide: true
  },
  // {
  //   path: "/cards",
  //   name: "KD Cards",
  //   icon: "ni ni-credit-card text-green",
  //   component: CustomersTable,
  //   layout: "/admin",
  // },
  {
    path: "/products",
    name: "Products",
    icon: "ni ni-shop text-purple",
    component: ProductsTable,
    layout: "/admin",
  },
  {
    path: "/sides",
    name: "Snacks & Drinks",
    icon: "ni ni-cart text-green",
    component: SidesTable,
    layout: "/admin",
  },
  {
    path: "/product/create",
    name: "Create Product",
    layout: "/admin",
    component: CreateProductForm,
    hide: true,
  },
  {
    path: "/product/:id",
    name: "Product Detail",
    layout: "/admin",
    component: ProductProfile,
    hide: true,
  },
  {
    path: "/snack/:id",
    name: "Snack Detail",
    layout: "/admin",
    component: SnackProfile,
    hide: true,
  },
  {
    path: "/drink/:id",
    name: "Drink Detail",
    layout: "/admin",
    component: DrinkProfile,
    hide: true,
  },
  {
    path: '/school/create',
    name: 'Create School',
    component: CreateSchool,
    layout: "/admin",
    hide: true
  },
  {
    path: "/schools",
    name: "Schools",
    icon: "ni ni-building text-indigo",
    component: SchoolsTable,
    layout: "/admin",
  },
  {
    path: "/orders/:id",
    name: "Orders",
    icon: "ni ni-basket text-red",
    component: OrderProfile,
    layout: "/admin",
    hide: true
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "ni ni-basket text-red",
    component: OrdersTable,
    layout: "/admin",
  },
  {
    path: "/meals",
    name: "Reports",
    icon: "ni ni-bag-17 text-red",
    component: MealsTable,
    layout: "/admin",
    hide: false
  },
  {
    path: "/meal/:id",
    name: "Meal profile",
    icon: "ni ni-bag-17 text-red",
    component: MealProfile,
    layout: "/admin",
    hide: true
  },
  // {
  //   path: "/menus",
  //   name: "Menus",
  //   icon: "ni ni-calendar-grid-58 text-teal",
  //   component: MenusTable,
  //   layout: "/admin",
  //   hide: false
  // },
  {
    path: "/menu/:id",
    name: "Menus",
    component: MenuProfile,
    layout: "/admin",
    hide: true
  },
  // {
  //   path: "/cats",
  //   name: "Categories",
  //   icon: "ni ni-active-40 text-yellow",
  //   component: CustomerProfile,
  //   layout: "/admin",
  //   hide: false
  // },
  // {
  //   path: "/tags",
  //   name: "Tags",
  //   icon: "ni ni-atom text-green",
  //   component: CustomerProfile,
  //   layout: "/admin",
  //   hide: false
  // },
  {
    path: '/customer/:id/create/order',
    name: 'Create Order', 
    icon: 'ni ni-single-02 text-yellow',
    layout: '/admin',
    hide: true,
    component: CreateOrder,
  },
  {
    path: "/customer/:id",
    name: "Customer Profile",
    icon: "ni ni-single-02 text-yellow",
    component: CustomerProfile,
    layout: "/admin",
    hide: true
  },
  {
    path: "/user/:id",
    name: "User Profile - NEW",
    icon: "ni ni-single-02 text-yellow",
    component: UserProfile,
    layout: "/admin",
    hide: true
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
    hide: true,
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: Register,
  //   layout: "/auth",
  //   hide: true,
  // },
  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-curved-next",
    component: Logout,
    layout: "/auth",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: "ni ni-settings-gear-65",
    component: Settings,
    layout: "/admin",
  },
];
export default routes;
