import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect }  from 'react';
import { Link } from "react-router-dom";
import ResultsPerPage from "components/ResultsPerPage.js";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import axios from 'axios';
import Skeleton from 'react-loading-skeleton'

import { 
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    UncontrolledTooltip,
    Form,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
} from 'reactstrap';

const MenuProfile = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL
    let [menu, setMenu] =  useState([])
    let [isLoaded, setLoad] = useState(false)
    let [error, setError] = useState([])
    const {id} = useParams();

    let [allergy, setAllergy] = useState('000');
    let [allergies, setAllergies] = useState([]);
    let [categories, setCategories] = useState([]);
    let [sortId, setSortId] = useState(null);
    let [sortName, setSortName] = useState(null);
    let [name, setName] = useState("");
    let [category, setCategory] = useState([]);
    let [page, setPage] = useState(1);
    let [pageLimit, setPageLimit] = useState(5)
    let [numberPages, setNumberPages] = useState(null)

    const sortProductsByAllergy = (productsArray) => {
        if (allergy === '000') {
            return productsArray
        }
        let mapedProds = productsArray.map((product) => {
           let algs =  product.allergies.filter((prodAllergy) => {
                if ( prodAllergy.id === parseInt(allergy) ) {
                    return prodAllergy
                }
            })
            product.allergies = algs
            return product
        })
       let result = mapedProds.filter((prod) => {
           console.log(prod.allergies.length)
            if (prod.allergies.length > 0){
                return prod 
            }
        })
        return result
    }
    const getCateogryData = async () => {
        try {
            let res = await axios.get(BASE_URL + '/categories')
            setCategories(res.data)
        } catch (error) {
            setCategories([])
        }
    }

    const getAllergies = async () => {
        try {
            let res = await axios.get(BASE_URL +  '/allergy/mobile')
            setAllergies(res.data)
        } catch(error) {
            setAllergies([])
        }
    }
    const getData = async () => {
        try {
            let query = '?category=' +  category  + '&allergy=' +  allergy + '&name=' + name + '&limit=' + pageLimit  + '&page=' + page
            let data = await axios.get(BASE_URL + '/menus/' + id  + query)
            let sortedProds = sortProductsByAllergy(data.data.products)
            data.data.products = sortedProds
            setMenu(data.data)
            setLoad(true)
        } catch(error) {
            setError(error)
            setLoad(true)
        }
    }

    const deleteProductFromMenu = async (productID) =>  {
        try {
            await axios.put(BASE_URL + '/menus/' + id + '/relate/product/', {productID: productID})
            getData()
        } catch(error) {
            setError(error)
            setLoad(true)
        }
    }
    
    useEffect(() =>{
        getData()
        getCateogryData()
        getAllergies()
    }, [name, category, allergy, allergy])
    
    return (
    <>
     <Container  className="mt--7" fluid>
     <UserHeader></UserHeader>

     <Card className='card-profile shadow'>
        <CardHeader>
            <Row className='justify-content-around align-items-center'>
            <h1>{menu.name} Menu</h1>
            <h3>Total Items: {menu.products ? menu.products.length : 0}</h3>
            <Button>Edit</Button>
            </Row>
        </CardHeader>
    </Card>
    { 
               isLoaded ? 
        <Card className='py-4 my-4 card-body shadow'>
            <h2>Products</h2>
            <FormGroup row className="col">
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Name
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="name"
                                        type="text"
                                        placeholder="Enter Name"
                                        value={name}
                                        onChange = {e => {
                                          setName(e.target.value)
                               
                                        }}
                                        />
                                        <InputGroupAddon addonType="append">
                                          <Button color="primary" id="button-addon2" outline type="button" onClick={e => {
                                            setName("");
                                          }}>
                                            <i className="ni ni-fat-remove" />

                                          </Button>
                                        </InputGroupAddon>
                                      </InputGroup>
                                    </Col> 
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Category
                                      </label>
                                      <InputGroup>
                                        <Input
                                            id="category-dropdown"
                                            name="category"
                                            type="select"
                                            value={category.name}
                                            onChange = {e => {
                                            e.preventDefault()
                                            setCategory(e.target.value)
                                            setPage(1)
                                            }}
                                        >
                                          <option value="000">
                                              Select a Category
                                          </option>
                                        {categories.map((cat) => <option key={cat.id} value={cat.id}>{cat.name}</option>)}
                                        </Input>
                                        <InputGroupAddon addonType="append">
                                          <Button color="primary" id="button-addon2" outline type="button" onClick={e => {
                                            setCategory("")
                                          }}>
                                            <i className="ni ni-fat-remove" />
                                          </Button>
                                        </InputGroupAddon>
                                      </InputGroup>
                                    </Col>  
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Allergies
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="allergy"
                                        type="select"
                                        placeholder={allergy.name}
                                        value={allergy}
                                        onChange = {e => {
                                            e.preventDefault()
                                            setAllergy(e.target.value)
                                        }}
                                        >
                                        <option value="000">
                                            Select an Allergy
                                        </option>
                                        {allergies.map((alg) => <option key={alg.id} value={alg.id}>{alg.name}</option>)}
                                        </Input>
                                        <InputGroupAddon addonType="append">
                                          <Button color="primary" id="button-addon2" outline type="button" onClick={e => {
                                            setAllergy('000');
                                          }}>
                                            <i className="ni ni-fat-remove" />
                                          </Button>
                                        </InputGroupAddon>
                                      </InputGroup>
                                    </Col> 
                                </FormGroup>
            <br></br>
            <Table className=''>
                <thead className='text-center thead-light'>
                    <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th>Price</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody className='text-center'>
                    {
                        menu.products.map((prod) => {
                        return <tr key={prod.id}>
                                <td>
                                {prod.name}
                                </td>
                                <td>
                                    {prod.category.name}
                                </td>
                                <td>{prod.price}</td>
                                <td >
                          <UncontrolledDropdown>
                                <DropdownToggle
                                className="btn-icon-only text-light"
                                role="button"
                                size="sm"
                                color="success"
                                onClick={(e) => e.preventDefault()}
                                >
                                <i className="fas fa-ellipsis-v" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                    // href="#pablo"
                                    // onClick={(e) => e.preventDefault()}
                                    tag={Link}
                                    to={"/admin/menu/" + menu.id}
                                >
                                    View
                                </DropdownItem>
                                <DropdownItem
                                    // href="#pablo"
                                    onClick={async (e) => {
                                    e.preventDefault()
                                    await deleteProductFromMenu(prod.id)
                                    }}
                                >
                                    Delete
                                </DropdownItem>
                                </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                            </tr>      
                            })
                    }
                </tbody>
            </Table>
                </Card> : 
                <Skeleton count={5} />
            }
     </Container>
    </>
    )
}

export default MenuProfile