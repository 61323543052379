/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
    // Badge,
    Card,
    CardHeader,
    CardFooter,
    Media,
    // // Pagination,
    // PaginationItem,
    // PaginationLink,
    Table,
    Container,
    Row,
    Form,
    Col,
    FormGroup,
    Input,
    InputGroup,
    Button,
    Modal,
  } from "reactstrap";
  // core components
  import UsersTableHeader from "components/Headers/UsersTableHeader.js";
  import React, { useState, useEffect }  from 'react';
  import ResultsPerPage from "components/ResultsPerPage.js";
  import { useForm, Controller } from "react-hook-form";
  import { ReactSession } from 'react-client-session';
  import axios from 'axios';
  import { Pagination } from "@mui/material";



  const AdminTable = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const { register, handleSubmit, reset, formState, control } = useForm();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [users, setUsers] = useState([]);

    // Filtering
    const [name, setName] = useState("");
    const [role, setRole] = useState("admin");
    const [email, setEmail] = useState("");

    // Sorting
    const [sortId, setSortId] = useState(null);
    const [sortName, setSortName] = useState(null);
    const [sortCreatedAt, setSortCreatedAt] = useState(null);

    // Pagination
    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(25);
    const [numberPages, setNumberPages] = useState(null);

    // Admin Creation/Deletion
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [createAdminError, setCreateAdminError] = useState(null);
    const [deleteAdminError, setDeleteAdminError] = useState(null);


    const token = ReactSession.get("token");
    // console.log('AllergiesTable token: ' + token)

    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }


    const getData = async () => {
        getAdmins()
        setIsLoaded(true)
    }


    const getAdmins = async () => {
        
        let queryString = "/?page=" + page + "&limit=" + pageLimit + "&sortName=" + sortName + "&sortId=" + sortId + "&sortCreatedAt=" + sortCreatedAt + "&name=" + name + "&role=" + role + "&email=" + email
        try {
            let res = await axios.get(BASE_URL + '/users' + queryString, config)
            setIsLoaded(true);
            setUsers(res.data.data);
            setNumberPages(res.data.meta.last_page)
            
        } catch (error) {
            console.error('ERROR')
            console.error(error.message)
            setIsLoaded(true);
            setError(error.message);
        }
    }

   


    const createAdmin = async (data, e ) => {
        let userData = {
        name: data.admin_name,
        password: data.admin_password,
        email: data.admin_email,
        role: 'admin',
        }

        try{
            let res = await axios.post(BASE_URL + '/users', userData, config)
            setUserModalOpen(false)
            getData()
            reset()
        }catch(error){
            console.error(error)
            setError('createAdmin: ' + error.message)
        }

    }
    
    // Dangerous functionality, TODO: IMpletement some suspending later
    // const deleteAdmin = async (data, e ) => {
    //     console.log('DELETING')
    //     console.log(data)

    //     try{
    //         let res = await axios.delete(BASE_URL + '/users/' + data, config)
    //         getData()
    //     }catch(error){
    //         console.error(error)
    //         setDeleteAdminError('deleteAdmin: ' + error.message)
    //     }
        

    // }




     useEffect(() => {
       getData()
      }, [page, pageLimit,sortName,sortId, name, role, email])


      const handlePageChange = (event, value) => {
        setPage(value);
      };

    if (error) {
        return <div>Error: {error.responseText}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

      return (
        <>
          <UsersTableHeader />
          {/* Page content */}
          <Container className="mt--7" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className='justify-content-between'>
                    <h3 className="mb-0 ml-3">Admins</h3>
                    <Row>
                      <ResultsPerPage pageLimit={pageLimit} setPageLimit={setPageLimit} page={page} setPage={setPage} rowAmount={users.length}/>
                      <Button sm='true' onClick={(e) => setUserModalOpen(true)} color='primary'>Create</Button>
                      <div className="mx-2"></div>
                    </Row>
                    </Row>
                    <Row className='justify-content-between'>
                       <div className="text-warning ml-3">{(deleteAdminError) ? <p>{deleteAdminError}</p> : ''}</div>
                    </Row>
                  </CardHeader>
                  <Form>
                    <div className="pl-lg-4">
                        <Row>
                            {/* <Col > */}
                                <FormGroup row className="col">
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Name
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="name"
                                        type="text"
                                        placeholder="Enter Name"
                                        value={name}

                                        onChange = {e => {
                                          setName(e.target.value)
                               
                                        }}
                                        />
                                     
                                      </InputGroup>
                                    </Col> 
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Role
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="role"
                                        type="select"
                                        value={role}
                                        disabled
                                        onChange = {e => {
                                          setRole(e.target.value)
                                          setPage(1)
                                        }}
                                        >
                                          <option disabled value="">
                                              Select a Role
                                          </option>
                                        
                                          <option key='admin' value='admin'>
                                              Administrator
                                          </option>
                                          <option key='customer' value='customer'>
                                              Customer
                                          </option>
                                        
                                        </Input>
                                
                                      </InputGroup>
                                    </Col>  
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Email
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="email"
                                        type="text"
                                        placeholder="Enter Email"
                                        value={email}

                                        onChange = {e => {
                                          setEmail(e.target.value)
                               
                                        }}
                                        />
                                      </InputGroup>
                                    </Col> 
                                       
                                </FormGroup>
                            {/* </Col>   */}
                        </Row>
                    </div>
                  </Form>
                  <Row>
                    <Col></Col>
                  </Row>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        {/* <th scope="col">Project</th>
                        <th scope="col">Budget</th>
                        <th scope="col">Status</th>
                        <th scope="col">Users</th>
                        <th scope="col">Completion</th>
                        <th scope="col" /> */}
                        <th scope="col" onClick={(e) => {
                            e.preventDefault()
                            setSortName(null)
                            if(sortId === 'asc' ) {setSortId('desc');}
                            else if(sortId === 'desc' || sortId === null) {setSortId('asc');}
                            setPage(1)
                          }}>ID</th>
                        <th scope="col" onClick={(e) => {
                            e.preventDefault()
                            setSortId(null)
                            if(sortName === 'asc' ) {setSortName('desc');}
                            else if(sortName === 'desc' || sortName === null) {setSortName('asc');}
                            setPage(1)
                            
                          }}>Name</th>
                        <th scope="col">Role</th>
                        <th scope="col">Email</th>
                        <th scope="col" 
                        // onClick={(e) => {
                        //   console.log("CLICK")
                        //     e.preventDefault()
                        //     setSortName(null)
                        //     setSortId(null)
                        //     if(sortCreatedAt === 'asc' ) {setSortCreatedAt('desc');}
                        //     else if(sortCreatedAt === 'desc' || sortCreatedAt === null) {setSortCreatedAt('asc');}
                        //     setPage(1)
                        //   }}
                          >Date Created</th>
                        <th scope="col">Last Updated</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                    {users.map(user => (
                    <tr key={user.id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            {/* <a
                              className="avatar rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                src={
                                  require("../../assets/img/theme/bootstrap.jpg")
                                    .default
                                }
                              />
                            </a> */}
                            <Media>
                              <span className="mb-0 text-sm">
                                {user.id}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>{user.name}</td>
                        <td>{user.role}</td>
                        <td>{user.email}</td>
                        <td>{user.created_at}</td>
                        <td>{user.updated_at}</td>
                        {/* <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                // href="#pablo"
                                // onClick={(e) => e.preventDefault()}
                                tag={Link}
                                to={"/admin/user/" + user.id}
                              >
                                View
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => deleteAdmin(user.id)}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td> */}
                      </tr>
                      ))}
                      
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                    <Pagination count={numberPages} color="primary" onChange={handlePageChange}/>
                      {/* <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem>
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              let prevpage = +page - +1;
                              if(prevpage >= 1) setPage(prevpage)
                            }}
                            // tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>

                        
                        {[...Array(numberPages)].map((x, i) =>
                            <PaginationItem key={i} className={`${page==(i+1) ? "active" : ""}`}>
                            <PaginationItem className="active">
                            <PaginationLink
                              onClick={(e) => { 
                                  setPage(e.target.innerText)
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        )}

                
                        <PaginationItem>
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              let nextpage = +page + +1;
                              if(nextpage <= numberPages) setPage(nextpage)
                            }}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination> */}
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
            
          </Container>
          <Modal isOpen={userModalOpen}>
            <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                        Create Admin
                        </h5>
                        <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={(e) => setUserModalOpen(false)}
                        >
                        <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="text-warning ml-4">{(createAdminError) ? <p>{createAdminError}</p> : ''}</div>

                    <Form onSubmit={handleSubmit(createAdmin)}>
                    <div className="modal-body">
                        <Row>
                            <Col lg="12">
                                <label
                                className="form-control-label"
                                htmlFor="admin_name"
                                >
                                Name
                                </label>
                                <input
                                className="form-control"
                                id="admin_name"
                                placeholder="John Doe"
                                type="text"
                                {...register('admin_name')}
                                required
                                />
                            </Col>
                            
                        </Row>
                        <br />
                        <Row>
                            <Col lg="12">
                                <label
                                className="form-control-label"
                                htmlFor="admin_email"
                                >
                                Email
                                </label>
                                <input
                                className="form-control"
                                id="admin_email"
                                placeholder="mail@mail.com"
                                type="phone"
                                {...register('admin_email')}
                                required
                                />
                            </Col>
                        
                        </Row>
                        <br />
                        <Row>
                            <Col lg="6">
                                <label
                                className="form-control-label"
                                htmlFor="admin_password"
                                >
                                Password 
                                </label>
                                <input 
                                className="form-control"
                                placeholder="Password"
                                type="password"
                                id="admin_password"
                                {...register('admin_password')}
                                required
                                />
                            </Col>
                        </Row>
                        <br />
                        
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="secondary"
                            data-dismiss="modal"
                            type="button"
                        >
                        Close
                        </Button>
                        <Button
                            color="primary"
                            data-dismiss="modal"
                            type="submit"
                        >
                        Save
                        </Button>
                    </div>
                </Form>
            </Modal>
        </>
      );
    }
  };
  
  export default AdminTable;
  