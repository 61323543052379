/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
  import { ReactSession } from 'react-client-session';
  import { Redirect } from "react-router-dom";
  
  const Logout = () => {
  
    // let  [registered, setRegistered] = useState(false)
    
    ReactSession.remove("token");


    return <Redirect to="/auth/login" />
  };
  
  export default Logout;
  