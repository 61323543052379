import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect }  from 'react';
import { Link } from "react-router-dom";
import ResultsPerPage from "components/ResultsPerPage.js";
import { useForm, Controller } from "react-hook-form";
import axios from 'axios';
import { 
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    // Pagination,
    // PaginationItem,
    // PaginationLink,
    Table,
    Container,
    Row,
    Form,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
    Modal,
} from 'reactstrap';
import { ReactSession } from 'react-client-session';
import { Pagination } from "@mui/material";

const ProductsTable = () => {
    const { handleSubmit, register } = useForm();
    const BASE_URL = process.env.REACT_APP_BASE_URL
    let [ error, setError] = useState(null)
    let [ formError, setFormError] = useState(null)
    let [ products, setProducts ] = useState([])
    // let [ isLoaded, setLoad ] = useState(true)
    // Pagination 
    let [page, setPage] = useState(1);
    let [pageLimit, setPageLimit] = useState(100)
    let [numberPages, setNumberPages] = useState(null)

    let [name, setName] = useState("");
    let [category, setCategory] = useState([]);
    let [allergies, setAllergies] = useState([]);
    // Very hacky stuff
    let [allergy, setAllergy] = useState('000');
    //
    let [categories, setCategories] = useState([]);
    const [sortId, setSortId] = useState(null);
    const [sortName, setSortName] = useState(null);

    let [typeModal, setTypeModal] = useState(false)


    const token = ReactSession.get("token");
    // console.log('AllergiesTable token: ' + token)

    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }

    const sortProductsByAllergy = (productsArray) => {
        if (allergy === '000') {
            return productsArray
        }
        let mapedProds = productsArray.map((product) => {
           let algs =  product.allergies.filter((prodAllergy) => { 
                if ( prodAllergy.id === parseInt(allergy) ) {
                    return prodAllergy
                }
            })
            product.allergies = algs
            return product
        })
       let result = mapedProds.filter((prod) => {
           console.log(prod.allergies.length)
            if (prod.allergies.length > 0){
                return prod 
            }
        })
        return result
    }

    const getData = async () => {
        try {
            let res = await axios.get(BASE_URL + '/product/paginate?category=' + category + '&allergy=' +  allergy + '&name=' + name + '&limit=' + pageLimit  + '&page=' + page,config) 
            let prods = sortProductsByAllergy(res.data.data)
            setProducts(prods)
            setNumberPages(res.data.meta.last_page)
        } catch(error) {
            setError('getData: ' + error.message)
        }
    }

    const getCateogryData = async () => {
        try {
            let res = await axios.get(BASE_URL + '/categories', config)
            setCategories(res.data)
        } catch (error) {
            setError('getCateogryData: ' + error.message)
            setCategories([])
        }
    }

    const getAllergies = async () => {
        try {
            let res = await axios.get(BASE_URL +  '/allergy/mobile', config)
            setAllergies(res.data)
        } catch(error) {
            setError('getAllergies: ' + error.message)

            setAllergies([])
        }
    }

    const createType = async (data, e) => {
      
      let typeData = {
        typeName: data.type_name,
      }

      try{
        await axios.post(BASE_URL + '/types', typeData, config)
        setTypeModal(false)
        getData()
      }catch(error){
        setFormError('createType: ' + error.message)

      }
      
    }

    useEffect(() => {
        getData()
        getCateogryData()
        getAllergies()
    }, [name, page, allergy, category, pageLimit])
  
    const handlePageChange = (event, value) => {
      setPage(value);
    }

    if (error) {
      return <div>Error: {error}</div>;
  
    } else {
    return (
    <>
        <UserHeader />
        <Container className="mt--7" fluid>
        <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className='justify-content-between'>
                    <h3 className="mb-0 ml-4">Products</h3>
                      <Row className='justify-content-between mt-2'>
                      <ResultsPerPage pageLimit={pageLimit} setPageLimit={setPageLimit} page={page} setPage={setPage} rowAmount={products.length}/>
                      
                      <Link to='/admin/product/create'><Button sm='true' className='mr-4' color='primary'>Create</Button></Link>
                      {/* <h3 className="mb-0 ml-4"></h3> */}
                      <Button onClick={(e) => setTypeModal(true)} sm='true' className='mr-4' color='primary'>Create Type</Button>
                      </Row>
                    </Row>

                  </CardHeader>
                  <Form>
                    <div className="pl-lg-4">
                        <Row>
                            {/* <Col > */}
                                <FormGroup row className="col">
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Name
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="name"
                                        type="text"
                                        placeholder="Enter Name"
                                        value={name}
                                        onChange = {e => {
                                          setName(e.target.value)
                               
                                        }}
                                        />
                               
                                      </InputGroup>
                                    </Col> 
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Category
                                      </label>
                                      <InputGroup>
                                        <Input
                                            id="category-dropdown"
                                            name="category"
                                            type="select"
                                            value={category.name}
                                            onChange = {e => {
                                            e.preventDefault()
                                            setCategory(e.target.value)
                                            setPage(1)
                                            }}
                                        >
                                          <option value="">
                                              Select a Category
                                          </option>
                                        {categories.map((cat) => <option key={cat.id} value={cat.id}>{cat.name}</option>)}
                                        </Input>
                              
                                      </InputGroup>
                                    </Col>  
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Allergies
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="allergy"
                                        type="select"
                                        placeholder={allergy.name}
                                        value={allergy}
                                        onChange = {e => {
                                            e.preventDefault()
                                            setAllergy(e.target.value)
                                        }}
                                        >
                                        <option value="000">
                                            Select an Allergy
                                        </option>
                                        {allergies.map((alg) => <option key={alg.id} value={alg.id}>{alg.name}</option>)}
                                        </Input>
                          
                                      </InputGroup>
                                    </Col> 
                                </FormGroup>
                            {/* </Col>   */}
                        </Row>
                    </div>
                  </Form>
                  <Row>
                    <Col></Col>
                  </Row>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light text-center">
                      <tr>
                        <th scope="col" onClick={(e) => {
                            e.preventDefault()
                            setSortName(null)
                            if(sortId === 'asc' ) {setSortId('desc');}
                            else if(sortId === 'desc') {setSortId('asc');}
                            else if( sortId === null) {setSortId('desc');}
                            setPage(1)
                          }}>ID</th>
                        <th scope="col" onClick={(e) => {
                            e.preventDefault()
                            setSortId(null)
                            if(sortName === 'asc' ) {setSortName('desc');}
                            else if(sortName === 'desc' || sortName === null) {setSortName('asc');}
                            setPage(1)
                            
                          }}>Name</th>
                        <th scope="col">Price</th>
                        <th scrop="col">Category</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                    {products.map(product => (
                    <tr key={product.id} className='text-center'>
                        <td>
                            {product.id}
                        </td>
                        <td>{product.name}</td>
                        <td>{product.price}</td>
                        <td>{product.category.name}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color="success"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                // href="#pablo"
                                // onClick={(e) => e.preventDefault()}
                                tag={Link}
                                to={"/admin/product/" + product.id}
                              >
                                View
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                      ))}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                     <Pagination count={numberPages} color="primary" onChange={handlePageChange}/>

                      {/* <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                      <PaginationItem>
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              let prevpage = +page - +1;
                              if(prevpage >= 1) setPage(prevpage)
                            }}
                            // tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>

                        
                        {[...Array(numberPages)].map((x, i) =>
                            <PaginationItem key={i} className={`${page === (i+1) ? "active" : ""}`}>
                            <PaginationItem className="active">
                            <PaginationLink
                              onClick={(e) => { 
                                  setPage(e.target.innerHTML)
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        )}
                        <PaginationItem>
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              let nextpage = +page + +1;
                              if(nextpage <= numberPages) setPage(nextpage)
                            }}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination> */}
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
        </Container>
        <Modal isOpen={typeModal}>
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                Add Product Type
                </h5>
                
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => setTypeModal(false)}
                >
                <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="text-warning ml-3">{(formError) ? <p>{formError}</p> : ''}</div>
            <Form onSubmit={handleSubmit(createType)}>
            <div className="modal-body">
                <Row>
                <Col >
                        <label
                        className="form-control-label"
                        htmlFor="type_name"
                        >
                        Name
                        </label>
                        <input
                        className="form-control"
                        id="type_name"
                        type="text"
                        {...register('type_name')}
                        
                        />
                    </Col>
                    
                </Row>
            
            </div>
            <div className="modal-footer">
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={(e) => setTypeModal(false)}
                >
                Close
                </Button>
                <Button
                    color="primary"
                    data-dismiss="modal"
                    type="submit"
                >
                Save
                </Button>
            </div>
          </Form>
      </Modal>
    </>
    )
  }
} 

export default ProductsTable