import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect }  from 'react';
import { Link } from "react-router-dom";
import ResultsPerPage from "components/ResultsPerPage.js";
// import { useForm, Controller } from "react-hook-form";
import axios from 'axios'
import {
    // Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    // Media,
    // Pagination,
    // PaginationItem,
    // PaginationLink,
    // Progress,
    Table,
    Container,
    Row,
    // UncontrolledTooltip,
    Form,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
  } from "reactstrap";
  import { ReactSession } from 'react-client-session';
  import { Pagination } from "@mui/material";

const StudentsTable = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL
    let [role, setRole] = useState("");
    let [email, setEmail] = useState("");

    let [error, setError] = useState(null);
    let [isLoaded, setIsLoaded] = useState(false);
    let [students, setStudents] = useState([]);
    let [studentsFirst, setName] = useState("");
    let [studentsLast, setLastname] = useState("");

    // Sorting
    let [sortId, setSortId] = useState('null');
    let [sortName, setSortName] = useState('');
    let [school, setSchool ] = useState('')
    let [schoolSelected, setSchoolSelected] = useState(false)
    let [campuses, setCamps] = useState([])
    let [campus, setCampus] = useState([])
    let [selectedCampus, setSelectedCampus] = useState(false)
    let [grades, setGrades] = useState([])
    let [grade, setGrade] = useState([])
    let [classes, setClasses] = useState([])
    let [classroom, setClass] = useState([])
    // let [dob, setDob] = useState('')
    let [isAllowedToOrder, setIsAllowed] = useState('')

    //
    let [schools, setSchools] = useState([])
    // Pagination
    let [page, setPage] = useState(1);
    let [pageLimit, setPageLimit] = useState(25);
    let [numberPages, setNumberPages] = useState(1);


    const token = ReactSession.get("token");
    // console.log('AllergiesTable token: ' + token)

    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }

    const getData = async () => {
        try {
            let query =  "&first_name=" + studentsFirst  + '&last_name=' + studentsLast  + '&school=' +  school +  `&classroom=${classroom}&campus=${campus}&grade=${grade}&limit=` + pageLimit  + '&page=' + page  
            let res = await axios.get(BASE_URL + '/students? ' +  query, config) 
            setStudents(res.data.data)
            setNumberPages(res.data.meta.last_page)
        } catch(error) {
            console.log('error', error)
            setError('getData: ' + error.message)
        }
    }

    const getSchools = async () => {
        try {
            let res = await axios.get(BASE_URL + '/schools/mobile ', config) 
            setSchools(res.data)
        } catch(error) {
            setError('getSchools: ' + error.message)

            setSchools([])
        }
    }

    const getCampuses = async (schoolID) => {
      let query = `?school=${schoolID}`
      let res = await axios.get(BASE_URL + '/campuses' + query)
      if (res.status === 200) {
        setCamps(res.data)
      }
    }

    const getGrades = async (schoolID) => {
      let query = `?school=${schoolID}`
      let res = await axios.get(BASE_URL + '/grades' + query, config)
      if (res.status === 200) {
        setGrades(res.data)
      }
    }

    const getClassrooms = async (campusID) => {
      let query = `?campus=${campusID}`
      let res = await axios.get(BASE_URL + '/classrooms' + query)
      if (res.status === 200) {
        setClasses(res.data)
      }
    }   
    
    const handlePageChange = (event, value) => {
      setPage(value);
    };
  

    useEffect(() => {
        getData()
        getSchools()
    }, [studentsFirst, studentsLast, school, campus, grade, classroom, pageLimit, numberPages, page])


    if (error) {
      return <div>Error: {error}</div>;
  
    } else {

    return (
        <>
        <UserHeader />
        <Container className="mt--7" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="justify-content-between">
                      <h3 className="mb-0 ml-4">Students</h3>
                      <ResultsPerPage pageLimit={pageLimit} setPageLimit={setPageLimit} page={page} setPage={setPage} rowAmount={students.length}/>
                    </Row>
                  </CardHeader>
                  <Form>
                    <div className="pl-lg-4">
                        <Row>
                        <FormGroup row className="col">
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                       First Name
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="studentName"
                                        type="text"
                                        placeholder="Enter First Name"
                                        value={studentsFirst}

                                        onChange = {e => {
                                          setName(e.target.value)
                                        }}
                                        />
                                      </InputGroup>
                                    </Col>
                                    <Col>
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Last Name
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="studentLastName"
                                        type="text"
                                        placeholder="Enter Last Name"
                                        value={studentsLast}

                                        onChange = {e => {
                                          setLastname(e.target.value)
                                        }}
                                        />
                                      </InputGroup>
                                    </Col> 
                                    <Col>
                                      <label className=" form-control-label" htmlFor="basic-url">
                                          School
                                      </label>
                                      <InputGroup>
                                        <Input
                                            id="category-dropdown"
                                            name="category"
                                            type="select"
                                            value={school}
                                            onChange = {e => {
                                              e.preventDefault()
                                              setSchool(e.target.value)
                                              setSchoolSelected(true)
                                              getGrades(e.target.value)
                                              getCampuses(e.target.value)
                                              setPage(1)
                                            }}
                                        >
                                          <option value="">
                                              Select a School
                                          </option>
                                        {schools.map((sch) => <option key={sch.id} value={sch.id}>{sch.name}</option>)}
                                        </Input>
                                      </InputGroup>
                                    </Col>
                                </FormGroup>
                        </Row>
                        <Row>
                        <FormGroup row className="col">
                            <Col>
                                <label className=" form-control-label" htmlFor="basic-url">
                                    Campus
                                </label>
                                <InputGroup>
                                  <Input
                                      id="category-dropdown"
                                      name="campus"
                                      type="select"
                                      disabled={!schoolSelected} 
                                      value={campus}
                                      onChange = {e => {
                                        e.preventDefault()
                                        setCampus(e.target.value)
                                        setSelectedCampus(true)
                                        getClassrooms(e.target.value)
                                        setPage(1)
                                      }}
                                  >
                                    <option value="">
                                        Select a Campus
                                    </option>
                                  {school && campuses.map((campus) => <option key={campus.id} value={campus.id}>{campus.name}</option>)}
                                  </Input>
                              </InputGroup>
                            </Col> 
                            <Col>
                                <label className=" form-control-label" htmlFor="basic-url">
                                    Grade
                                </label>
                                <InputGroup>
                                  <Input
                                      id="category-dropdown"
                                      name="grade"
                                      type="select"
                                      value={grade}
                                      disabled={!schoolSelected} 
                                      onChange = {e => {
                                      e.preventDefault()
                                      setGrade(e.target.value)
                                      setPage(1)
                                      }}
                                  >
                                    <option value="">
                                        Select a Grade
                                    </option>
                                  {grades.map((grade) => <option key={grade.id} value={grade.id}>{grade.name}</option>)}
                                  </Input>
                              </InputGroup>
                            </Col> 
                            <Col>
                                <label className=" form-control-label" htmlFor="basic-url">
                                    Classroom
                                </label>
                                <InputGroup>
                                  <Input
                                      id="category-dropdown"
                                      name="classroom"
                                      type="select"
                                      value={classroom}
                                      disabled={!selectedCampus} 
                                      onChange = {e => {
                                      e.preventDefault()
                                      setClass(e.target.value)
                                      setPage(1)
                                      }}
                                  >
                                    <option value="">
                                        Select a Classroom
                                    </option>
                                  {classes.map((croom) => <option key={croom.id} value={croom.id}>{croom.class_number}</option>)}
                                  </Input>
                              </InputGroup>
                            </Col> 
                        </FormGroup>
                      </Row>
                    </div>
                  </Form>
                  <br></br>
                  <div>
                  </div>

                  {/* <ResultsPerPage pageLimit={pageLimit} setPageLimit={setPageLimit} page={page} setPage={setPage} /> */}
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light text-center">
                      <tr>
                        <th scope="col" onClick={(e) => {
                            e.preventDefault()
                            setSortName(null)
                            if(sortId === 'asc' ) {setSortId('desc');}
                            else if(sortId === 'desc') {setSortId('asc');}
                            else if( sortId === null) {setSortId('desc');}
                            setPage(1)
                          }}>ID</th>
                        <th scope="col" onClick={(e) => {
                            e.preventDefault()
                            setSortId(null)
                            if(sortName === 'asc' ) {setSortName('desc');}
                            else if(sortName === 'desc' || sortName === null) {setSortName('asc');}
                            setPage(1)
                            
                          }}>Name</th>
                        <th scope="col">School Name</th>
                        <th scrop="col">Is Allowed To Order</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                    {students.map(student => (
                        
                    <tr key={student.id} className='text-center'>
                        <td>
                                {student.id}
                        </td>
                        <td>{student.first_name + " " +  student.last_name}</td>
                        <td>{student.school.name}</td>
                        <td>{student.has_permission_to_order}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color="success"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                // href="#pablo"
                                // onClick={(e) => e.preventDefault()}
                                tag={Link}
                                to={"/admin/student/" + student.id}
                              >
                                View
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                      ))}
                      
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination count={numberPages} color="primary" onChange={handlePageChange}/>

                      {/* <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                      <PaginationItem>
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              let prevpage = +page - +1;
                              if(prevpage >= 1) setPage(prevpage)
                            }}
                            // tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>

                        
                        {[...Array(numberPages)].map((x, i) =>
                            <PaginationItem key={i} className={`${page==(i+1) ? "active" : ""}`}>
                            <PaginationItem className="active">
                            <PaginationLink
                              onClick={(e) => { 
                                  setPage(e.target.innerHTML)
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        )}

                
                        <PaginationItem>
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              let nextpage = +page + +1;
                              if(nextpage <= numberPages) setPage(nextpage)
                            }}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination> */}
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
        </Container>
    </>
    )
  }
}

export default StudentsTable