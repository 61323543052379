
// reactstrap components
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    ListGroup, 
    ListItem,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    Form,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button,
    UncontrolledTooltip,
    Label,
    Modal,
    Alert,
  } from "reactstrap";
  // core components
  import UserHeader from "components/Headers/UserHeader.js";
  import React, { useState, useEffect }  from 'react';
  import { useParams } from "react-router-dom";
  import { useForm, Controller } from "react-hook-form";
  import { Link } from "react-router-dom";
  import axios from 'axios';
  import parsePhoneNumber from 'libphonenumber-js'
  import { ReactSession } from 'react-client-session';

  const CustomerProfile = () => {
    const { register, handleSubmit, reset, formState, control } = useForm();
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const [error, setError] = useState(null);
    let [isLoaded, setIsLoaded] = useState(false);

    const {id} = useParams();
    
    const [customer, setCustomer] = React.useState([]);
    const [role, setRole] = React.useState("");

    // Get Data
    let [allergies, setAllergies] = useState([]);
    let [students, setStudents] = useState([]);

    // Pagination Controls
    let [page, setPage] = useState(1);
    let [pageLimit, setPageLimit] = useState(5);
    let [numberPages, setNumberPages] = useState(null);

    // Edit-Modal Control
    let [modalOpen, setModalOpen] = useState(false);
    let [modalData, setModalData] = useState([]);
    let [walletModalOpen, setWalletModal] = useState(false)

    let [alertOpen, setAlert] = useState(false)
    let [alertError, setErrorAlert] = useState(false)
  

    const token = ReactSession.get("token");
    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }
  

    const getData = async () => {
      try {
        let data = await axios.get(BASE_URL + '/customers/' + id, config)
        console.log(data)
        setCustomer(data.data);
        setRole(data.data.role);
        setAllergies(data.data.allergies);
        setStudents(data.data.parent.students);
        setIsLoaded(true);
      } catch(error) {
        setIsLoaded(true);
        setError(error);
      }
    }

    useEffect(() => {
      getData()
    }, [])

    const submitCustomerEditForm = async (data, e) => {
      // parse the phone number just in case
      let parsedNum = parsePhoneNumber(data.phone_number, 'US')
      let formData = {
        firstName: data.first_name,
        lastName: data.last_name,
        phoneNumber: parsedNum.formatNational(),
        email: data.email,
      }
      let res  = await axios.put(BASE_URL  + '/customers/' + customer.id, formData)
      if (res.status === 200) {
        getData()
        setAlert(true)
      } else {
        setErrorAlert(true)
      }
    }

    async function submitWalletBalance(formData, e) {
      let dataBalance = new Intl.NumberFormat('us-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(formData.wallet_amount);
      let balance = parseFloat(dataBalance).toFixed(2)
      let data = { balance: balance, id: customer.wallet.id }
      let res = await axios.put(BASE_URL + '/wallets/add/balance', data, config);
      if (res.status === 200) {
        getData()
        setAlert(true)
      } else {
        setErrorAlert(true)
      }
    }
  
    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else { 
        return (
        <>
            <UserHeader user={customer}/>
            {/* Page content */}
            <Container className="mt--7" fluid>
            <Alert color="success" isOpen={alertOpen}  fade={true} toggle={(e) => setAlert(false)}>
              Info updated!
            </Alert>
            <Alert color="danger" isOpen={alertError}  fade={true} toggle={(e) => setErrorAlert(false)}>
              Error updating customer information.
            </Alert>
            <Row>
                <Col className="order-xl-2 mb-1 mb-xl-0" xl="4">
                <Card className="card-profile shadow">
                    <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0">
                      <h3>
                        {customer.first_name} {customer.last_name}
                      </h3>
                    </CardHeader>
                    <CardBody className="pt-0 ">
                    <Row>
                        <div className="col">
                        <div className="card-profile-stats d-flex justify-content-center">
                            <div>
                            <span className="heading">{students.length}</span>
                            <span className="description">Students</span>
                            </div>
                            <div>
                            <span className="heading">{customer.orders.length}</span>
                            <span className="description">Orders</span>
                            </div>
                        </div>
                        </div>
                    </Row>
                    <div className="text-center">
                           {role === 'teacher' ? 
                           <div>
                                <div className="h5 font-weight-300">
                                    <i className="ni location_pin mr-2" />
                                    {customer.parent.school.name}
                                </div>
                                <div className="h5 font-weight-300">
                                    <i className="ni location_pin mr-2" />
                                    {customer.parent.classroom.campus.name}
                                </div>
                                <div className="h5 font-weight-300">
                                    <i className="ni location_pin mr-2" />
                                    {customer.parent.classroom.class_number}
                                </div>
                                <div className="h5 font-weight-300">
                                    <i className="ni location_pin mr-2" />
                                    Grade: {customer.parent.classroom.grade}
                                </div>
                           </div>
                           : null }
                    </div>
                    <Row className='justify-content-center'>
                     <Link to={'/admin/customer/' + customer.id + '/create/order'}><Button color='primary'>Create Order</Button></Link>
                    </Row>
                  </CardBody>
                </Card>
                </Col>
                <Col className="order-xl-1" xl="8">
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                        <Col xs="8">
                        <h3 className="mb-0">Customer Account</h3>
                        </Col>
                        <Col className="text-right" xs="4">
                        </Col>
                    </Row>
                    </CardHeader>
                    <CardBody>
                    <Form onSubmit={handleSubmit(submitCustomerEditForm)}>
                        <h6 className="heading-small text-muted mb-4">
                        Customer information
                        </h6>
                        <div className="pl-lg-4">
                        
                        <Row>
                            <Col lg="6">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                                >
                                First name
                                </label>
                                <input
                                className="form-control"
                                defaultValue={customer.first_name}
                                id="input-first-name"
                                placeholder="First name"
                                type="text"
                                {...register('first_name')}
                                />
                            </FormGroup>
                            </Col>
                            <Col lg="6">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="input-last-name"
                                >
                                Last name
                                </label>
                                <input
                                className="form-control"
                                defaultValue={customer.last_name}
                                id="input-last-name"
                                placeholder="Last name"
                                type="text"
                                {...register('last_name')}
                                />
                            </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="input-username"
                                >
                                Phone Number
                                </label>
                                <input
                              className="form-control"
                                defaultValue={customer.phone_number}
                                id="input-username"
                                placeholder="phone number"
                                type="text"
                                {...register('phone_number')}
                                />
                            </FormGroup>
                            </Col>
                            <Col lg="6">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="input-email"
                                >
                                Email address
                                </label>
                                <input
                                className="form-control"
                                id="input-email"
                                defaultValue={customer.user?.email}
                                placeholder={customer.user?.email}
                                type="email"
                                {...register('email')}
                                />
                            </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                            <FormGroup>
                                <Button type="submit" color="success" >
                                            Update
                                </Button>
                            </FormGroup>
                            </Col>
                            
                        </Row>
                        </div>
                    </Form>
                    </CardBody>
                </Card>
                </Col>
            </Row>
            <br />
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Allergies</h3>
                  </CardHeader>
                    <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light text-center">
                      <tr>
                        <th scope="col" 
                          >Name</th>
                        <th scope="col" />
                        
                      </tr>
                    </thead>
                    <tbody>
                      {allergies.length > 0 ? allergies.map(allergy => (
                    <tr key={allergy.id} className='text-center' >
                        {/* <th scope="row" className='text-center'>
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                                {allergy.id}
                              </span>
                            </Media>
                          </Media>
                        </th> */}
                        <td>{allergy.name}</td>
     
                        
                      </tr>
                      )) : <tr><td> Customer has no allergies</td></tr>}
                    
                      <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            toggle={() => setModalOpen(!modalOpen)} 
                            isOpen={modalOpen} 
                            >
                            <div className="modal-body p-0">
                                <Card className="bg-secondary shadow border-0">
                               
                                <CardBody className="px-lg-5 py-lg-5">
                                    
                                    <Form role="form" onSubmit={handleSubmit(data => {
                                        fetch(BASE_URL + "/allergy/" + modalData.id + "/edit", {
                                            method: 'PUT',
                                            body: JSON.stringify({name: data.newName}),
                                            headers: { 'Content-Type': 'application/json' },
                                          })
                                            .then(res => res.json())
                                            .then(
                                                (data) => {
                                                    console.log(data)
                                                    fetch(BASE_URL + "/allergy/?" + "page=" + page + "&limit=" + pageLimit)
                                                        .then(res => res.json())
                                                        .then(
                                                            (data) => {
                                                                console.log("updating list")
                                                                console.log(data)
                                                                setIsLoaded(true);
                                                                setAllergies(data.data);
                                                                setNumberPages(data.meta.last_page)
                                                                reset({"name":""});
                                                                setModalOpen(!modalOpen)
                                                            },
                                                            (error) => {
                                                                setIsLoaded(true);
                                                                setError(error);
                                                            }
                                                        )
                                                   
                                                    setIsLoaded(true);
                                                },
                                                (error) => {
                                                    setIsLoaded(true);
                                                    setError(error);
                                                }
                                            )
                                    })}>
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                        >
                                        Edit {modalData.name}
                                        </label>
                                        <InputGroup>
                                            <Controller
                                                name="newName"
                                                control={control}
                                                render={({ field }) => <Input {...field}  placeholder={modalData.name} />}
                                            />
                                            <Button type="submit" color="success" >
                                            Update
                                            </Button>
                                        </InputGroup>
                                    </FormGroup>
                                    </Form>
                                </CardBody>
                                </Card>
                            </div>
                            </Modal>
                    </tbody>
                  </Table>
                </Card>
                </div>
                <Col lg='4'>
                  <Card className='shadow'>
                  <CardHeader className="border-0">
                    <Row className='justify-content-between align-items-baseline'>
                      <h3 className="ml-4">Wallet Info</h3>
                      <Button size='sm' className='mr-4' onClick={() => setWalletModal(true)}>Add Balance</Button>  
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row className='justify-content-between'>
                      <span className='ml-4'>Balance</span>
                      <span className='mr-4'>${customer.wallet != null ? customer.wallet.balance : '0'}</span>
                    </Row>
                    <br></br>
                    <Row>
                      <div>
                        <ul>
                          {customer.wallet.paymentMethods.map((pm) => {
                            return <li>{pm.brand}  - {pm.last4}</li>
                          })}
                        </ul>
                      </div>
                    </Row>
                    <br></br>
                  </CardBody>
                  </Card>
                </Col>
            </Row>
            <br/>
            <Row>
                <div className="col">
                    <Card className="shadow">
                    <CardHeader className="border-0">
                        <h3 className="mb-0">Students</h3>
                    </CardHeader>
                    <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr className='text-center'>
                        <th scope="col" 
                          >ID</th>
                        <th scope="col" 
                  
                          >Name</th>
                        <th scope="col">School</th>
                        <th scope="col">Grade</th>
                        <th scope="col">Classroom</th>
                        <th scope="col">Permission to Order</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                    {students.map(student => (
                    <tr key={student.id} className='text-center'>
                        <td>
                          {student.id}
                        </td>
                        <td>{student.first_name} {student.last_name}</td>
                        <td>{student.school?.name}</td>
                        <td>{student.classroom.grade}</td>
                        <td>{student.classroom.class_number}</td>
                        <td>{student.has_permission_to_order}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              color="success"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                // href="#pablo"
                                // onClick={(e) => e.preventDefault()}
                                tag={Link}
                                to={"/admin/student/" + student.id}
                              >
                                View
                              </DropdownItem>
                              {/* <DropdownItem
                                // href="#pablo"
                   }}
                              >
                                Edit
                              </DropdownItem> */}
                              {/* <DropdownItem
                                // href="#pablo"
                 
                                }}
                              >
                                Delete
                              </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                        
                      </tr>
                      ))}
                      <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            toggle={() => setModalOpen(!modalOpen)} 
                            isOpen={modalOpen} 
                            >
                            <div className="modal-body p-0">
                                <Card className="bg-secondary shadow border-0">
                               
                                <CardBody className="px-lg-5 py-lg-5">
                                    
                                    <Form role="form" onSubmit={handleSubmit(data => {
                                        fetch(BASE_URL + "/allergy/" + modalData.id + "/edit", {
                                            method: 'PUT',
                                            body: JSON.stringify({name: data.newName}),
                                            headers: { 'Content-Type': 'application/json' },
                                          })
                                            .then(res => res.json())
                                            .then(
                                                (data) => {
                                                    fetch(BASE_URL + "/allergy/?" + "page=" + page + "&limit=" + pageLimit)
                                                        .then(res => res.json())
                                                        .then(
                                                            (data) => {
                                                                console.log("updating list")
                                                                console.log(data)
                                                                setIsLoaded(true);
                                                                setAllergies(data.data);
                                                                setNumberPages(data.meta.last_page)
                                                                reset({"name":""});
                                                                setModalOpen(!modalOpen)
                                                                // e.target.reset();
                                
                                                            },
                                                            (error) => {
                                                                setIsLoaded(true);
                                                                setError(error);
                                                            }
                                                        )
                                                   
                                                    setIsLoaded(true);
                                                },
                                                (error) => {
                                                    setIsLoaded(true);
                                                    setError(error);
                                                }
                                            )
                                    })}>
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                        >
                                        Edit {modalData.name}
                                        </label>
                                        <InputGroup>
                                            <Controller
                                              name="newName"
                                              control={control}
                                              render={({ field }) => <Input {...field}  placeholder={modalData.name} />}
                                            />
                                          <Button type="submit" color="success" >
                                            Update
                                          </Button>
                                        </InputGroup>
                                    </FormGroup>
                                    </Form>
                                </CardBody>
                                </Card>
                            </div>
                            </Modal>
                    </tbody>
                  </Table>
                    </Card>
                </div>
            </Row>
            <br/>
            <Row>
                <div className="col">
                    <Card className="shadow">
                    <CardHeader className="border-0">
                        <h3 className="mb-0">Orders</h3>
                    </CardHeader>
                    <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr  className='text-center'>
                        <th scope="col" 
                          >ID</th>
                        <th scope="col">Total Cost</th>
                        <th scope="col">Qty Meals</th>
                        <th scope="col">Date Ordered</th>
                        <th scope="col">More</th>
                      </tr>
                    </thead>
                    <tbody>
                      {customer.orders.length > 0 ?<>
                      {customer.orders.map((order) => <tr className='text-center'>
                        <td>
                          {order.id}
                        </td>
                        <td>
                          {order.total}
                        </td> 
                        <td>
                          {order.meals.length}
                        </td>
                        <td>
                          {Intl.DateTimeFormat('en-US').format(new Date(Date.parse(order.created_at)))}
                        </td>
                        <td>
                          <Link to={`/admin/orders/${order.id}`}>See Details</Link>
                        </td>
                      </tr> )}
                      </> : <tr className='pt-4'><td>Order history not available</td></tr>}
                  
                    </tbody>
                    <tbody>
                    {/* {allergies.map(allergy => (
                    <tr key={allergy.id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            <Media>
                              <span className="mb-0 text-sm">
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                // href="#pablo"
                                // onClick={(e) => e.preventDefault()}
                                tag={Link}
                                to={"/admin/user/" + allergy.id}
                              >
                                View
                              </DropdownItem>
                              <DropdownItem
                                // href="#pablo"
                                // onClick={(e) => e.preventDefault()}
                                onClick={() => {
                                    setModalData(allergy); 
                                    setModalOpen(!modalOpen)}}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                // href="#pablo"
                                onClick={(e) => {
                                    fetch(BASE_URL + "/allergy/" + allergy.id, {
                                        method: 'DELETE',
                                    })
                                        .then(res => res.json())
                                        .then(
                                            (data) => {
                                                setPage(1)
                                                setIsLoaded(true);
                                            },
                                            (error) => {
                                                setIsLoaded(true);
                                                setError(error);
                                            }
                                        )
                                }}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                        
                      </tr>
                      ))} */}
                      <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            toggle={() => setModalOpen(!modalOpen)} 
                            isOpen={modalOpen} 
                            >
                            <div className="modal-body p-0">
                                <Card className="bg-secondary shadow border-0">
                               
                                <CardBody className="px-lg-5 py-lg-5">
                                    
                                    <Form role="form" onSubmit={handleSubmit(data => {
                                        fetch(BASE_URL + "allergy/" + modalData.id + "/edit", {
                                            method: 'PUT',
                                            body: JSON.stringify({name: data.newName}),
                                            headers: { 'Content-Type': 'application/json' },
                                          })
                                            .then(res => res.json())
                                            .then(
                                                (data) => {
                                                    console.log(data)
                                                    fetch(BASE_URL + "/allergy/?" + "page=" + page + "&limit=" + pageLimit)
                                                        .then(res => res.json())
                                                        .then(
                                                            (data) => {
                                                                console.log("updating list")
                                                                console.log(data)
                                                                setIsLoaded(true);
                                                                setAllergies(data.data);
                                                                setNumberPages(data.meta.last_page)
                                                                reset({"name":""});
                                                                setModalOpen(!modalOpen)
                                                                // e.target.reset();
                                
                                                            },
                                                            (error) => {
                                                                setIsLoaded(true);
                                                                setError(error);
                                                            }
                                                        )
                                                   
                                                    setIsLoaded(true);
                                                },
                                                (error) => {
                                                    setIsLoaded(true);
                                                    setError(error);
                                                }
                                            )
                                    })}>
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                        >
                                        Edit {modalData.name}
                                        </label>

                                        <InputGroup>
                                            <Controller
                                                name="newName"
                                                control={control}
                                                render={({ field }) => <Input {...field}  placeholder={modalData.name} />}
                                               
                                            />
                                            <Button type="submit" color="success" >
                                            Update
                                            </Button>
                                        </InputGroup>
                                        
                                    </FormGroup>
                                    
                                    
                                    </Form>
                                </CardBody>
                                </Card>
                            </div>
                            </Modal>
                    </tbody>
                  </Table>
                    </Card>
                </div>
            </Row>
            <Modal isOpen={walletModalOpen}>
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                Add Balance
                </h5>
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => setWalletModal(false)}
                >
                <span aria-hidden={true}>×</span>
                </button>
            </div>
            <Form onSubmit={handleSubmit(submitWalletBalance)}>
            <div className="modal-body">
                <Row className='justify-content-center'>
                        <Col lg="8">
                                <label
                                className="form-control-label"
                                htmlFor="mod_name"
                                >
                                Amount
                                </label>
                                <input
                                className="form-control"
                                id="mod-name"
                                placeholder="$0.0"
                                type="text"
                                {...register('wallet_amount')}
                                required
                                />
                            </Col>
                </Row>
            </div>
            <div className="modal-footer">
                <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    onClick={(e) => setWalletModal(false)}
                >
                Close
                </Button>
                <Button
                    color="primary"
                    data-dismiss="modal"
                    type="submit"
                    onClick={(e) => setWalletModal(false)}
                >
                Save
                </Button>
            </div>
            </Form>
            </Modal>
          </Container>
        </>
        );
    }
  };
  
  export default CustomerProfile;
  