/*!


* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import {
    // Badge,
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
    // Pagination,
    // PaginationItem,
    // PaginationLink,
    // Progress,
    Table,
    Container,
    Row,
    // UncontrolledTooltip,
    Form,
    Col,
    FormGroup,
    Input,
    InputGroup,
    // InputGroupAddon,
    // Button,
  } from "reactstrap";
  // core components
  import UsersTableHeader from "components/Headers/UsersTableHeader.js";
  import React, { useState, useEffect }  from 'react';
  import { Link } from "react-router-dom";
  import ResultsPerPage from "components/ResultsPerPage.js";
  // import { useForm, Controller } from "react-hook-form";
  import { ReactSession } from 'react-client-session';
  import { Pagination } from "@mui/material";
  import axios from 'axios'


  const CustomersTable = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL
    // const { register, handleSubmit, reset, formState, control } = useForm();

    let [error, setError] = useState(null);
    let [isLoaded, setIsLoaded] = useState(false);
    let [customers, setCustomers] = useState([]);

    // Filtering
    let [name, setName] = useState("");
    let [role, setRole] = useState("");
    let [email, setEmail] = useState("");

    // Sorting
    let [sortId, setSortId] = useState(null);
    let [sortName, setSortName] = useState(null);

    // Pagination
    let [page, setPage] = useState(1);
    let [pageLimit, setPageLimit] = useState(25);
    let [numberPages, setNumberPages] = useState(null);

    const token = ReactSession.get("token");
    // console.log('AllergiesTable token: ' + token)

    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }


    const url = `${BASE_URL}/customers/`;
    const params = {
      page: page,
      limit: pageLimit,
      sortName: sortName,
      sortId: sortId,
      name: name,
      role: role,
      email: email,
    };

    useEffect(() => {
      axios
      .get(url, { ...config, params })
      .then((response) => {
        const data = response.data;
        if (data["error"]) {
          setIsLoaded(true);
          setError(data["error"]);
        } else {
          setIsLoaded(true);
          setCustomers(data.data);
          setNumberPages(data.meta.last_page);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    
      }, [page, pageLimit,sortName,sortId, name, role, email])

      const handlePageChange = (event, value) => {
        setPage(value);
      }

    if (error) {
        return <div>Error: {error.responseText}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

      return (
        <>
          <UsersTableHeader />
          {/* Page content */}
          <Container className="mt--7" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                  <Row className="justify-content-between">
                      <h3 className="mb-0 ml-4">Customers</h3>
                      <ResultsPerPage pageLimit={pageLimit} setPageLimit={setPageLimit} page={page} setPage={setPage} rowAmount={customers.length}/>
                    </Row>
                  </CardHeader>
                  <Form>
                    <div className="pl-lg-4">
                        <Row>
                            {/* <Col > */}
                                <FormGroup row className="col">
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Name
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="name"
                                        type="text"
                                        placeholder="Enter Name"
                                        value={name}
                                        onChange = {e => {
                                          setName(e.target.value)
                               
                                        }}
                                        />
                                      </InputGroup>
                                    </Col> 
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Role
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="role"
                                        type="select"
                                        value={role}
                                        onChange = {e => {
                                          setRole(e.target.value)
                                          setPage(1)
                                        }}
                                        >
                                          <option value="">
                                              Select a Role
                                          </option>
                                        
                                          <option key='parent' value='parent'>
                                              Parent
                                          </option>
                                          <option key='teacher' value='teacher'>
                                              Teacher
                                          </option>
                                          {/* <option key='student' value='student'>
                                              Student
                                          </option> */}
                                        </Input>
                                      </InputGroup>
                                    </Col>  
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Email
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="email"
                                        type="text"
                                        placeholder="Enter Email"
                                        value={email}

                                        onChange = {e => {
                                          setEmail(e.target.value)
                               
                                        }}
                                        />
                                      </InputGroup>
                                    </Col> 
                                       
                                </FormGroup>
                            {/* </Col>   */}
                        </Row>
                    </div>
                  </Form>
                  <Row>
                    <Col></Col>
                  </Row>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        {/* <th scope="col">Project</th>
                        <th scope="col">Budget</th>
                        <th scope="col">Status</th>
                        <th scope="col">Users</th>
                        <th scope="col">Completion</th>
                        <th scope="col" /> */}
                        <th scope="col" onClick={(e) => {
                            e.preventDefault()
                            setSortName(null)
                            if(sortId === 'asc' ) {setSortId('desc');}
                            else if(sortId === 'desc') {setSortId('asc');}
                            else if( sortId === null) {setSortId('desc');}
                            setPage(1)
                          }}>ID</th>
                        <th scope="col" onClick={(e) => {
                            e.preventDefault()
                            setSortId(null)
                            if(sortName === 'asc' ) {setSortName('desc');}
                            else if(sortName === 'desc' || sortName === null) {setSortName('asc');}
                            setPage(1)
                            
                          }}>Name</th>
                        <th scope="col">Role</th>
                        <th scope="col">Email</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                    {customers.map(customer => (
                    <tr key={customer.id}>
                        <th scope="row">
                          <Media className="align-items-center">
                            {/* <a
                              className="avatar rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <img
                                alt="..."
                                src={
                                  require("../../assets/img/theme/bootstrap.jpg")
                                    .default
                                }
                              />
                            </a> */}
                            <Media>
                              <span className="mb-0 text-sm">
                                {customer.id}
                              </span>
                            </Media>
                          </Media>
                        </th>
                        <td>{customer.first_name} {customer.last_name}</td>
                        <td>{customer.role}</td>
                        <td>{customer.user.email}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color="success"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              {customer.role != 'student'  ?  <DropdownItem
                                // href="#pablo"
                                // onClick={(e) => e.preventDefault()}
                                tag={Link}
                                to={"/admin/customer/" + customer.id}
                              >
                                View 
                                </DropdownItem>
                                : 
                                <DropdownItem
                                // href="#pablo"
                                // onClick={(e) => e.preventDefault()}
                                tag={Link}
                                to={"/admin/student/" + customer.id}
                              >
                                View
                              </DropdownItem>
                                }
                           
                              {/* <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Delete
                              </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                      ))}
                      
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination count={numberPages} color="primary" onChange={handlePageChange}/>

                      {/* <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem>
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              let prevpage = +page - +1;
                              if(prevpage >= 1) setPage(prevpage)
                            }}
                            // tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>

                        
                        {[...Array(numberPages)].map((x, i) =>
                            <PaginationItem key={i} className={`${page==(i+1) ? "active" : ""}`}>
                            <PaginationItem className="active">
                            <PaginationLink
                              onClick={(e) => { 
                                  setPage(e.target.innerText)
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        )}

                
                        <PaginationItem>
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              let nextpage = +page + +1;
                              if(nextpage <= numberPages) setPage(nextpage)
                            }}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination> */}
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
            
          </Container>
        </>
      );
    }
  };
  
  export default CustomersTable;
  