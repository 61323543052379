function removeDuplicateCampuses(school) {
    school.campuses = school.campuses.filter((campus, index, self) =>
        index === self.findIndex((c) => (
            c.name === campus.name && c.address === campus.address && 
            c.state === campus.state && c.city === campus.city && 
            c.zipCode === campus.zipCode
        ))
    );
    return school;
}
export default removeDuplicateCampuses;