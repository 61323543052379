import {
Container, 
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect }  from 'react';
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import axios from 'axios';

import { StandardRectangle } from 'react-avery';


const ImportsExportsPage = () => {
    const { Layout, Sheet, LabelRow, Label,  } = StandardRectangle;

    return (
    <>
    </>
    )
}


export default ImportsExportsPage