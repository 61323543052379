/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { useState } from 'react';


const Register = () => {
  const { handleSubmit, register, watch } = useForm();
  const BASE_URL = process.env.REACT_APP_BASE_URL

  let  [registered, setRegistered] = useState(false)
  let  [error, setError] = useState(false)
  let  [errorMessage, setErrorMessage] = useState('')

  const createUser = async (data, e ) => {
    let userData = {
     name: data.name,
     password: data.password,
     email: data.email,
     role: 'admin',
    }


    await axios.post(BASE_URL + '/users', userData)
    .then((response) => {
      setRegistered(true)
    })
    .catch(function (error) {
      if (error.response) {
        console.error("The request was made and the server responded with a status code that falls out of the range of 2xx")
        setError(true)
        setErrorMessage(error.response.data.error.detail)
      } else if (error.request) {
        console.error("The request was made but no response was received")
      } else {
        console.error("Something happened in setting up the request that triggered an Error")
      }
    });
  }



  return (
      
      <>
      
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-4">
                <small>Sign Up</small>
              </div>
              <p className="text-danger text-center">{error ? errorMessage : ''} </p>  
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
            {!registered
              ? 
              <Form role="form" onSubmit={handleSubmit(createUser)}>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-hat-3" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <input
                        className="form-control"
                        id="name"
                        placeholder="Name"
                        type="text"
                        {...register('name')}
                        required
                        />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <input
                      className="form-control"
                      placeholder="Email"
                      defaultValue=""
                      type="email"
                      id="email"
                      {...register('email')}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <input 
                      className="form-control"
                      placeholder="Password"
                      type="password"
                      id="password"
                      defaultValue=""
                      {...register('password')}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                {/* <div className="text-muted font-italic">
                  <small>
                    password strength:{" "}
                    <span className="text-success font-weight-700">strong</span>
                  </small>
                </div> */}
                
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="submit">
                    Create account
                  </Button>
                </div>
              </Form>
              : 
                <Row className="mx-auto">
                  <Col className="mx-auto">
                    <p className="text-center">Thanks for registering!</p>
                  </Col>
                </Row> 
            }
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
            <a
                className="text-light"
                href="/auth/login"
              >
                <small>Already have an account?</small>
              </a>
            </Col>
            
          </Row>
        </Col>
        
    
      </>
      
  );
};

export default Register;
