import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,    
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import toast from 'react-hot-toast';

function EditMealModal({
    isOpen,
    onSubmit,
    schoolYearID,
    schoolID,
    campusID,
    classroomID,
    gradeID,
    toggle
  }) {
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState(null);
  const [selectedCampus, setSelectedCampus] = useState(null);
  const [selectedClassroom, setSelectedClassroom] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL

  const [schoolOpen, setSchoolOpen] = useState(false);
  const [schoolYearOpen, setSchoolYearOpen] = useState(false);
  const [campusOpen, setCampusOpen] = useState(false);
  const [classroomOpen, setClassroomOpen] = useState(false);
  const [gradeOpen, setGradeOpen] = useState(false);

  let [schools, setSchools] = useState([]) // Populate youxr schools array here

  let [schoolYears, setSchoolYears] = useState([]); // Populate your school years array here
  let [campuses, setCampuses] = useState([]); // Populate your campuses array here
  let [classrooms, setClassRoom] = useState([]); // Populate your classrooms array here
  let [grades, setGrades] = useState([]); // Populate your grades array here
  
  const [dataFetched, setDataFetched] = useState(false);

  const token = ReactSession.get("token")
  let config = {
    headers: {
      'Authorization': `Bearer ${token}` 
    }
  }

  const getSchools = async () => {
    let res = await axios.get(BASE_URL + '/schools/mobile ', config)
    setSchools(res.data)
  }

  const getSchoolYears = async () => {
    let res = await axios.get(BASE_URL + '/school_years/', config)
    setSchoolYears(res.data)
  }

  const getCampuses = async (schoolID) => {
    let query = '';
    if (schoolID) {
        query += `?school=${schoolID}`;
    }
    if (selectedSchoolYear) {
        query += query ? `&year=${selectedSchoolYear.id}` : `?year=${selectedSchoolYear.id}`;
    }
    let res = await axios.get(BASE_URL + '/campuses' + query);
    if (res.status === 200) {
        setCampuses(res.data);
    }
  }

  const getClassrooms = async (campusID) => {
    let query = ''
    if (selectedSchoolYear == null) {
        query += `?campus=${campusID}`
    }  else {
      query += `?campus=${campusID}&year=${selectedSchoolYear.id}`
    }
    let res = await axios.get(BASE_URL + '/classrooms' + query)
    if (res.status === 200) {
        setClassRoom(res.data)
    }
  }

  const getGrades = async (schoolID) => {
    let query = ''
    if (selectedSchoolYear == null) {
        query += `?school=${schoolID}`
    } else {
        query += `?year=${selectedSchoolYear.id}&school=${schoolID}`
    }
    let res = await axios.get(BASE_URL + '/grades' + query, config)
    if (res.status === 200) {
      setGrades(res.data)
    }
  }

  const save = () => {
    if (!selectedCampus || 
        !selectedClassroom ||
        !selectedGrade ||
        !selectedSchool ||
        !selectedSchoolYear) {
      toast.error('Please select all fields')
      } else {
        let data = {
          grade_id: selectedGrade.id,
          classroom_id: selectedClassroom.id,
          school_id: selectedSchool.id,
          year_id: selectedSchoolYear.id,
          campus_id: selectedCampus.id
        }
        onSubmit(data)
      }
  }

  useEffect(() => {
    // Fetch school years and schools data
    const fetchData = async () => {
      await getSchools();
      await getSchoolYears();
      setDataFetched(true); // Set the flag that data has been fetched
    };
  
    fetchData();
  }, []);
  
  useEffect(() => {
    // Run the pre-selection logic once data has been fetched and isOpen is true
    if (dataFetched) {
      // Pre-select school year if provided
      if (schoolYearID) {
        const selectedYear = schoolYears.find(year => year.id === schoolYearID);
        setSelectedSchoolYear(selectedYear);
        if (selectedSchool != null) {
          getCampuses(selectedSchool.id);
          getClassrooms(selectedSchool.id);
          getGrades(selectedSchool.id);
        }
      }
  
      // Pre-select school if provided
      if (schoolID) {
        const foundSchool = schools.find(school => school.id === schoolID);
        setSelectedSchool(foundSchool);
        if (foundSchool) {
          getClassrooms(foundSchool.id);
          getGrades(foundSchool.id);
          getCampuses(foundSchool.id);
        }
        // Fetch related data for the selected school
      }
  
      // Pre-select campus if provided
      if (campusID) {
        const selectedCampus = campuses.find(campus => campus.id === campusID);
        setSelectedCampus(selectedCampus);
        // Fetch related data for the selected campus
        if (selectedCampus) {
          getClassrooms(selectedCampus.id);
        }
      }
  
      // Pre-select classroom if provided
      if (classroomID) {
        const selectedClassroom = classrooms.find(classroom => classroom.id === classroomID);
        if (selectedClassroom) {
          setSelectedClassroom(selectedClassroom);
        }
      }
  
      // Pre-select grade if provided
      if (gradeID) {
        const selectedGrade = grades.find(grade => grade.id === gradeID);
        if (selectedGrade) {
          setSelectedGrade(selectedGrade);
        }
      }
    }
  }, [dataFetched, isOpen]);
  
  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      toggle={toggle}
      isOpen={isOpen}
    >
      <ModalHeader
        toggle={toggle}
      >Edit Meal</ModalHeader>
      <ModalBody>
        <Row>
          <Col lg="6">
              <label className="form-control-label" htmlFor="input-schoolYears">
              School Year
              </label>
              <br></br>
              <Dropdown isOpen={schoolYearOpen} toggle={() => setSchoolYearOpen(!schoolYearOpen)}>
              <DropdownToggle caret>
                  {selectedSchoolYear ? <span>{selectedSchoolYear.name}</span> : <span>Click to open</span>}
              </DropdownToggle>
              <DropdownMenu>
                  {schoolYears.map((year) => (
                  <DropdownItem key={year.id} onClick={ async () => {
                      setSelectedSchoolYear(year)
                      if (selectedSchool != null) {
                          await getCampuses(selectedSchool.id)
                          await getClassrooms(selectedSchool.id)
                          await getGrades(selectedSchool.id)
                      }

                  }}>
                      {year.name}
                  </DropdownItem>
                  ))}
              </DropdownMenu>
              </Dropdown>
          </Col>
          <Col lg="6">
              <label className="form-control-label" htmlFor="input-schools">
              School
              </label>
              <br></br>
              <Dropdown isOpen={schoolOpen} toggle={() => setSchoolOpen(!schoolOpen)}>
              <DropdownToggle caret>
                  {selectedSchool ? <span>{selectedSchool.name}</span> : <span>Click to open</span>}
              </DropdownToggle>
              <DropdownMenu>
                  {schools.map((school) => (
                  <DropdownItem key={school.id} onClick={async () => {
                      setSelectedSchool(school)
                      await getClassrooms(school.id)
                      await getGrades(school.id)
                      await getCampuses(school.id)
                  }}>
                      {school.name}
                  </DropdownItem>
                  ))}
              </DropdownMenu>
              </Dropdown>
          </Col>
        </Row>

        <Row>
          <Col lg="6">
              <label className="form-control-label" htmlFor="input-campuses">
              Campus
              </label>
              <br></br>
              <Dropdown isOpen={campusOpen} toggle={() => {
                  if (selectedSchool == null) {
                      toast.error('Please select a school first');          
                  } else {
                      setCampusOpen(!campusOpen)
                  }
              }}>
              <DropdownToggle caret>
                  {selectedCampus ? <span>{selectedCampus.name}</span> : <span>Click to open</span>}
              </DropdownToggle>
              <DropdownMenu>
                  {campuses.map((campus) => (
                  <DropdownItem key={campus.id} onClick={async () => {
                      if (selectedSchool == null) {
                          toast.error('Please select a school first');          
                      } else {
                          setSelectedCampus(campus);
                          await getClassrooms(campus.id);
                      }
                  }}
                  >
                      {campus.name}
                  </DropdownItem>
                  ))}
              </DropdownMenu>
              </Dropdown>
          </Col>
          <Col lg="6">
          <label className="form-control-label" htmlFor="input-classrooms">
            Classroom
          </label>
          <br></br>
          <Dropdown isOpen={classroomOpen} toggle={() => {
              if (selectedCampus == null) {
                  toast.error('Please select a campus first');          
              } else {
                  setClassroomOpen(!classroomOpen)
              }
          }}>
            <DropdownToggle caret>
              {selectedClassroom ? <span>{selectedClassroom.class_number}</span> : <span>Click to open</span>}
            </DropdownToggle>
            <DropdownMenu>
              {classrooms.map((classroom) => (
                <DropdownItem key={classroom.id} onClick={() => setSelectedClassroom(classroom)}>
                  {classroom.class_number}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          </Col>
        </Row>
        <Row 
        className='justify-content-center align-items-end'
        >
        <Col lg="6">
            <label className="form-control-label" htmlFor="input-grades">
            Grade
            </label>
            <br></br>
            <Dropdown isOpen={gradeOpen} toggle={() => {
                if (selectedSchool == null) {
                    toast.error('Please select a school first');          
                } else {
                    setGradeOpen(!gradeOpen)
                }
            }}>
            <DropdownToggle caret>
                {selectedGrade ? <span>{selectedGrade.name}</span> : <span>Click to open</span>}
            </DropdownToggle>
            <DropdownMenu>
                {grades.map((grade) => (
                <DropdownItem key={grade.id} onClick={() => setSelectedGrade(grade)}>
                    {grade.name}
                </DropdownItem>
                ))}
            </DropdownMenu>
            </Dropdown>
        </Col>
        <Col lg="6">
            <Button
            color='success'
            data-dismiss="modal"
            onClick={() => {
              save()
            }}
            >Save</Button>
        </Col>
        </Row>

      </ModalBody>
    </Modal>
  );
}

export default EditMealModal;
