import {
Card,
CardBody,
CardHeader,
Container, 
Row,
Form,
Col,
FormGroup,
Button,
InputGroup,
Input,
} from "reactstrap";
    // core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect }  from 'react';
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import axios from 'axios';

const CreateSchool = () => {
    const { handleSubmit, register, control } = useForm();
    const BASE_URL = process.env.REACT_APP_BASE_URL
    // Things get filtered and set for the modals, except for the modifiers
    let [isLoaded, setIsLoaded] = useState(false)
    let [updateLoad, setUpdate] =useState(false)
    const [error, setError] = useState(null);
    const { id } = useParams();
    const  history  = useHistory()
    const [value, setValue] = useState(true)


    const createSchool = async (data,e) => {
        let schoolData = {
            name: data.school_name,
            abbreviation: data.school_abbreviation,
            // cutoff_time: data.cutoff_time,
            has_weekly_orders: true,
            phone_number: data.phone_number,
            email: data.school_email,
            school_code: data.school_code
        }
        let res = await axios.post(BASE_URL + '/schools', schoolData)
        if (res.status === 200) {
            history.push(`/admin/schools/${res.data.id}`)
        } else {
            // Error
        }
    }

    const handleToggle = () => {
        setValue(!value)
    }
    return (
        <>
        <UserHeader/>
        <Container fluid className="mt--7" >
        <Row className='align-items-center justify-content-center'>
        <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                    <Col xs="8">
                    <h3 className="mb-0">Create School</h3>
                    </Col>
                    <Col className="text-right" xs="4">
                    {/* <h4>Category: {product.category.name}</h4> */}
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                <Form onSubmit={handleSubmit(createSchool)}>
                    <div className="pl-lg-4">
                        <Row>
                            <Col lg="4">
                                <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="input-school-name"
                                    >
                                    Name
                                    </label>
                                    <input
                                    className="form-control"
                                    id="input-school-name"
                                    placeholder="School Name"
                                    type="text"
                                    {...register('school_name')}
                                    required
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="4">
                                <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="school_email"
                                    >
                                    Email
                                    </label>
                                    <input
                                    className="form-control"
                                    id="school_email"
                                    placeholder="school@edu.org"
                                    type="email"
                                    required
                                    {...register('school_email')}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="4">
                                <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="phone_number"
                                    >
                                    Phone Number
                                    </label>
                                    <input 
                                    className='form-control' 
                                    type='numeric' 
                                    placeholder="123XXXXXXX"
                                    required
                                    {...register('phone_number')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                        <Col lg="4">
                            <FormGroup>
                                <label
                                className="form-control-label"
                                htmlFor="school_abbreviation"
                                >
                                Abbreviation
                                </label>
                                <input
                                className="form-control"
                                id="school_abbreviation"
                                placeholder="..."
                                type="text"
                                required
                                {...register('school_abbreviation')}
                                />
                            </FormGroup>
                        </Col>
                            <Col lg="4">
                                <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="school_code"
                                    >
                                    School Code
                                    </label>
                                    <input
                                    className="form-control"
                                    id="school_code"
                                    placeholder="..."
                                    type="text"
                                    required
                                    {...register('school_code')}
                                    />
                                </FormGroup>
                            </Col>
                            {/* <Col lg="4" className='align-items-center justify-content-center'>
                                <FormGroup>
                                    <label
                                    className="form-control-label"
                                    >
                                        Weekly Orders
                                    </label>
                                    <br></br>
                                    <label
                                    className="custom-toggle"
                                    >
                                    <input
                                    className="form-control"
                                    id="weekly_orders"
                                    type="checkbox"
                                    checked={value}
                                    onClick={handleToggle}
                                    {...register('weekly_orders')}
                                    />
                                    <span className="custom-toggle-slider rounded-circle" data-label-off="OFF" data-label-on="ON"></span>
                                    </label>
                                </FormGroup>
                            </Col> */}
                            {/* <Col lg='4'>
                            <FormGroup>
                                    <label
                                    className="form-control-label"
                                    htmlFor="cutoff_time"
                                    >
                                        Cutoff Time
                                    </label>
                                    <input
                                    className="form-control"
                                    id="weekly_orders"
                                    type="time"
                                    required
                                    {...register('cutoff_time')}
                                    />
                                </FormGroup>
                            </Col> */}
                        </Row>
                        <br></br>
                        <Row>
                           { updateLoad ? <span>...</span>: <Col lg="6">
                            <FormGroup>
                                <Button type="submit" color="success" >
                                    Create
                                </Button>
                            </FormGroup>
                            </Col>}
                        </Row>
                        </div>
                </Form>
                </CardBody>
            </Card>
        </Row>
        </Container>
        </>
    )
}

export default CreateSchool