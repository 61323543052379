import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect }  from 'react';
import { Link } from "react-router-dom";
// import ResultsPerPage from "components/ResultsPerPage.js";
import axios from 'axios';
import { 
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Col,
} from 'reactstrap';
import { ReactSession } from 'react-client-session';

const MenusTable =  () => {

    const BASE_URL = process.env.REACT_APP_BASE_URL
    let [ error, setError] = useState(null)
    let [ menus, setMenus ] = useState([])
    let [ isLoaded, setIsLoaded ] = useState(true)

    let [page, setPage] = useState(1);
    // let [pageLimit, setPageLimit] = useState(5)
    let [numberPages, setNumberPages] = useState(null)

    const token = ReactSession.get("token");

    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }
  

    const getData = async () => {
        try {
            // let uqey 
            let res = await axios.get(BASE_URL + '/menus', config) 
            setMenus(res.data)
            // setNumberPages(res.data.meta.last_page)
        } catch(error) {
            setError('getData: ' + error.message)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    if (error) {
      return <div>Error: {error}</div>;
  }else{
    return (
    <>
        <UserHeader />
          {/* Page content */}
          <Container className="mt--7" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row>
                    <h3 className="mb-0 pl-3">Menus</h3>
                    </Row>
                  </CardHeader>
                  {/* Form to add allergy */}
                  <Row className='justify-content-between'>
                  <Col xs="5">
                  </Col>
                  </Row>
                  {/* Form to filter results by query */}
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light text-center">
                      <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Name</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                    {menus.map(menu => (
                    <tr key={menus.id} className='text-center' >
                        <td className='text-center'>
                            <span className="mb-0 text-sm">
                              {menu.id}
                            </span>
                        </td>
                        <td>{menu.name}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              color="success"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                // href="#pablo"
                                // onClick={(e) => e.preventDefault()}
                                tag={Link}
                                to={"/admin/menu/" + menu.id}
                              >
                                View
                              </DropdownItem>
                              <DropdownItem
                                // href="#pablo"
                                // onClick={(e) => e.preventDefault()}
                                onClick={(e) => {
                               e.preventDefault()
                                }}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                // href="#pablo"
                                onClick={(e) => {
                                    fetch(BASE_URL + "/menu/" + menu.id, {
                                        method: 'DELETE',
                                    })
                                        .then(res => res.json())
                                        .then(
                                            (data) => {
                                                setPage(1)
                                                setIsLoaded(true);
                                            },
                                            (error) => {
                                                setIsLoaded(true);
                                                setError(error);
                                            }
                                        )
                                }}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                        
                      </tr>
                      ))}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem >
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              let prevpage = +page - +1;
                              if(prevpage >= 1) setPage(prevpage)
                            }}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>

                        
                        {[...Array(numberPages)].map((x, i) =>
                            <PaginationItem key={i} className={`${page === (i+1) ? "active" : ""}`}>
                            {/* <PaginationItem className="active"> */}
                            <PaginationLink
                              onClick={(e) => { 
                                  setPage(e.target.innerText)
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        )}
                        <PaginationItem>
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              let nextpage = +page + +1;
                              console.log('nextpage: ' + nextpage)
                              if(nextpage <= numberPages) setPage(nextpage)
                            }}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
    </>
    )
  }
}

export default MenusTable