import React, { useState, useEffect } from 'react';
import {
Modal,
ModalHeader,
ModalBody,
Row,    
Col,
Label,
Input,
Dropdown,
DropdownToggle,
DropdownMenu,
DropdownItem,
Button,
ModalFooter,
} from 'reactstrap';
import { ReactSession } from 'react-client-session';
import toast from 'react-hot-toast';
import { getSchools } from '../../api';
import ApiClient from 'api/apiClient';

function EditStudentModal({
    isOpen,
    toggle,
    onSubmit, 
    schoolID,
    campusID,
    classroomID,
    gradeID,
    teacherID,
    firstName, 
    lastName,
}) {

    const [selectedSchool, setSelectedSchool] = useState(null);
    const [selectedCampus, setSelectedCampus] = useState(null);
    const [selectedClassroom, setSelectedClassroom] = useState(null);
    const [selectedGrade, setSelectedGrade] = useState(null);
    const [schoolYear, setSchoolYear] = useState(null);
    // State for input fields
    const [studentFirstName, setStudentFirstName] = useState(firstName);
    const [studentLastName, setStudentLastName] = useState(lastName);
    const [schools, setSchools] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const token = ReactSession.get("token");

    const [campuses, setCampuses] = useState([]);
    const [classrooms, setClassrooms] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [grades, setGrades] = useState([]);
  
    const [campusDropdownOpen, setCampusDropdownOpen] = useState(false);
    const [classroomDropdownOpen, setClassroomDropdownOpen] = useState(false);
    const [teacherDropdownOpen, setTeacherDropdownOpen] = useState(false);
    const [gradeDropdownOpen, setGradeDropdownOpen] = useState(false);
    
    const [selectedTeacher, setSelectedTeacher] = useState(null);

    const apiClient = new ApiClient(token);

    const fetchSchools = async () => {
        try {
            const schoolsRes = await apiClient.getSchoolsUnpaginated()
            if (schoolsRes.status === 200) setSchools(schoolsRes.data)

        } catch (error) {
            toast.error('Error getting schools')
        }
    }

    const fetchCampuses = async () => {
        try {
            const campusesRes = await apiClient.getCampuses(`?school_id=${selectedSchool.id}&year=${schoolYear}`)
            if (campusesRes.status === 200) setCampuses(campusesRes.data)
        } catch (error) {
            console.log(error)
            toast.error('Error getting campuses')
        }
    }

    const fetchClassrooms = async () => {
        let query = ''
        if (schoolYear == null) {
            query += `?campus=${selectedCampus.id}`
        } else {
          query += `?campus=${selectedCampus.id}&year=${schoolYear}`
        }
        try {
            const classroomsRes = await apiClient.getClassrooms(query)
            if (classroomsRes.status === 200) setClassrooms(classroomsRes.data)
        } catch (error) {
            console.log(error)
            toast.error('Error getting classrooms')
        }
    }

    const fetchGrades = async () => {
        let query = ''
        if (schoolYear == null) {
            query += `?school=${selectedSchool.id}`
        }  else {
            query += `?year=${schoolYear}&school=${selectedSchool.id}`
        }
        let res = await apiClient.getGrades(query)
        if (res.status === 200) {
            setGrades(res.data)
        }
    }

    const fetchTeachers = async () => {
        let query = ''
        if (schoolYear == null) {
            query += `?school=${selectedSchool.id}`
        }  else {
            query += `?year=${schoolYear}&school=${selectedSchool.id}`
        }
        let res = await apiClient.getTeachers(query)
        if (res.status === 200) {
            setTeachers(res.data)
        }
    }

    const getActiveSchoolYear = async () => {
        let storedSchoolYear = localStorage.getItem('selectedSchoolYear')
        storedSchoolYear = JSON.parse(storedSchoolYear)
        if (storedSchoolYear) {
          setSchoolYear(storedSchoolYear.id)
        }
    }

    const save = () => {
        let data = {
            firstName: studentFirstName,
            lastName: studentLastName,
            schoolId: selectedSchool.id,
            campusId: selectedCampus.id,
            classroomId: selectedClassroom.id,
            gradeId: selectedGrade.id,
            teacherId: selectedTeacher.id,
            allergies: [],
        }
        onSubmit(data)
        toggle()
    }


    useEffect(() => {
        fetchSchools();
        getActiveSchoolYear();
    }, [])

    useEffect(() => {
        if (selectedSchool) {
            fetchCampuses()
            fetchGrades()
            fetchTeachers()
        }
    }, [selectedSchool]);

    useEffect(() => {
        if (selectedCampus) {
            fetchClassrooms();
        }
    }, [selectedCampus])

    return (
        <>
          <Modal className="modal-dialog-centered" size="md" isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Edit Student</ModalHeader>
            <ModalBody>
              <Row className="mb-3">
                <Col>
                  <Label for="firstName">First Name:</Label>
                  <Input
                    type="text"
                    name="firstName"
                    id="firstName"
                    value={studentFirstName}
                    onChange={(e) => setStudentFirstName(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <Label for="lastName">Last Name:</Label>
                  <Input
                    type="text"
                    name="lastName"
                    id="lastName"
                    value={studentLastName}
                    onChange={(e) => setStudentLastName(e.target.value)}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                    <Col>
                        <Label for="schoolDropdown">School:</Label>
                        <br />
                        <Dropdown id="schoolDropdown" isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
                            <DropdownToggle caret>
                                {selectedSchool ? selectedSchool.name : 'Select a School'}
                            </DropdownToggle>
                            <DropdownMenu>
                                {schools.map((school) => (
                                    <DropdownItem key={school.id} onClick={() => setSelectedSchool(school)}>
                                        {school.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                    <Col>
                        <Label for="campusDropdown">Campus:</Label>
                        <br />
                        <Dropdown id="campusDropdown" isOpen={campusDropdownOpen} toggle={() => setCampusDropdownOpen(!campusDropdownOpen)} disabled={!selectedSchool}>
                            <DropdownToggle caret disabled={selectedSchool == null}>
                            {selectedCampus ? selectedCampus.name : 'Select Campus'}
                            </DropdownToggle>
                            <DropdownMenu>
                            {campuses.map((campus) => (
                                <DropdownItem key={campus.id} onClick={() => setSelectedCampus(campus)}>
                                {campus.name}
                                </DropdownItem>
                            ))}
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                    <Label for="classroomDropdown">Classroom:</Label>
                    <br />
                    <Dropdown id="classroomDropdown" isOpen={classroomDropdownOpen} toggle={() => setClassroomDropdownOpen(!classroomDropdownOpen)} disabled={!selectedCampus}>
                        <DropdownToggle caret disabled={selectedCampus == null}>
                        {selectedClassroom ? selectedClassroom.class_number : 'Select Classroom'}
                        </DropdownToggle>
                        <DropdownMenu>
                        {classrooms.map((classroom) => (
                            <DropdownItem key={classroom.id} onClick={() => setSelectedClassroom(classroom)}>
                            {classroom.class_number}
                            </DropdownItem>
                        ))}
                        </DropdownMenu>
                    </Dropdown>
                    </Col>
                    <Col>
                    <Label for="teacherDropdown">Teacher:</Label>
                    <br />
                    <Dropdown id="teacherDropdown" isOpen={teacherDropdownOpen} toggle={() => setTeacherDropdownOpen(!teacherDropdownOpen)} disabled={!selectedClassroom}>
                        <DropdownToggle caret>
                        {selectedTeacher ? selectedTeacher.first_name + ' ' + selectedTeacher.last_name : 'Select Teacher'}
                        </DropdownToggle>
                        <DropdownMenu>
                        {teachers.map((teacher) => (
                            <DropdownItem key={teacher.id} onClick={() => setSelectedTeacher(teacher)}>
                            {teacher.first_name} {teacher.last_name}
                            </DropdownItem>
                        ))}
                        </DropdownMenu>
                    </Dropdown>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                    <Label for="gradeDropdown">Grade:</Label>
                    <br />
                    <Dropdown id="gradeDropdown" isOpen={gradeDropdownOpen} toggle={() => setGradeDropdownOpen(!gradeDropdownOpen)} disabled={!selectedTeacher}>
                        <DropdownToggle caret disabled={selectedSchool == null}>
                            {selectedGrade ? selectedGrade.name : 'Select kids grade'}
                        </DropdownToggle>
                        <DropdownMenu>
                        {grades.map((grade) => (
                            <DropdownItem key={grade.id} onClick={() => setSelectedGrade(grade)}>
                            {grade.name}
                            </DropdownItem>
                        ))}
                        </DropdownMenu>
                    </Dropdown>
                    </Col>
                </Row>
                <ModalFooter>
                    <Button
                    color='success'
                    data-dismiss="modal"
                    onClick={() => {
                    save()
                    }}
                    >Save</Button>
                </ModalFooter>
              {/* You can continue adding other input fields and dropdowns here */}
            </ModalBody>
          </Modal>
        </>
      );
}


export default EditStudentModal;