
// reactstrap components
import {
Card,
CardHeader,
CardBody,
ListGroup,
ListGroupItem,
Button,
Modal,
Col,
Container,
Row,
Form,
} from "reactstrap";
// core components
import React, { useState, useEffect }  from 'react';
import UserHeader from "components/Headers/UserHeader.js";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { ReactSession } from 'react-client-session';

const TagsCategoriesTable = () => {
    const { handleSubmit, register, reset } = useForm();
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const [error, setError] = useState(null);
    const [categoryError, setCategoryError] = useState(null);
    const [createCategoryError, setCreateCategoryError] = useState(null);
    const [createTagError, setCreateTagError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    let [categories, setCategories] = useState([])
    let [tags, setTags] = useState([])
    let [catOnEdit, setCat] = useState([])
    let [tagOnEdit, setTag] = useState('')
    // Modal States
    let [categoryFormOpen, openCatsForm] = useState(false)
    let [tagFormOpen, openTagsForm] = useState(false)

    const token = ReactSession.get("token");
    // console.log('AllergiesTable token: ' + token)

    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }

    const editCategory = async (data, e) => {
        if(data.cat_name === undefined) return
        let catData = {
            name: data.cat_name,
            sort_order: data.cat_order
        }
        try{
            await axios.put(BASE_URL + `/categories/${catOnEdit}/edit` , catData, config)
            getCategories()
            setCat([])
            reset({ cat_name: ''})
        }catch(error){
            setCategoryError('editCategory: ' + error.message)
        }
    }

    const deleteCategory = async (catID) => {
        try{
            await axios.delete(BASE_URL + `/categories/${catID}/delete`, config )
            getCategories()
        }catch(error){
            setCategoryError('deleteCategory: ' + error.message)
        }
         
    }

    const toggleCategory  = async (catID) => {
        try{
            await axios.put(BASE_URL + `/categories/${catID}/toggle`, {}, config )
            getCategories()
        }catch(error){
            setCategoryError('toggleCategory: ' + error.message)
        }
         
    }

    const toggleTag  = async (tagID) => {
        try{
            await axios.put(BASE_URL + `/tags/${tagID}/toggle`, {}, config )
            getTags()
        }catch(error){
            setCategoryError('toggleTag: ' + error.message)
        }
         
    }

    const categoryFormHandling = async (formData) => {
        if (!formData.cat_name) {
            // Error handling
            return
        }
        try {
            let data = {name : formData.cat_name, sort_order: formData.cat_order, }
            await axios.post(BASE_URL + '/categories/create', data, config)
            openCatsForm(false)
            getCategories()
        } catch (error){
            setCreateCategoryError('categoryFormHandling: ' + error.message)
        }
        
    }

    const tagFormHandling = async (formData) => {
        if (!formData.tag_name || !formData.tag_initials) {
            // Error handling
            return
        }
        try {
            let data = {name: formData.tag_name, initials: formData.tag_initials}
            let res = await axios.post(BASE_URL + '/tags/create', data, config)
            openTagsForm(false)
            getTags()
            reset({ tag_name: ''})
        } catch(error) {
            setCreateTagError('tagFormHandling: ' + error.message)
        }
    }

    const getCategories = async () => {
        try {
          let res = await axios.get(BASE_URL + '/categories', config)
          let sortedCategories = res.data.sort((a, b) => {
            if (a.name < b.name) {
              return -1
            }
            if (a.name > b.name) {
              return 1
            }
            return 0
          })
          setCategories(sortedCategories)
        } catch (error) {
          setError('getCategories: ' + error.message)
          setCategories([])
        }
    }

    const getTags = async () => {
        try {
            let res = await axios.get(BASE_URL + '/tags', config)
            setTags(res.data)
        } catch (error) {
            setError('getTags: ' + error.message)
            setTags([])
        }
    }


    const editTag = async (data) => {
        try { 
            let tagData = {
                name: data.tag_name,
                initials: data.tag_initials
             }
             await axios.put(BASE_URL + `/tags/${tagOnEdit}/edit` , tagData, config)
            getTags()
            setTag('')
            reset({ name: '', initials: '' })
        } catch (error) {
            setError('edit error: ' + error.message)
            setTags([])
        }
    }
    useEffect(() => {
        getCategories()
        getTags()
        setIsLoaded(true)
    }, [])

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <>Loading...</>
    } else return (<>
    <UserHeader />
    <Container className='mt--7' fluid>
        <Row>
            <Col xl='6'>
                <Card className='shadow'>
                    <CardHeader className="bg-white border-0">
                        <Row className='justify-content-between'>
                            <h2 className='ml-4'>Categories</h2>
                            <Button size='sm' color='success' className='mr-4'
                             onClick={(e) => {
                                e.preventDefault()
                                openCatsForm(true)
                                }} >
                                Add
                            </Button>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div className="text-warning ml-3">{(categoryError) ? <p>{categoryError}</p> : ''}</div>
                        <Row className=''>
                            <Col className='ml-4'>Name</Col>
                            <Col className='ml-2'>Rank</Col>
                            <Col></Col>

                        </Row>

                        <ListGroup>
                        {categories.map((cat) => {
                            return  <ListGroupItem key={cat.id}> 
                                {catOnEdit === cat.id ? (
                                <Form onSubmit={handleSubmit(editCategory)} value={cat.id}>
                                    <Row className=''>
                                    <Col lg='5'><input className="form-control h-25" placeholder={cat.name}type='text' {...register('cat_name')} /></Col>
                                    <Col lg='2'><input className="form-control h-25" placeholder={cat.sort_order}type='text' {...register('cat_order')} /></Col>
                                    <Button size='sm' type='submit' color='success' className='mr-4 h-25' value={cat.id}> Save </Button> 
                                    </Row>
                                </Form>
                                )
                                : (
                                <Row className='justify-content-center'>
                                    <Col className='ml-2'>{cat.name}</Col>
                                    <Col className='ml-4'>{cat.sort_order}</Col>
                                    <Col >
                                    <Row > 
                                        {/* <Button size='sm' color='danger' className='mr-4' value={cat.id} onClick={(e) => deleteCategory(cat.id)} >Delete</Button> */}
                                        <Button size='sm' color='primary' className='mr-4' value={cat.id} onClick={(e) => toggleCategory(cat.id)} >
                                        {cat.status === 'active' ?  "Hide" : "Show"}
                                        </Button>
                                        <Button size='sm' color='success' className='mr-4' value={cat.id} onClick={(e) => setCat(cat.id)} >
                                            Edit
                                        </Button>
                                    </Row>
                                    </Col>
                                </Row>
                                )
                                }
                            </ListGroupItem>
                        })}
                        </ListGroup>
                    </CardBody>
                </Card>
            </Col>
            <br></br>
            <Col xl='6'>
                <Card className="bg-white border-0 shadow">
                    <CardHeader className="bg-white border-0">
                    <Row className='justify-content-between'>
                            <h2 className='ml-4'>Tags</h2>
                            <Button size='sm' color='success' className='mr-4' onClick={(e) => {
                                e.preventDefault()
                                openTagsForm(true)
                                }} >
                                Add
                            </Button>
                        </Row>
                    </CardHeader>
                    <CardBody>
                    <ListGroup>
                        {tags.map((tag) =>  {
                            console.log(tag)
                                return  <ListGroupItem key={tag.id}> 
                                   { tagOnEdit === tag.id ? (
                                          <Form onSubmit={handleSubmit(editTag)} value={tag.id}>
                                          <Row className='justify-content-between align-items-center'>
                                          <Row lg='8' className="jusitfy-content-between px-4">
                                            <input
                                            required
                                            className="form-control h-25 w-50 mx-2" placeholder={tag.name}type='text' {...register('tag_name')} />
                                            <input 
                                            required
                                            className="form-control h-25 w-25" placeholder={tag.initials} type='text' maxLength={2} {...register('tag_initials')} />
                                          </Row>
                                            <Button size='sm' type='submit' color='success' className='mr-4 h-25' value={tag.id}> Save </Button> 
                                          </Row>
                                      </Form>
                                   ) : <Row className='justify-content-between'>
                                    <Row className="pl-4">
                                        <span className='ml-4'>{tag.name}</span> 
                                        <span className='ml-4'>{tag.initials}</span> 
                                    </Row>
                                    <Row> 
                                            <Button size='sm' color={tag.status === true ?  "primary" : "danger"} className='mr-4' value={tag.id} onClick={(e) => toggleTag(tag.id)} >
                                            {tag.status === true ?  "Hide" : "Show"}
                                            </Button>
                                        <Button size='sm' color='success' className='mr-4' value={tag.id} onClick={(e) => setTag(tag.id)} >
                                                Edit
                                        </Button>
                                      </Row>
                                    </Row> }
                                </ListGroupItem>
                        })}
                    </ListGroup>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <Modal isOpen={categoryFormOpen}>
            <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                Create a Category
                </h3>
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => openCatsForm(false)}
                >
                <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="text-warning ml-3">{(createCategoryError) ? <p>{createCategoryError}</p> : ''}</div>

            <Form onSubmit={handleSubmit(categoryFormHandling)}>
                <div className="modal-body">
                    <Row className='align-items-center ml-3'>
                        <Col lg="8">
                            <label
                            className="form-control-label"
                            htmlFor="cat_name"
                            >
                            Name
                            </label>
                            <input
                            className="form-control"
                            id="cat_name"
                            placeholder="Name"
                            type="text"
                            {...register('cat_name')}
                            required
                            />
                        </Col>
                        <Col lg="4">
                            <label
                            className="form-control-label"
                            htmlFor="cat_order"
                            >
                            Rank
                            </label>
                            <input
                            className="form-control"
                            id="cat-order"
                            placeholder="1"
                            type="string"
                            title='Please use correct formatting'
                            {...register('cat_order')}
                            required
                            />
                        </Col>
                        </Row>
                        </div>
                        <div className="modal-footer">
                            <Button
                                color="secondary"
                                data-dismiss="modal"
                                type="button"
                                onClick={(e) => openCatsForm(false)}
                            >
                            Close
                            </Button>
                            <Button
                                color="primary"
                                data-dismiss="modal"
                                type="submit"
                                // onClick={(e) => openModsForm(false)}
                            >
                            Save
                            </Button>
                        </div>

                    {/*                         
                        <Col lg='4'>
                            <Button
                            className='mt-4'
                            color="primary"
                            data-dismiss="modal"
                            type="submit"
                            >
                            Save
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                </div> */}
            </Form>
        </Modal>
        <Modal isOpen={tagFormOpen}>
            <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                Create Tags
                </h3>
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => openTagsForm(false)}
                >
                <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="text-warning ml-3">{(createTagError) ? <p>{createTagError}</p> : ''}</div>

            <Form onSubmit={handleSubmit(tagFormHandling)}>
                <div className="modal-body">
                    <Row className='align-items-center ml-3'>
                        <Col lg="6">
                            <label
                            className="form-control-label"
                            htmlFor="cat_name"
                            >
                            Name
                            </label>
                            <input
                            className="form-control w-75"
                            id="cat_name"
                            placeholder="Name"
                            type="text"
                            {...register('tag_name')}
                            required
                            />
               
                        </Col>
                        <Col lg="6">
                        <label
                            className="form-control-label "
                            htmlFor="cat_name"
                            >
                            Initials
                            </label>
                            <input
                            className="form-control w-75"
                            id="tag_initials"
                            placeholder="Initials"
                            type="text"
                            {...register('tag_initials')}
                            required
                            />
                        </Col>
                        <Col lg='4'>
                            <Button
                            className='mt-4'
                            color="primary"
                            data-dismiss="modal"
                            type="submit"
                            >
                            Save
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                </div>
            </Form>
        </Modal>
    </Container>
    </>)
}

export default TagsCategoriesTable