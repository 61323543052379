import {
Card,
CardBody,
CardHeader,
Dropdown,
DropdownToggle,
Container, 
Row,
Form,
Col,
Table,
FormGroup,
Button,
Modal,
ListGroup,
ListGroupItem,
DropdownMenu,
DropdownItem,
UncontrolledDropdown,

} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect }  from 'react';
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import axios from 'axios';
import { DateTime } from 'luxon'
import { ReactSession } from 'react-client-session';
    

const OrderProfile = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL
    let [ error, setError] = useState([])
    let [ products, setProducts ] = useState([])
    let [ isLoaded, setLoad ] = useState(false)
    let [order, setOrder] = useState([])
    let [paymentMethod, setPayment] = useState([])
    let [meals, setMeals] = useState([])
    const { id } = useParams();

    const token = ReactSession.get("token");
    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }

    const getData = async () => {
        let res = await axios.get(BASE_URL + '/orders/' + id, config)
        if (res.status === 200) {
            setOrder(res.data)
            if (res.data.payment_method_id) {
                getOrderPaymentMethod(res.data.payment_method_id)
            }
            setMeals(res.data.meals)
            setLoad(true)
        }
    }

    const getOrderPaymentMethod = async (paymentMethodId) => {
        let res = await axios.get(BASE_URL + '/wallets/pm/' + paymentMethodId, config)
        if (res.status === 200) {
            setPayment(res.data)
        }
    }

    useEffect(() => {
        getData()
    }, [])
    if(!isLoaded) {
        return <>Loading...</>
    } else return (
        <>
        <UserHeader /> 
        <Container fluid className='mt--7'>
            <Row>
                <Col xl='8'>
                    {/* General Order info, cost, # meals,  school address, date */}
                    <Card className="card-profile shadow">
                        <CardHeader>
                            <Row>
                                <h3>Order Information</h3>
                            </Row>
                        </CardHeader>
                        <CardBody className="pt-0 pt-md-4">
                        <Col className=' align-items-center'>
                            <Row className='justify-content-center'>
                            <div className="card-profile-stats d-flex justify-content-center">
                                <div>
                                 <span className="heading">{order.meals.length}</span>
                                  <span className="description">Order Meals</span>
                                </div>
                                <div>
                                 <span className="heading">{order.payment_status}</span>
                                  <span className="description">Order Status</span>
                                </div>
                                <div>
                                <span className="heading">{order.promotion_id ?  <>See more...</> : <>None</>}</span>
                                  <span className="description">Promotion</span>
                                </div>
                            </div>
                            </Row>
                            <Row className='justify-content-center'>
                                <div className='card-profile-stats d-flex justify-content-center'>
                                    <div>
                                     <span className="heading">$ {order.total}</span>
                                     <span className="description">Total</span>
                                    </div>
                                    <div>
                                     <span className="heading">$ {order.sub_total}</span>
                                     <span className="description">Subtotal</span>
                                    </div>     
                                    <div>
                                     <span className="heading">$ {order.tax}</span>
                                     <span className="description">Tax Amount</span>
                                    </div>     
                                    <div>
                                     <span className="heading">$ {order.credit}</span>
                                     <span className="description">Credit</span>
                                    </div>
                                </div>
                            </Row>
                        </Col>
                        </CardBody>
                    </Card>
                    <br></br>
                    {/* Meals Table */}
                    <Card className="card-profile shadow">
                        <CardHeader>
                            <Row className='justify-content-between align-items-center'>
                                <h3 className="mb-0 ml-4">Meals</h3>
                            </Row>
                        </CardHeader>
                    <CardBody className="pt-0 pt-md-4">
                        {meals.length > 0 ?
                            <Table className="align-items-center table-flush" responsive>
                                <thead className='thead-light text-center'>
                                    <tr>
                                        <th>
                                            Product
                                        </th>
                                        <th>
                                            Receiver
                                        </th>
                                        <th>
                                            Delivery Date
                                        </th>
                                        <th>
                                            More
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {meals.map((meal) => <tr className='text-center'>
                                        <td>
                                        {meal.products.name}
                                        </td>
                                        <td>
                                        {meal.receiver_name}
                                        </td>
                                        <td>{Intl.DateTimeFormat('en-US').format(DateTime.fromSQL(meal.scheduled_for))}</td>
                                        {/* <td>{Intl.DateTimeFormat('en-US').format(new Date(Date.parse(meal.scheduled_for)))}</td> */}
                                        <td className="text-center">
                                    <UncontrolledDropdown>
                                        <DropdownToggle
                                        className="btn-icon-only text-light"
                                        href="#pablo"
                                        role="button"
                                        size="sm"
                                        color="success"
                                        onClick={(e) => e.preventDefault()}
                                        >
                                        <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-arrow" right>
                                        <DropdownItem
                                            tag={Link}
                                            to={"/admin/meal/" + meal.id}
                                        >
                                            View
                                        </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    </td>
                                    </tr>)}
                                </tbody>
                            </Table>
                         :  <span>No meals</span>}
           
                    </CardBody>
                    </Card>
                </Col>
                <Col xl='4'>
                    {/* Customer Card */}
                <Card className="card-profile shadow">
                    <CardBody className="pt-0 pt-md-4">
                    <div className="text-center">
                        <Row className='justify-content-between align-items-baseline'>
                            <h2 className='ml-2'>Customer</h2>
                            <h3 className='mr-2'>
                            {order.customer.first_name} {order.customer.last_name}
                            </h3>
                        </Row>
                            <div className='card-profile-stats d-flex justify-content-center'>
                                <div>
                                    <span className="heading">{order.customer.phone_number}</span>
                                    <span className="description">Phone Number</span>
                                </div>
                                <div>
                                    <span className="heading">{order.customer.role}</span>
                                    <span className="description">Role</span>
                                </div>     
                            </div>
                            <br></br>
                            <Row className='justify-content-left'>
                            <Link className='ml-4' to={`/admin/customer/${order.customer.id}`}>Visit Profile</Link>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
                <br></br>
                {/* Receiver info */}
                <Card className="card-profile shadow">
                    <CardBody className="pt-0 pt-md-4">
                    <div className="text-center">
                        <Row className='justify-content-between align-items-baseline'>
                            <h2 className='ml-2'>Payment Method Information</h2>
                        </Row>
                            <div className='card-profile-stats d-flex justify-content-center'>
                                <div>
                                    <span className="heading">{paymentMethod !== [] ? paymentMethod.brand : ''}</span>
                                    <span className="description">Brand</span>
                                </div>
                                <div>
                                    <span className="heading">{paymentMethod  !== [] ? paymentMethod.last4: ''}</span>
                                    <span className="description">Last 4</span>
                                </div>     
                            </div>
                            <br></br>
                   
                        </div>
                    </CardBody>
                </Card>
                </Col>
           
            </Row>
        </Container>
        </>
    )
}

export default OrderProfile