
// reactstrap components
import {
Card,
CardHeader,
CardFooter,
DropdownMenu,
DropdownItem,
UncontrolledDropdown,
DropdownToggle,
Col,
Button,
Dropdown,
// Pagination,
// PaginationItem,
// PaginationLink,
Table,
Container,
Row,
Form,
FormGroup,
Input,
Label,
InputGroup,
} from "reactstrap";
// core components
import React, { useState, useEffect }  from 'react';
import { Link } from "react-router-dom";
import UserHeader from "components/Headers/UserHeader.js";
import axios from 'axios';
import ResultsPerPage from "components/ResultsPerPage.js";
import { ReactSession } from 'react-client-session';
import { Pagination } from "@mui/material";


  const OrdersTable = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [orders, setOrders] = useState([]);
    const [orderCreatedAt, setOrderCreatedAt] = useState('desc');


    // let [total, setTotal] = useState('')
    let [createdAt, setCreatedAt] = useState('')
    let [customerEmail, setCustomerEmail] = useState('')
    let [customerName, setCustomerName] = useState('')
    
    let [customers, setCustomers] = useState([])
    let [selectedCustomer, setSelectedCustomer] = useState(null)
    let [customersOpen, setCustomersOpen] = useState(false)
    // let [customerSelected, setCustomerSelected] = useState(false)
    
    let [page, setPage] = useState(1);
    let [pageLimit, setPageLimit] = useState(25)
    let [numberPages, setNumberPages] = useState(null)
    const [schoolYear, setSchoolYear] = useState()
    const [allSchoolYears, setAllSchoolYears] = useState([])
    let [dateRange, setDateRange] = useState(true)
    let [scheduledFor, setDate] = useState('')
    let [scheduledTo, setToDate] = useState('')
    let [specificDate, setSpfDate] = useState('')
    const token = ReactSession.get("token");

    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }

    const getData = async () => {
      let dateQuery = dateRange ? `?range=${scheduledFor}*${scheduledTo}` : `?date=${specificDate}` 
      let query = `${dateQuery}&customer=${selectedCustomer ? selectedCustomer.id : ''}&name=${customerName}&page=${page}&limit=${pageLimit}&year=${schoolYear}&orderCreatedAt=${orderCreatedAt}`
      try{
        let res = await axios.get(BASE_URL + '/orders/admin' + query, config)
          setOrders(res.data.data)
          setNumberPages(res.data.meta.last_page)
          getSchoolYears()
          setIsLoaded(true)
      }catch(error){
        setError('getData: ' + error.message)
      }
    }


    const getSchoolYears = async () => {
      let res = await axios.get(BASE_URL + '/school_years/', config)
      if (res.status === 200) {
        setAllSchoolYears(res.data)
      }
    }

    // const selectCustomer = async (e) => { }


    const handlePageChange = (event, value) => {
      setPage(value);
    }

    async function fetchCustomersByName(name) {
    
      try {
        const response = await axios.get(BASE_URL + '/customers/unpaginated', {
          params: {
            name: name
          },
          headers: config.headers
        });
    
        if (response.data.error) {
          throw new Error(response.data.error);
        }
    
        setCustomers(response.data)
      } catch (error) {
        console.error('Error fetching customers:', error.message);
        return {
          error: error.message
        };
      }
    }
  
    useEffect(() => {
      getData()
      fetchCustomersByName(customerName)
      setIsLoaded(true)
    }, [page,
      pageLimit,
      customerEmail,
      createdAt,
      scheduledFor,
      scheduledTo,
      specificDate, 
      schoolYear,
      customerName,
      selectedCustomer,
      orderCreatedAt])

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
      return (
        <>
          <UserHeader />
          {/* Page content */}
          <Container className="mt--7" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className='justify-content-between align-items-center'>
                    <h3 className="ml-4">Orders</h3>
                          <div className="">
                              <ResultsPerPage pageLimit={pageLimit} setPageLimit={setPageLimit} page={page} setPage={setPage} rowAmount={orders.length}/>
                          </div>
                    </Row>
                  </CardHeader>
                  <Form>
                    <div className="pl-lg-4">
                      <Row>
                        <Col xl='2' className='pl-5 py-2'>
                                <Col>
                                  <Label check>
                                        <Input type="checkbox" onChange={() => { 
                                          setDateRange(!dateRange)
                                          setDate('')
                                          setToDate('')
                                          setSpfDate('')
                                        }} value={!dateRange}/>
                                        Single Date
                                  </Label>
                                </Col>
                        </Col>
                      </Row>
                      <Row className='align-items-baseline justify-content-center'>
                      { dateRange ? (<>
                                  <Col xl='4' className='pr-2'>
                                    <label className=" form-control-label" htmlFor="basic-url">
                                      From Date
                                    </label>
                                    <InputGroup>
                                    <Input
                                      id="exampleSelect"
                                      name="from date"
                                      type="date"
                                      placeholder='From date'
                                      value={scheduledFor}
                                      onChange = {e => {
                                        e.preventDefault()
                                        setDate(e.target.value)
                                      }}
                                      >
                                    </Input>
                                    </InputGroup>
                                  </Col>
                            <Col xl='4' className='pl-2'>
                                    <label className=" form-control-label" htmlFor="basic-url">
                                      To Date
                                    </label>
                                    <InputGroup>
                                      <Input
                                        id="exampleSelect"
                                        name="from date"
                                        type="date"
                                        placeholder='From date'
                                        value={scheduledTo}
                                        onChange = {e => {
                                          e.preventDefault()
                                          setToDate(e.target.value)
                                        }}
                                      >
                                      </Input>
                                    </InputGroup>
                            </Col>
                      </> ) :  <Col xl='8' className='p-0'>
                                  <Col>
                                    <label className=" form-control-label" htmlFor="basic-url">
                                    Created At
                                    </label>
                                    <InputGroup>
                                    <Input
                                      id="exampleSelect"
                                      name="from date"
                                      type="date"
                                      placeholder='From date'
                                      value={specificDate}
                                      onChange = {e => {
                                          e.preventDefault()
                                          setSpfDate(e.target.value)
                                      }}
                                    >
                                    </Input>
                                    </InputGroup>
                                  </Col> 
                                </Col>
                            }
                      </Row>
                            <br></br>
                        <Row  className='align-items-baseline justify-content-center'>
                    <Col xl='4'>
                      <label className=" form-control-label" htmlFor="created_at">
                        Order By
                      </label>
                      <InputGroup>
                        <Input
                        id="created_at"
                        type="select"
                        onChange = {e => {
                          setOrderCreatedAt(e.target.value)
                        }}
                        value={orderCreatedAt}
                        >
                          <option key='Newest' value='desc'>Newest</option>
                          <option key='Oldest' value='asc'>Oldest</option>
                      </Input>
                      </InputGroup>
                    </Col>
                    <Col xl='4'>
                    <label
                        className="form-control-label"
                        htmlFor="input-first-name"
                    >
                    Select a Customer    
                    </label>
                    <br></br>
                    <Dropdown
                    className="w-100"
                    toggle={() => setCustomersOpen(!customersOpen)} isOpen={customersOpen} flip={'true'}>
                       {/* <Row > */}
                            <DropdownToggle 
                            className={!selectedCustomer ? "btn-nopad" : ""}
                            >
                            {selectedCustomer ? <span>{selectedCustomer.first_name} {selectedCustomer.last_name}</span>:  <Input  onChange={(e) => setCustomerName(e.target.value)} placeholder="Search for a customer name" />}
                            </DropdownToggle>
                            {selectedCustomer && <Button onClick={() => {
                                setSelectedCustomer(null)
                                setCustomerName('')
                            }}><i className='ni ni-fat-remove'></i></Button>}
                        {/* </Row> */}
                        <DropdownMenu children>
                            {customers.slice(0,5).map((cust) => (
                            <div key={cust.id}>
                                <DropdownItem onClick={() => setSelectedCustomer(cust)}>{cust.first_name} {cust.last_name}</DropdownItem>
                             </div>
                            ))}
                            <div key={0}>
                                <DropdownItem onClick={() => {
                                  setSelectedCustomer(null)
                                  setCustomerName('')
                                  }}>Search to find more...</DropdownItem>
                            </div>
                        </DropdownMenu>
                    </Dropdown>
                    </Col>  
                      <br></br>
                      </Row>
                    </div>
                  </Form>
                  <br></br>
                  {/* <Row className='justify-content-center'>
                      <Col xl='8'><ResultsPerPage pageLimit={pageLimit} setPageLimit={setPageLimit} page={page} setPage={setPage} /></Col>
                  </Row> */}
                  <br></br>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr className='text-center'>
                        <th scope="col">ID</th>
                        <th scope="col">Status</th>
                        <th scope="col">Customer</th>
                        <th scope="col">Total</th>
                        <th scope="col"># of Meals</th>
                        {/* <th scope="col" onClick={(e) => {
                            e.preventDefault()
                            if(orderCreatedAt === 'asc' ) {setOrderCreatedAt('desc');}
                            else if(orderCreatedAt === 'desc' || orderCreatedAt === null) {setOrderCreatedAt('asc');}
                            setPage(1)
                          }}>Created At</th> */}
                        <th scope="col">Created At</th>
                        <th scope="col">More</th>
                      </tr>
                    </thead>
                      {
                      orders.length > 0  ? <>
                    <tbody>
                         {orders.map(order => (
                      <tr key={order.id} className='text-center'>
                          <td>{order.id} </td>
                          <td>{order.payment_status}</td>
                          <td><Link to={`/admin/customer/${order.customer_id}`}>{order.customer.first_name} {order.customer.last_name}</Link></td>
                          <td>$ {order.total}</td>
                          <td>{order.meals.length}</td>
                          <td>{Intl.DateTimeFormat('en-US').format(new Date(Date.parse(order.created_at)))}</td>
                          <td className="text-center">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color="success"
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow success" right>
                                <DropdownItem
                                  tag={Link}
                                  to={"/admin/orders/" + order.id}
                           
                                >
                                  View
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                      </tr>
                      ))
                    }   
                    </tbody>
                      </> : <tbody><tr className='justify-content-center'><td className='mt-3'>No orders on file</td></tr></tbody>
                       } 
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                     <Pagination count={numberPages} color="primary" onChange={handlePageChange}/>

                      {/* <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                      <PaginationItem>
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              let prevpage = +page - +1;
                              if(prevpage >= 1) setPage(prevpage)
                            }}
                            // tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>

                        
                        {[...Array(numberPages)].map((x, i) =>
                          <PaginationItem key={i} className={`${page === (i+1) ? "active" : ""}`}>
                            <PaginationItem className="active">
                            <PaginationLink
                              onClick={(e) => { 
                                  setPage(e.target.innerHTML)
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        )}
                        <PaginationItem>
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              let nextpage = +page + +1;
                              if(nextpage <= numberPages) setPage(nextpage)
                            }}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination> */}
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </>
      );
    }
  };
  
  export default OrdersTable;
  