import {
Card,
CardBody,
CardHeader,
DropdownToggle,
Container, 
Row,
Form,
Col,
ListGroup,
Table,
Button
} from "reactstrap";
import EditStudentModal from "components/Modals/EditStudentModal";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect }  from 'react';
import { useParams, Link, useHistory } from "react-router-dom";
import axios from "axios";
import { DateTime } from 'luxon'
import ApiClient from 'api/apiClient';
import toast from "react-hot-toast";
import { ReactSession } from 'react-client-session';

const StudentProfile = () => { 
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const {id} = useParams();
    let [error, setError] = useState(null);
    let [isLoaded, setIsLoaded] = useState(false);
    let [student, setUser] = React.useState([]);
    let [editStudentModal, setEditStudentModal] = useState(false);
    const  history  = useHistory()
    const token = ReactSession.get("token");

    const apiClient = new ApiClient(token);

    const getData = async () => {
        try {
            let res = await axios.get(BASE_URL + '/students/' + id )
            setUser(res.data)
            setIsLoaded(true)
        } catch(error) {
            setIsLoaded(true);
            setError(error);
        }
    }

    const updateStudent = async (data) => {
        try {
            let res = await apiClient.updateStudent(id, data)
            console.log(res)
            if (res.status === 200) {
                toast.success('Student Updated')
                window.location.reload()
            }
        } catch(error) {
            console.log(error)
            toast.error(JSON.stringify(error))
        }
    }

    useEffect(() => {
        getData()
    }, []);

    if(!isLoaded) {
        return (<> 
        <UserHeader></UserHeader>
        <Container fluid className="mt--7">
            Loading...
        </Container>
        </>)
    }
    else return (
    <>
    <UserHeader />
    <Container fluid  className="mt--7">
    <Row>
        {/* Profile Card */}
        {/* Parent Card */}
        {/* Some Customer Info */}
        <Col xl='6'>
             <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                        <Col xs="8">
                        <h3 className="mb-0">Student Information</h3>
                        </Col>
                        <Col xs="4" className="text-right">  {/* Add this new column for the Edit button */}
                            <Button className="btn btn-primary" onClick={() => setEditStudentModal(!editStudentModal)}>Edit</Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Form >
                        <div className="pl-lg-4">
                        <Row>
                            <Col lg="6">
                                <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                                >
                                First Name
                                </label>
                                <br></br>
                                <span>{student.first_name}</span>
                            </Col>
                            <Col lg="6">
                                <label
                                className="form-control-label"
                                htmlFor="product_price"
                                >
                                Last Name
                                </label>
                                <br></br>
                            <span>{student.last_name}</span>
                            </Col>
                            <br></br>
                         
                        </Row>
                        <br></br>
                        <Row>
                        <Col lg="6">
                                <label
                                className="form-control-label"
                                htmlFor="product_price"
                                >
                                Is Allowed to order?
                                </label>
                                <br></br>
                                <span>{student.has_permission_to_order}</span>
                            </Col>
                            <Col lg="4">
                            <label
                                className="form-control-label"
                                htmlFor="product_price"
                                >
                                Email
                            </label>     
                            <br></br>
                                <span>{student.customer ? student.customer.user.email : 'No Email'}</span>
                            </Col>
                        </Row>
                        <br></br>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        </Col>
        <Col>
            <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                    <Row className="justify-content-between align-items-baseline">
                        <h3 className="ml-4">Parent Info</h3>
                        <DropdownToggle
                            className="btn text-green mr-4"
                            role="button"
                            size="sm"
                            onClick={(e) => {
                                history.push(`/admin/customer/${student.parent.customer_id}`)
                                history.push(`/admin/customer/${student.parent.customer_id}`)
                            }}
                        >
                        <span>Visit Profile</span>
                        </DropdownToggle>
                    </Row>
                </CardHeader>
                <CardBody>
                    <div className="pl-lg-4">
                    <Row>
                        <Col lg="6">
                            <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                            >
                            First Name
                            </label>
                            <br></br>
                            <span>{student.parent.customer.first_name} </span>
                        </Col>
                        <Col lg="6">
                            <label
                            className="form-control-label"
                            htmlFor="product_price"
                            >
                            Last Name
                            </label>
                            <br></br>
                            <span>{student.parent.customer.last_name}</span>
                        </Col>
                        <br></br>
                    </Row>
                    <br></br>
                    <Row>
                        <Col lg="6">
                        <label
                            className="form-control-label"
                            htmlFor="product_price"
                            >
                            Email
                        </label>     
                        <br></br>
                        <span>{student.parent.customer.user.email} </span>
                        </Col>
                        <Col lg="6">
                        <label
                            className="form-control-label"
                            htmlFor="product_price"
                            >
                            Phone Number
                        </label>   
                        <br></br>
                        <span>{student.parent.customer.phone_number} </span>
                        </Col>
                    </Row>
                    <br></br>
                    </div>
            </CardBody>
            </Card>
        </Col>  
    </Row>
    <br></br>
    <Row>
        {/* School */}
        {/* Classroom */}
        {/* Grade */}
        {/* Campus */}
        {/* Allergies Card */}
    <Col xl='8'>
        <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <Row className="justify-content-between align-items-baseline">
                <h3 className="ml-4">School Info</h3>
                    <DropdownToggle
                        className="btn text-green mr-4"
                        role="button"
                        size="sm"
                        onClick={(e) => {
                            history.push(`/admin/schools/${student.school_id}`)
                        }}
                    >
                    <span>Visit Profile</span>
                    </DropdownToggle>
                </Row>
            </CardHeader>
            <CardBody>
                    <div className="pl-lg-4">
                        <Row>
                            <Col lg="5">
                                <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                                >
                                Name
                                </label>
                                <br></br>
                                <span>{student.school.name} -  {student.school.abbreviation}</span>
                            </Col>
                            <Col lg="4">
                                <label
                                className="form-control-label"
                                htmlFor="input-first-name"
                                >
                                Teacher
                                </label>
                                <br></br>
                                <span>{student.teacher.first_name} {student.teacher.last_name}</span>
                            </Col>
                            <Col md="2">
                                <label
                                 className="form-control-label"
                                 htmlFor="product_price"
                                 >
                                Campus
                                </label>
                                <br></br>
                                <span>{student.campus.name}</span>
                            </Col>
                        </Row>
                        <br></br>
                    <Row>
                    <Col lg="5">
                            <label
                            className="form-control-label"
                            htmlFor="product_price"
                            >
                            Address
                            </label>
                            <br></br>
                        <span>{student.school.address}</span>
                        </Col>
                        <Col lg="4">
                            <label
                            className="form-control-label"
                            htmlFor="product_price"
                            >
                            Grade
                            </label>
                            <br></br>
                            <span>{student.grade.name}</span>
                        </Col>
                        <Col lg="2">
                            <label
                                className="form-control-label"
                                htmlFor="product_price"
                                >
                            Classroom
                            </label>
                            <br></br>
                            <span>{student.classroom.class_number}</span>
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                    </Row>
                    </div>
            </CardBody>
        </Card>
    </Col>  
    <Col xl='4'>
        <Card className="shadow">
            <CardHeader className="bg-white border-0">
                <Row className="justify-content-between align-items-baseline">
                <h3 className="ml-4">Allergies</h3>
                </Row>
            </CardHeader>
            <CardBody>
                <div className="text-center">
                {student.allergies.length > 0  ?
                        <ListGroup>
                        {student.allergies.map((alg) => {
                        return<> 
                        <ListGroup key={alg.id}>
                            <Row className='justify-content-between' key={alg.id}>
                            <span className='ml-4'>{alg.name}</span>
                            </Row> 
                        </ListGroup>
                            <br></br>
                        </>
                        })}
                        </ListGroup>
                        : <span>This kid has no allergies</span>}
                </div>
            </CardBody>
        </Card>
    </Col>
    </Row>
    <br></br>
    <Row>
        <Col>
            <Card className="shadow">
                <CardHeader className="bg-white border-0">
                    <Row className="justify-content-between align-items-baseline">
                        <Col>
                            <h3 className="ml-4">Meal History</h3>
                        </Col>
                    </Row>
                </CardHeader>
            <CardBody>
                <div className='text-center'>
                {student.meals.length > 0 ?(<>
                <Table>
                <thead className="thead-light">
                    <tr className='text-center'>
                        <th scope="col">Total</th>
                        <th scope="col">Delivery Date</th>
                        <th scope="col">More</th>
                    </tr>
                </thead>
                    <tbody>
                        {student.meals.map((meal) => {
                           return  <tr>
                               <td>{meal.meal_total}</td>
                               <td>{Intl.DateTimeFormat('en-US').format(DateTime.fromSQL(meal.scheduled_for))}</td>
                               <td><Link to={`/admin/meal/${meal.id}`}>View Meal</Link></td>
                           </tr>
                        })}
                    </tbody>
                </Table>
                </>) : <span>No Order History available from student</span> }
                </div>
            </CardBody>
            </Card>
        </Col>
    </Row>
    {/* Orders */}
    {/* Modals */}
    </Container>

    <EditStudentModal 
       className="modal-dialog-centered"
       size="sm"
       toggle={() => setEditStudentModal(!editStudentModal)}
       isOpen={editStudentModal}
       firstName={student.first_name}
       lastName={student.last_name}
       schoolID={student.school_id}
       campusID={student.campus_id}
       classroomID={student.classroom_id}
       gradeID={student.grade_id}
       teacherID={student.teacher_id} 
       onSubmit={updateStudent}
    />
    </>
    )
}


export default StudentProfile