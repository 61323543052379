// reactstrap components
import {
    // Badge,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    Media,
    // Pagination,
    // PaginationItem,
    // PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    Form,
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Button,
    UncontrolledTooltip,
    Label,
    Modal
  } from "reactstrap";
  // core components
  import UsersTableHeader from "components/Headers/UsersTableHeader.js";
  import ResultsPerPage from "components/ResultsPerPage.js";
  import React, { useState, useEffect }  from 'react';
  import { Link } from "react-router-dom";
  import { useForm, Controller } from "react-hook-form";
  import { ReactSession } from 'react-client-session';
  import { Pagination } from "@mui/material";


  const AllergiesTable = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const { register, handleSubmit, reset, formState, control } = useForm();

    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    // Pagination Controls
    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(25);
    const [numberPages, setNumberPages] = useState(null);

    // Edit-Modal Control
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);
   
    // Get Allergy Data
    const [allergies, setAllergies] = useState([]);

    // Sorting
    const [orderId, setOrderId] = useState(null);
    const [orderName, setOrderName] = useState(null);


    const token = ReactSession.get("token");
    // console.log('AllergiesTable token: ' + token)

    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }

    const getData = () => {
      fetch(BASE_URL + "/allergy/?" + "page=" + page + "&limit=" + pageLimit + "&orderName=" + orderName + "&orderId=" + orderId, config)
      .then(res => res.json())
      .then(
          (data) => {
            if(data['error']){
              setIsLoaded(true);
              setError(data['error']);
            }else{
              // console.log(data.getUrlsForRange(1, data.lastPage))
              setIsLoaded(true);
              setAllergies(data.data);
              setNumberPages(data.meta.last_page)
            }

              
          })
     
    }

    useEffect(() => {
      getData()
      }, [page, pageLimit,orderName,orderId])

    // const resetSort = () => {
    //   setOrderId(null)
    //   setOrderName(null)
    // }
      
    const submitAddAllergyForm = (data, e) => {
    
        // e.preventDefault();

        // console.log(JSON.stringify(data));  
        
        fetch(BASE_URL + "/allergy/create", {
            method: 'POST',
            body: JSON.stringify(data),
            headers: { 'Content-Type': 'application/json' },
          })
            .then(res => res.json())
            .then(
                (data) => {
                    fetch(BASE_URL + "/allergy/?" + "page=" + page + "&limit=" + pageLimit, config)
                        .then(res => res.json())
                        .then(
                            (data) => {
                                console.log("updating list")
                                setIsLoaded(true);
                                setAllergies(data.data);
                                setNumberPages(data.meta.last_page)
                                reset({"name":""});

                            },
                            (error) => {
                                setIsLoaded(true);
                                setError(error);
                            }
                        )
                   
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            )

    }

    

    const handlePageChange = (event, value) => {
      setPage(value);
    };

    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

      return (
        <>
          <UsersTableHeader />
          {/* Page content */}
          <Container className="mt--7" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="justify-content-between">

                    <h3 className="mb-0 pl-3">Allergies</h3>
                    <Row>
                      <ResultsPerPage pageLimit={pageLimit} setPageLimit={setPageLimit} page={page} setPage={setPage} rowAmount={allergies.length}/>
                      <Form className="navbar-search  form-inline mr-3 d-none d-md-flex ml-lg-auto">
                        <FormGroup className="mb-0">
                          <InputGroup className="input-group-alternative">
                              <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                  <i className="fas fa-search" />
                              </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder="Search" name="query" type="text" onChange={e => {
                                  fetch(BASE_URL + "/allergy/?" + "page=" + page + "&limit=" + pageLimit +"&name=" + e.target.value, {
                                      method: 'GET',
                                      headers: config.headers,
                                  })
                                      .then(res => res.json())
                                      .then(
                                          (data) => {
                                              setIsLoaded(true);
                                              setAllergies(data.data);
                                              setNumberPages(data.meta.last_page)
                                              setPage(1)
                                              reset({"name":""});
                                          },
                                          (error) => {
                                              setIsLoaded(true);
                                              setError(error);
                                          }
                                      )
                              }}/>
                          </InputGroup>
                        </FormGroup>
                      </Form>
                    </Row>
                    
                </Row>
                  </CardHeader>
                  {/* Form to add allergy */}
                  <Row className='justify-content-between'>
                  <Col xs="5">
                    <Form id="allergy_form" onSubmit={handleSubmit(submitAddAllergyForm)}>
                      <div className="pl-lg-4">
                          <FormGroup>
                              <label
                              className="form-control-label"
                              htmlFor="input-username"
                              >
                              Allergy Form
                              </label>

                              <InputGroup>
                                  <Controller
                                      name="name"
                                      control={control}
                                      render={({ field }) => <Input {...field} />}
                                  />
                                  <Button type="submit" color="success" >
                                  Add
                                  </Button>
                              </InputGroup>
                              
                          </FormGroup>
                      </div>
                    </Form>
                  </Col>
                  </Row>
                  {/* Form to filter results by query */}
                  <Row>
                    <Col></Col>
                  </Row>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light text-center">
                      <tr>
                        <th scope="col" onClick={(e) => {
                            e.preventDefault()
                            setOrderName(null)
                            if(orderId === 'asc' ) {setOrderId('desc');}
                            else if(orderId === 'desc' || orderId === null) {setOrderId('asc');}
                            setPage(1)
                          }}>ID</th>
                        <th scope="col" onClick={(e) => {
                            e.preventDefault()
                            setOrderId(null)
                            if(orderName === 'asc' ) {setOrderName('desc');}
                            else if(orderName === 'desc' || orderName === null) {setOrderName('asc');}
                            setPage(1)
                            
                          }}>Name</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                    {allergies.map(allergy => (
                    <tr key={allergy.id} className='text-center' >
                        <td className='text-center'>
                            <span className="mb-0 text-sm">
                              {allergy.id}
                            </span>
                        </td>
                        <td>{allergy.name}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              color="success"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                // href="#pablo"
                                // onClick={(e) => e.preventDefault()}
                                onClick={() => {
                                    setModalData(allergy); 
                                    setModalOpen(!modalOpen)}}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                // href="#pablo"
                                onClick={(e) => {
                                    fetch(BASE_URL + "/allergy/" + allergy.id, {
                                        method: 'DELETE',
                                    })
                                        .then(res => res.json())
                                        .then(
                                            (data) => {
                                                setPage(1)
                                                setIsLoaded(true);
                                                getData()
                                            },
                                            (error) => {
                                                setIsLoaded(true);
                                                setError(error);
                                            }
                                        )
                                }}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                        
                      </tr>
                      ))}
                      <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            toggle={() => setModalOpen(!modalOpen)} 
                            isOpen={modalOpen} 
                            >
                            <div className="modal-body p-0">
                                <Card className="bg-secondary shadow border-0">
                               
                                <CardBody className="px-lg-5 py-lg-5">
                                    
                                    <Form role="form" onSubmit={handleSubmit(data => {
                                        fetch(BASE_URL + "/allergy/" + modalData.id + "/edit", {
                                            method: 'PUT',
                                            body: JSON.stringify({name: data.newName}),
                                            headers: { 'Content-Type': 'application/json' },
                                          })
                                            .then(res => res.json())
                                            .then(
                                                (data) => {
                                                    fetch(BASE_URL + "/allergy/?" + "page=" + page + "&limit=" + pageLimit, config)
                                                        .then(res => res.json())
                                                        .then(
                                                            (data) => {
                                                                console.log("updating list")
                                                                setIsLoaded(true);
                                                                setAllergies(data.data);
                                                                setNumberPages(data.meta.last_page)
                                                                reset({"name":""});
                                                                setModalOpen(!modalOpen)
                                                                // e.target.reset();
                                
                                                            },
                                                            (error) => {
                                                                setIsLoaded(true);
                                                                setError(error);
                                                            }
                                                        )
                                                   
                                                    setIsLoaded(true);
                                                },
                                                (error) => {
                                                    setIsLoaded(true);
                                                    setError(error);
                                                }
                                            )
                                    })}>
                                    <FormGroup>
                                        <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                        >
                                        Edit {modalData.name}
                                        </label>

                                        <InputGroup>
                                            <Controller
                                                name="newName"
                                                control={control}
                                                render={({ field }) => <Input {...field}  placeholder={modalData.name} />}
                                               
                                            />
                                            <Button type="submit" color="success" >
                                            Update
                                            </Button>
                                        </InputGroup>
                                        
                                    </FormGroup>
                                    
                                    
                                    </Form>
                                </CardBody>
                                </Card>
                            </div>
                            </Modal>
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <nav aria-label="...">
                    <Pagination count={numberPages} color="primary" onChange={handlePageChange}/>
                      {/* <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem >
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              let prevpage = +page - +1;
                              if(prevpage >= 1) setPage(prevpage)
                            }}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>

                        
                        {[...Array(numberPages)].map((x, i) =>
                            <PaginationItem key={i} className={`${page==(i+1) ? "active" : ""}`}>
                            <PaginationItem className="active">
                            <PaginationLink
                              onClick={(e) => { 
                                  setPage(e.target.innerText)
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        )}
                        <PaginationItem>
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => {
                              e.preventDefault();
                              let nextpage = +page + +1;
                              if(nextpage <= numberPages) setPage(nextpage)
                            }}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination> */}
                    </nav>
                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </>
      );
    }
  };
  
  export default AllergiesTable;
  