

import UsersTableHeader from "components/Headers/UsersTableHeader.js"
import {
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    Col,
    Button,
    Modal
  } from "reactstrap"
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import ResultsPerPage from "components/ResultsPerPage.js";
import Skeleton from 'react-loading-skeleton'
import axios from 'axios'
import { ReactSession } from 'react-client-session';
import { Link } from "react-router-dom";

const SchoolGradesTable = () => {
    let { id } = useParams()
    let [ grades, setGrades ] = useState([])
    let [ isLoading, setLoad ] = useState(true)
    const BASE_URL = process.env.REACT_APP_BASE_URL

    const [schoolYear, setSchoolYear] = useState("");
    const [gradeName, setName] = useState("");
    const [gradeID, setGradeID] = useState("");
    const [address, setAddress] = useState("");

    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(25);
    const [numberPages, setNumberPages] = useState(null);
    const [schoolYears, setSchoolYears] = useState([]);
    const { register, handleSubmit, reset, formState, control } = useForm();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState([""]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const token = ReactSession.get("token");

    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }

    const getData = async () => {
        let params = + "page=" + page + "&limit=" + pageLimit + "&school_year_id=" + schoolYear + "&name=" + gradeName   +  "&id=" + gradeID + "&address=" + address 
        let data = await axios.get(BASE_URL  + '/schools/' + id + '/grades/?' + params)
        if (data.status === 200) {
            setGrades(data.data.data)
            setNumberPages(data.data.meta.last_page)
            setLoad(false)
        }
    } 


    const getSchoolYears = async () => {
      let res = await axios.get(BASE_URL + '/school_years/', config)
      if (res.status === 200) {
        setSchoolYears(res.data)
      }
    }

    useEffect(() => {
        getData()
        getSchoolYears()
    }, [page, pageLimit, schoolYear, gradeName, gradeID, address])
    return (
        <>
        <UsersTableHeader />
        <Container className="mt--7" fluid>
        <Card>
        <CardHeader>
              <Row className='align-items-center justify-content-between'>
              <h1 className="ml-4 mr-4">School Grades</h1>
                {/* <Row className='pr-4'>
                  <Button 
                  color="success"
                  className='success' tag={Link} to='/admin/grades/create'>Create</Button>
                </Row> */}
              </Row>
            </CardHeader>
            <br></br>
            <Form>
                    <div className="pl-lg-4">
                        <Row>
                            {/* <Col > */}
                                <FormGroup row className="col">
                                    {/* <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        School Year
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="schoolYear"
                                        type="select"
                                        value={schoolYear}
                                        onChange = {e => {
                                          setSchoolYear(e.target.value)
                                          setPage(1)
                                        }}
                                        >
                                          <option disabled value="">
                                              Select a School Term
                                          </option>
                                        {schoolYears.map(year => (
                                          <option key={year.id} value={year.id}>
                                              {year.name}
                                          </option>
                                        ))}

                                        </Input>
                                      </InputGroup>
                                    </Col>   */}
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Name
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="gradeName"
                                        type="text"
                                        placeholder="Enter Grade Name"
                                        value={gradeName}

                                        onChange = {e => {
                                          setName(e.target.value)
                                        }}
                                        />
                                      </InputGroup>
                                    </Col>
                                    <Col>
                                    <label> &nbsp; </label>
                                      <ResultsPerPage pageLimit={pageLimit} setPageLimit={setPageLimit} page={page} setPage={setPage}/>
                                    </Col>
                                    {/* <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        ID
                                      </label>
                                      <InputGroup>
                                        <Input
                                            id="exampleSelect"
                                            name="id"
                                            type="number"
                                            placeholder="Enter Teacher ID"
                                            value={gradeID}

                                            onChange = {e => {
                                             setGradeID(e.target.value)
                                            }}
                                        />
                                    
                                      </InputGroup>
                                    </Col>   */}
                                </FormGroup>
                            {/* </Col>   */}
                        </Row>
                    </div>
                  </Form>
            <CardBody>
            {
            isLoading ?
            <>
             <Skeleton count={5}/>
            </>
            :
             <Table>
                <thead className='thead-light'>
                    <tr className='text-center'>
                    <th>ID</th>
                    <th>Name</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    {grades.map((grade) => (
                         <tr className='text-center'>
                            <td>
                                {grade.id}
                            </td>
                            <td>{grade.name}</td>
                            <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              color="success"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={() => {
                                    setModalData(grade); 
                                    setModalOpen(!modalOpen)}}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => {
                                  setModalData(grade)
                                    fetch(BASE_URL  + '/grades/' + grade.id + "/edit", {
                                      method: 'PUT',
                                      body: JSON.stringify({isDeleted: true}),
                                      headers: { 'Content-Type': 'application/json' },
                                    })
                                        .then(res => res.json())
                                        .then(
                                            (data) => {
                                                setPage(1)
                                                setIsLoaded(true);
                                                getData()
                                            },
                                            (error) => {
                                                setIsLoaded(true);
                                                setError(error);
                                            }
                                        )
                                }}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                        </tr>
                    ))}
                    <Modal
                            className="modal-dialog-centered"
                            size="sm"
                            isOpen={modalOpen} 
                            >
                            <div className="modal-body p-0">
                                <Card className="bg-secondary shadow border-0">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <Form role="form" onSubmit={handleSubmit(data => {
                                        fetch(BASE_URL  + '/grades/' + modalData.id + "/edit", {
                                            method: 'PUT',
                                            body: JSON.stringify({name: data.newName}),
                                            headers: { 'Content-Type': 'application/json' },
                                          })
                                          .then(res => res.json())
                                            .then(
                                                (data) => {
                                                    fetch(BASE_URL + '/schools/' + id + '/grades/?' + "page=" + page + "&limit=" + pageLimit + "&school_year_id=" + schoolYear + "&name=" + gradeName   +  "&id=" + gradeID + "&address=" + address, config)
                                                        .then(res => res.json())
                                                        .then(
                                                            (data) => {
                                                              setGrades(data.data);
                                                              setIsLoaded(true);
                                                              reset({"name":""});
                                                              setModalOpen(!modalOpen);
                                                            },
                                                            (error) => {
                                                                setIsLoaded(true);
                                                                setError(error);
                                                            }
                                                        )
                                                    setIsLoaded(true);
                                                },
                                                (error) => {
                                                    setIsLoaded(true);
                                                    setError(error);
                                                }
                                            )
                                    })}>
                                      <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                        >
                                        Edit Grade: {modalData.name}
                                        </label>
                                        <div className="modal-body">
                                          <Row>
                                            <Col>
                                              <label
                                              className="form-control-label"
                                              htmlFor="name"
                                              >
                                              Grade
                                              </label>
                                              <Controller
                                                name="newName"
                                                control={control}
                                                render={({ field }) => <Input {...field}  placeholder={modalData.name} />}
                                            />
                                            </Col>
                                          </Row>
                                        </div>
                                        <div className="modal-footer">
                                            <Button
                                                color="secondary"
                                                data-dismiss="modal"
                                                type="button"
                                                onClick={(e) => setModalOpen(!modalOpen)}
                                            >
                                            Close
                                            </Button>
                                            <Button
                                                color="primary"
                                                data-dismiss="modal"
                                                type="submit"
                                            >
                                            Save
                                            </Button>
                                        </div>
                                    </Form>
                                </CardBody>
                                </Card>
                            </div>
                            </Modal>
                </tbody>
            </Table>
               }
            </CardBody>
            <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem className="disabled">
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>

                        
                        {[...Array(numberPages)].map((x, i) =>
                            <PaginationItem key={i} className={`${page === (i+1) ? "active" : ""}`}>
                            {/* <PaginationItem className="active"> */}
                            <PaginationLink
                              onClick={(e) => { 
                                  setPage(e.target.innerHTML)
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        )}

                
                        <PaginationItem>
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                   </CardFooter>
        </Card>
        </Container>
        </>
    ) 
}

export default SchoolGradesTable