/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { useState, useEffect } from 'react';
import { ReactSession } from 'react-client-session';
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";


const Login = () => {
  const { handleSubmit, register } = useForm();
  const BASE_URL = process.env.REACT_APP_BASE_URL
  let  [error, setError] = useState(false)
  let  [errorMessage, setErrorMessage] = useState('')
  let [loginToken, setToken] = useState('')
  const  history  = useHistory()



  const login = async (data, e ) => {
   try { let loginData = {
      email: data.email,
      password: data.password,
    }
   let res =  await axios.post(BASE_URL + '/users/admin/login', loginData)
    if (res.status === 200) {
      ReactSession.set('token', res.data.token)
      // setToken(res.data.token)
      
      history.push("/admin/index")
    }} catch (error) {
      setError(error)
      setErrorMessage("There's been a problem while you were loggin in")
    }
  }

  useEffect(() => {
    // const token = ReactSession.get("token");
    // if(!loginToken){
    //   setToken(token)
    // }
    // document.addEventListener('touchstart', login, {passive: true});
    console.log('mounting')
  }, [])
  
  // if (loginToken){
  //   console.log('redi')
  //   return <Redirect to="/admin/meals" />
  // }
  return (
    <>
    
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>Sign In</small>
            </div>
            <p className="text-danger text-center">{error ? errorMessage : ''} </p> 
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            
            <Form role="form"  onSubmit={handleSubmit(login)} id="login-form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                      className="form-control"
                      placeholder="Email"
                      defaultValue=""
                      type="email"
                      id="email"
                      {...register('email')}
                      required
                    />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input 
                      className="form-control"
                      placeholder="Password"
                      type="password"
                      id="password"
                      defaultValue=""
                      {...register('password')}
                      required
                    />
                </InputGroup>
              </FormGroup>
              {/* <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Remember me</span>
                </label>
              </div> */}
              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          {/* <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="/auth/register"
              // onClick={(e) => e.preventDefault()}
            >
              <small>Create new account</small>
            </a>
          </Col> */}
        </Row>
      </Col>
    </>
  );
};

export default Login;
