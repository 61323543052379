import {
Card,
CardBody,
CardHeader,
Container, 
Row,
Col,
Button,
Modal,
} from "reactstrap";
    // core components
import UserHeader from "components/Headers/UserHeader.js";
import React, { useState, useEffect }  from 'react';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { DateTime } from 'luxon'
import EditMealModal from "components/Modals/EditMealModal";
import toast from 'react-hot-toast';

const MealProfile = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const { id } = useParams();
    let [ error, setError ] = useState(null);
    let [ isLoaded, setIsLoaded ] = useState(false);
    let [ meal, setMeal ] = React.useState([]);
    let [modalOpen, setModalOpen] = useState(false);
    let [editMealModal, setEditMealModal] = useState(false);

    const token = ReactSession.get("token");
    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }

    const getData = async () => {
        try {
            let res = await axios.get(BASE_URL + '/meals/' + id)
            setMeal(res.data);
            setIsLoaded(true);
        } catch(error) {
            setError(error)
        }
    }

    const cancelMeal = async () => {
        try {
            setIsLoaded(false)
            await axios.post(BASE_URL + '/meals/cancel', { mealID: meal.id }, config)
            getData()
            setModalOpen(false)
            setIsLoaded(true);
        } catch(error) {
            setError(error)
            setIsLoaded(true);
        }
    }


    const editMeal = async (data) => {
        toast.loading('Saving...', {
            duration: 1000, 
        })
        try {      
            let res = await axios.put(BASE_URL + `/meals/${meal.id}/edit/admin`, { data }, config)
            if (res.status === 200) {
                toast.success('Meal updated successfully')
                getData()
                setEditMealModal(false)
            } else {
                toast.error('Something went wrong')
            }
        } catch (error) {
            toast.error('Something went wrong')
        }
    }

    useEffect(() => { 
        getData()
    }, [])

    if(!isLoaded) {
        return (<>
        Loading...
        </>)
    } else return (
        <>
        <UserHeader></UserHeader>
        <Container fluid className='mt--7'>
            <Row>
                <Col xl='8'>
                <Card className="card-profile shadow">
                    <CardHeader>
                        <Row className='justify-content-between'>
                             <h3 className='ml-2'>Meal Information</h3>
                             <div>
                                {meal.status === 'canceled' ? <h4>Order Canceled</h4> : <Button onClick={() => setModalOpen(true)}> Cancel </Button>}
                                <Button onClick={() => setEditMealModal(!editMealModal)}>Edit Delivery Info</Button>
                             </div>
                        </Row>
                    </CardHeader>
                    <CardBody className="pt-0 pt-md-4">
                        <Col className='align-items-center'>
                            <Row className='justify-content-center'>
                                <div>
                                <div className="card-profile-stats d-flex justify-content-center">
                                    <div>
                                    <span className="heading">{meal.receiver_name}</span>
                                    <span className="description">Receiver</span>
                                    </div>
                                    <div>
                                    <span className="heading">{!meal.receiver_role ? 'unspecified' : meal.receiver_role}</span>
                                    <span className="description">Role</span>
                                    </div>
                                    <div>
                                    <span className="heading">{meal.school.name ?? "Not provided"}</span>
                                    <span className="description">School</span>
                                    </div>
                                </div>
                                <div className="card-profile-stats d-flex justify-content-center">
                                    <div>
                                    <span className="heading">${meal.meal_total}</span>
                                    <span className="description">Total</span>
                                    </div>
                                    <div>
                                    <span className="heading">{Intl.DateTimeFormat('en-US').format(DateTime.fromSQL(meal.scheduled_for))}</span>
                                    <span className="description">Delivery Date</span>
                                    </div>
                                    <div>
                                    <span className="heading">{meal.meal_count}</span>
                                    <span className="description">Daily Meal Count</span>
                                    </div>
                                </div>
                                </div>
                            </Row>
                        </Col>
                    </CardBody>
                </Card>
                <br></br>
                <Card className="card-profile shadow">
                    <CardHeader>
                        <Row>
                            <h3 className='ml-2'>Lunchbag</h3>
                        </Row>
                    </CardHeader>
                    <CardBody className="pt-0 pt-md-4">
                        <Col className='align-items-center'>
                            <Row className='justify-content-center'>
                                <div className="card-profile-stats d-flex justify-content-center">
                                    <div>
                                    <span className="heading">{meal.products.name}</span>
                                    <span className="description">Product</span>
                                    </div>
                                </div>
                                <div className="card-profile-stats d-flex justify-content-center">
                                    <div>
                                    <span className="heading">{meal.drink.name}</span>
                                    <span className="description">Drink</span>
                                    </div>
                                    <div>
                                    <span className="heading">{meal.snack.name}</span>
                                    <span className="description">Snack</span>
                                    </div>
                                </div>
                            </Row>
                            <Row className='justify-content-center'>
                                <div className="card-profile-stats d-flex justify-content-center">
                                {meal.modifiers.map((modifier) => modifier.name === 'Default' ? <p>this is default</p> :
                                      <div>
                                          <span className="heading">{modifier.name} ${modifier.price}</span>
                                          <span className="description">Modifier</span>
                                      </div>
                                    )}
                                </div>
                            </Row>
                        </Col>
                    </CardBody>
                </Card>
                </Col>
                <Col xl='4'>
                <Card className="card-profile shadow">
                    <CardBody className="pt-0 pt-md-4">
                    <div className="text-center">
                        <Row className='justify-content-between align-items-baseline'>
                            <h2 className='ml-2'>Order Info</h2>
                            <h3 className='mr-2'>
                            </h3>
                        </Row>
                            <div className='card-profile-stats d-flex justify-content-center'>
                                <div>
                                    <span className="heading">$ {meal.order.total}</span>
                                    <span className="description">Total</span>
                                </div>
                                <div>
                                    <span className="heading">{Intl.DateTimeFormat('en-US').format(DateTime.fromISO(meal.order.created_at))}</span>
                                    <span className="description">Placed on</span>
                                </div>     
                            </div>
                            <br></br>
                            <Row className='justify-content-left'>
                            <Link className='ml-4' to={`/admin/orders/${meal.order.id}`}>See more</Link>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
                <br></br>
                <Card className="card-profile shadow">
                    <CardBody className="pt-0 pt-md-4">
                        <div className="text-center">
                            <Row className='justify-content-between align-items-baseline'>
                                <h2 className='ml-2'>Extra Items</h2>
                                <h3 className='mr-2'>
                                </h3>
                            </Row>
                            <div className='card-profile-stats d-flex justify-content-center'>
                                <p>
                                    <ul>

                                    {meal.extraItems.length > 0 ? meal.extraItems.map((meal) => {
                                    return <li>{meal.name}</li>
                                    }) : <span>No Extra item in this meal</span>}
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <br></br>
                <Card className="card-profile shadow">
                    <CardBody className="pt-0 pt-md-4">
                        <div className="text-center">
                            <Row className='justify-content-between align-items-baseline'>
                                <h2 className='ml-2'>Instructions</h2>
                                <h3 className='mr-2'>
                                </h3>
                            </Row>
                            <div className='card-profile-stats d-flex justify-content-center'>
                                <p>
                                    {meal.instructions}
                                </p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <br></br>
                <Card className="card-profile shadow">
                    <CardBody className="pt-0 pt-md-4">
                    <div className="text-center">
                        <Row className='justify-content-between align-items-baseline'>
                            <h2 className='ml-2'>Message</h2>
                            <h3 className='mr-2'>
                            </h3>
                        </Row>
                        <div className='card-profile-stats d-flex justify-content-center'>
                            <p>
                                {meal.message}
                            </p>
                        </div>
                    </div>
                    </CardBody>
                </Card>
                </Col>
            </Row>
        </Container>
        <Modal
        className="modal-dialog-centered"
        size="sm"
        toggle={() => setModalOpen(!modalOpen)} 
        isOpen={modalOpen} 
        >
            <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                <CardHeader>
                    <h2>
                    Cancelling order
                    </h2>
                </CardHeader>
                <CardBody className="px-lg-4 py-lg-5">
                    <div>
                        <p>
                        Are you sure you want to cancel this order?
                        </p>
                        <Row className='justify-content-center py-sm-3'>
                       <Button onClick={() => setModalOpen(false)}>Go Back</Button> <Button onClick={() => cancelMeal() } color='primary'>Cancel</Button>
                        </Row>
                    </div>
                </CardBody>
                </Card>
            </div>
        </Modal>

        <EditMealModal
            className="modal-dialog-centered"
            size="sm"
            toggle={() => setEditMealModal(!editMealModal)} 
            isOpen={editMealModal}
            gradeID={meal.grade_id}
            schoolID={meal.school_id}
            campusID={meal.campus_id}
            classroomID={meal.classroom_id}
            schoolYearID={meal.year_id}
            onSubmit={editMeal}
        />
     
        </>
    )
}

export default MealProfile