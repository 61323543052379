import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  Table,
  Container,
  Row,
  Form,
  Col,
  FormGroup,
  Input,
  InputGroup,
  // InputGroupAddon,
  Button,
  Modal,
} from "reactstrap";
// core components
import UsersTableHeader from "components/Headers/UsersTableHeader.js";
import React, { useState, useEffect }  from 'react';
import { Link } from "react-router-dom";
import ResultsPerPage from "components/ResultsPerPage.js";
import { useForm, Controller } from "react-hook-form";
import CSVReader from 'react-csv-reader'
import axios from 'axios'
import ReactLoading from 'react-loading';
import { ReactSession } from 'react-client-session';
import { Checkbox, Pagination } from "@mui/material";
import removeDuplicateCampuses from "helpers/removeDuplicateCampuses";


  const SchoolsTable = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const {  reset  } = useForm();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [schoolYears, setSchoolYears] = useState([]);
    const [schools, setSchools] = useState([]);
    const [foundSchool, setFoundSchool] = useState(false)
    const [school, setSchool] = useState(false)

    const [schoolYear, setSchoolYear] = useState("");
    const [schoolName, setSchoolName] = useState("");
    const [abbreviation, setAbbreviation] = useState("");
    const [address, setAddress] = useState("");
    const [weeklyOrders, setWeeklyOrders] = useState("");

    let [page, setPage] = useState(1);
    let [pageLimit, setPageLimit] = useState(25);
    let [numberPages, setNumberPages] = useState(null);
    let [modalOpen, setModal] = useState(false)
    let [importError, setImportError] = useState('')
    let [invalidFile, setInvalidFile] = useState(false)
    let [schoolsImport, setSchoolImport] = useState([])
    let [loadingFile, setLoadingFile] = useState(false)
    let [showHiddenSchools, setShowHiddenSchools] = useState(false)

    const token = ReactSession.get("token");

    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }
    const handlePageChange = (event, value) => {
      setPage(value);
    }
    const getSchools = async () => {
      try {
        
        let queryString =  "page=" + page + "&limit=" + pageLimit + "&school_year_id=" + schoolYear + "&name=" + schoolName + "&abbreviation=" + abbreviation + "&address=" + address + "&has_weekly_orders=" + weeklyOrders + "&isDeleted=" + showHiddenSchools
        let res = await axios.get(BASE_URL + "/schools/?" + queryString, config)
        if(res.status === 200) {
          setIsLoaded(true);
          setSchools(res.data.data);
          setNumberPages(res.data.meta.last_page)
        }
      } catch(error) {
        setIsLoaded(true);
        setError('getSchools: ' + error.message);
      }
    }
    useEffect(() => {
      getSchools()
    }, [page, pageLimit, schoolYear, schoolName, abbreviation, address, weeklyOrders, showHiddenSchools])
      
    // get the school years
    useEffect(() => {
        fetch(BASE_URL + "/school_years", config)
            .then(res => res.json())
            .then(
                (data) => {
                    setIsLoaded(true);
                    setSchoolYears(data);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error.message);
                }
            )
      }, [])


    const parseSchoolData =  (data, fileInfo) => {
      if (fileInfo.type !== "text/csv") {
        // handle invalid file
        // Maybe a prettier alert
        window.alert('Only CSV files are currently supported.')
        setInvalidFile(true)
        return
      } else {
        setInvalidFile(false)
        let campusInfo = {
          name: '',
          address: '',
          state: '',
          city: '',
          zipCode: '',
          classrooms: []
        }
   
        let schoolInfo = {
          name: '',
          email: '',
          phoneNumber: '',
          abbreviation: '',
          address: '',
          schoolCode: '',
          grades: [],
          campuses: [],
          schoolYear: ''
        }
        let lastIndex = data.length - 1
        console.log(data)
        data.forEach(async (row, index) => { 
          switch (row[0]) {
            // First row must be the school year id
            case 'School Year ID:':
              if (row[1]) {
                schoolInfo.schoolYear = parseInt(row[1].trim())
              }
              break;
            case 'School Name:':
              if (row[1]) {
                schoolInfo.name = row[1].trim()
              }
              break;
            // Second row must be the the email
            case 'Email:':
              if (row[1]) {
                schoolInfo.email = row[1].trim()
              }
              break;
            case 'Phone number:':
              if (row[1]) {
                schoolInfo.phoneNumber = row[1].trim()
              }
              break;
            case 'Abbreviation:':
              if (row[1]) {
                schoolInfo.abbreviation = row[1].trim()
              }
              break;
            case 'School code:':
              if (row[1]) {
                schoolInfo.schoolCode = row[1].trim()
                let schoolRes = await axios.get(BASE_URL + "/schools/check?school_code="+ schoolInfo.schoolCode, config)
                if (schoolRes.status === 200) {
                  setFoundSchool(true)
                  setSchool(schoolRes.data)
                }
              }
              break;
            case 'Grades (separate with commas) :':
              if (row[1]) {
                let grades = row[1].split(',')
                schoolInfo.grades = grades
              }
              break;
            case 'Campus Name:':
              if (row[1]) {
                campusInfo.name = row[1].trim()
              }
              break;
            case 'Address:':
              if (row[1]) {
                campusInfo.address = row[1].trim()
              }
              break;
            case 'Zip code:':
                if (row[1]) {
                  campusInfo.zipCode = row[1].trim()
                }
              break;
            case 'City:':
                if (row[1]) {
                  campusInfo.city = row[1].trim()
                }
              break;
            case 'State (Abbreviation):':
              if (row[1]) {
                campusInfo.state = row[1].trim()
              }
            break;
            case ' Classroom':
              if (row[1]) {
                // Add a teacher to a classroom and roin the backend make all necessary associations.
                let teacherInfo = {
                  firstName: row[3],
                  lastName: row[4],
                  phoneNumber: row[5],
                  email: row[6],
                }
                let classroomsInfo = {
                  classNnumber: row[1],
                  teacher: teacherInfo,
                  deliveryStartTime: row[2],
                }
                // Same with classrooms, just assign them here to campuses and the school-classroom relation is set in the backend.
                campusInfo.classrooms.push(classroomsInfo)
              }
              if (index + 1 === lastIndex) {
                schoolInfo.campuses.push(campusInfo)
                // If not the last row, to avoid OutOfRange Exceptions
              } else if (index + 1 <= lastIndex) {
                // Check if the next row is the separator, if so, first campus info is complete, push it and go on to the next one.
                  if ((data[index+ 1][0] === '' && data[index+ 1][1] === '' && data[index+ 1][2]  === '') 
                  || (data[index+1][0] !== ' Classroom' && index + 2 === lastIndex)) { // Check if next row is not a classroom and is last row
                  schoolInfo.campuses.push(campusInfo)
                  campusInfo = {
                    name: '',
                    address: '',
                    state: '',
                    city: '',
                    zipCode: '',
                    classrooms: []
                  }
                }
              } else if (index === lastIndex && data[index][0] === ' Classroom') {
                schoolInfo.campuses.push(campusInfo)
                campusInfo = {
                  name: '',
                  address: '',
                  state: '',
                  city: '',
                  zipCode: '',
                  classrooms: []
                }
              } 
              break;
            default:
              break;
          }
        })
        let school = removeDuplicateCampuses(schoolInfo)
        // Set the object and ready to submit
        setSchoolImport(school)
        setInvalidFile(false)
      }
    }

    const sendBulkData = async () => {
      if (!invalidFile) {
        // Submit order CSV
        try {
          setLoadingFile(true)
          let res = await axios.post(BASE_URL + '/schools/csv', {schoolInfo: schoolsImport})
          if (res.status === 200) {
            setLoadingFile(false)
            getSchools()
            setModal(false)
            setFoundSchool(false)
          } else {
            setImportError('The file you provided was not in the correct format to import the data.')
            setLoadingFile(false)
          }
        } catch(err) {
          setImportError('The file you provided was not in the correct format to import the data.')
          setLoadingFile(false)
        }
      }
    }


    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {

      return (
        <>
          <UsersTableHeader />
          {/* Page content */}
          <Container className="mt--7" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className='align-items-center justify-content-between'>
                      <Row>
                      <h4 className="ml-4 mr-4">School</h4>
                    
                      </Row>

                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Row>
                        <div class="mx-4">
                        <ResultsPerPage pageLimit={pageLimit} setPageLimit={setPageLimit} page={page} setPage={setPage} rowAmount={schools.length}/>
                        </div>
                      </Row>
                      
                      <Row className='pr-4'>
                        <Button 
                        color="success"
                        className='success' tag={Link} to='/admin/school/create'>Create</Button>
                        <Button 
                        color="success"
                        onClick={(e) => setModal(true)} >Import School CSV</Button>
                      </Row>
                    </div>
                    </Row>
                  </CardHeader>
    
                  <Form>
                    <div className="pl-lg-4">
                        <Row>
  
                                <FormGroup row className="col">

                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Name
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="schoolName"
                                        type="text"
                                        placeholder="Enter School Name"
                                        value={schoolName}

                                        onChange = {e => {
                                          setSchoolName(e.target.value)
                               
                                        }}
                                        />
                         
                                      </InputGroup>
                                    </Col> 
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Abbreviation
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="abbreviation"
                                        type="text"
                                        placeholder="Enter Abbreviation"
                                        value={abbreviation}

                                        onChange = {e => {
                                          setAbbreviation(e.target.value)
                                        }}
                                        />
                             
                                      </InputGroup>
                                    </Col>  
                        
                                </FormGroup>
                            {/* </Col>   */}
                        </Row>
                    </div>
                  </Form>
                  <Row>
                    <Col></Col>
                  </Row>
                  
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr className='text-center'>
                        <th scope="col">Name</th>
                        <th scope="col">Abbreviation</th>
                        {/* <th scope="col">Has Weekly Orders?</th> */}
                        <th scope="col" >More</th>
                      </tr>
                    </thead>
                    <tbody>
                    {schools.map(school => (
                  <tr key={school.id}className="text-center" >
                        {/* <td>{school.schoolYear.name}</td> */}
                        <td>{school.name}</td>
                        <td>{school.abbreviation}</td>
                        {/* <td>{school.has_weekly_orders}</td> */}
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href="#pablo"
                              role="button"
                              size="sm"
                              color="success"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                // href="#pablo"
                                // onClick={(e) => e.preventDefault()}
                                tag={Link}
                                to={"/admin/schools/" + school.id}
                              >
                                View
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                    </tr>
                      ))}
                      
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <Row 
                    className='align-items-center justify-content-between '
                    >
                    <nav aria-label="...">
                    <Pagination count={numberPages} color="primary" onChange={handlePageChange}/>
                    </nav>
                    <Row
                      className='align-items-center'
                    >
                      <span className="ml-4 mr-4">Show hidden schools</span>
                      <Checkbox 
                          className="ml-4 mr-4"
                        checked={showHiddenSchools}
                        onChange={(e) => {
                          e.preventDefault()
                          setShowHiddenSchools(!showHiddenSchools)
                        }}
                      />
                      </Row>
                    </Row>

                  </CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
          <Modal isOpen={modalOpen}>
            <div className="modal-header">
                <h5 className="modal-title" id="alg-modal">
                Import File
                </h5>
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => {
                  setModal(false)
                  setFoundSchool(false)
                }}
                >
                <span aria-hidden={true}>×</span>
                </button>
             </div>
        { loadingFile ? 
          <Col>
            <Row className='align-self-center justify-content-center'>
              <ReactLoading type={'bars'} color={'green'} height={'20%'} width={'10%'} />
            </Row>
            <Row className='justify-content-center'>
              <p>Importing CSV...</p>
            </Row>
          </Col>
        : (<>
              <div className="modal-body">
              <CSVReader onFileLoaded={(data, fileInfo) => parseSchoolData(data, fileInfo)} />
              { importError && importError }
              <br></br>
              <div>
                {foundSchool && <>
                <p>School information found for: <span style={{ fontWeight: 'bold' }}> {school.name} </span></p>
                <p>Saving will update the school information and create necessary grades, classrooms, teachers and campuses.</p>
                </>}
              </div>
              </div>
                <div className="modal-footer">
                    <Button
                    color="secondary"
                    data-dismiss="modal"
                    type="button"
                    active={invalidFile}
                    onClick={(e) => sendBulkData()}
                    >
                    Save
                  </Button>
            </div>
        </>)
          }
          </Modal>
        </>
      );
    }
  };

  export default SchoolsTable;
  