import {
Card,
CardHeader,
CardFooter,
DropdownMenu,
DropdownItem,
UncontrolledDropdown,
DropdownToggle,
Col,
// Pagination,
// PaginationItem,
// PaginationLink,
Table,
Container,
Row,
Form,
FormGroup,
Input,
InputGroup,
Label
} from "reactstrap";
// core components
import React, { useState, useEffect }  from 'react';
import { Link } from "react-router-dom";
import UserHeader from "components/Headers/UserHeader.js";
import axios from 'axios';
import ResultsPerPage from "components/ResultsPerPage.js";
import { DateTime } from 'luxon'
import { ReactSession } from 'react-client-session';
import { Pagination } from "@mui/material";

const MealsTable = () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const [error, setError] = useState(null);
    const [reportError, setReportError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [meals, setMeals] = useState([]);
    const [mealCreatedAt, setMealsCreatedAt] = useState('desc');

    let [page, setPage] = useState(1);
    let [pageLimit, setPageLimit] = useState(25)
    let [numberPages, setNumberPages] = useState(null)

    let [scheduledFor, setDate] = useState('')
    let [scheduledTo, setToDate] = useState('')
    let [specificDate, setSpfDate] = useState(DateTime.now().toFormat('yyyy-MM-dd'))
    let [schools, setSchools] =useState([])
    let [school, setSchool] = useState([])
    let [schoolSelected, setSchoolSelected] = useState(false)
    let [campuses, setCamps] = useState([])
    let [campus, setCampus] = useState([])
    let [selectedCampus, setSelectedCampus] = useState(false)
    let [grades, setGrades] = useState([])
    let [grade, setGrade] = useState([])
    let [classes, setClasses] = useState([])
    let [classroom, setClass] = useState([])

    let [customerEmail, setCustomerEmail] = useState('')
    let [dateRange, setDateRange] = useState(false)
    const [schoolYear, setSchoolYear] = useState('')
    const [allSchoolYears, setAllSchoolYears] = useState([])

    //  FIlter by is Late
    let [isLate, setIsLate] = useState('')
    const token = ReactSession.get("token");
    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }
  
    const getData = async () => {
       let dateQuery = dateRange ? `?range=${scheduledFor}*${scheduledTo}` : `?date=${specificDate}` 
        let query = `${dateQuery}&school=${school}&page=${page}&limit=${pageLimit}&year=${schoolYear}&email=${customerEmail}&classroom=${classroom}&campus=${campus}&grade=${grade}&isLate=${isLate}&mealCreatedAt=${mealCreatedAt}`
        try{
          let res = await axios.get(BASE_URL + '/meals' + query, config)
          setMeals(res.data.data)
          setNumberPages(res.data.meta.last_page)
          getSchools()
          getSchoolYears()
          setIsLoaded(true)
        } catch (error) {
          setError('getData: ' + error.message)
        }
    }

    const getSchools = async () => {
      try {
          let res = await axios.get(BASE_URL + '/schools/mobile ', config) 
          setSchools(res.data)
      } catch (error) {
          setError('getSchools: ' + error.message)
          setSchools([])
      }
    }

    const getCampuses = async () => {
      let query = '';
      if (school) {
          query += `?school=${school}`;
      }
      if (schoolYear) {
          query += query ? `&year=${schoolYear}` : `?year=${schoolYear}`;
      }
      let res = await axios.get(BASE_URL + '/campuses' + query);
      if (res.status === 200) {
          setCamps(res.data);
      }
    }

    const getGrades = async (schoolID) => {
      let query = `?school=${schoolID}`
      let res = await axios.get(BASE_URL + '/grades' + query, config)
      if (res.status === 200) {
        setGrades(res.data)
      }
    }

    const getClassrooms = async (campusID) => {
      let query = `?campus=${campusID}`
      let res = await axios.get(BASE_URL + '/classrooms' + query)
      
      if (res.status === 200) {
        setClasses(res.data)
      }
    }

    const dailyKitchenReport = async () => {
      document.body.style.cursor = 'wait'
      try{
        let dateQuery = dateRange ? `?range=${scheduledFor}*${scheduledTo}` : `?date=${specificDate}` 
        let query = `${dateQuery}&school=${school}&page=${page}&limit=${pageLimit}&year=${schoolYear}&email=${customerEmail}&classroom=${classroom}&campus=${campus}&grade=${grade}&isLate=${isLate}`
        let res = await axios.get(BASE_URL + '/report/daily/total/html' + query, { 
          responseType: 'blob', 
          headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': `Bearer ${token}`
          },
        })
        const temp = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a', {'id': 'download-daily-report'})
        link.href = temp
        let now = DateTime.now()
        link.setAttribute('download', `daily_report_${now}.html`) //or any other extension
        document.body.appendChild(link)
        link.click()
        document.body.style.cursor = 'default'
      } catch (error){
        console.log(error)
        document.body.style.cursor = 'default'
        setReportError('dailyKitchenReport: ' + error.message)
      }
    }

    const schoolReports = async () => {
      document.body.style.cursor = 'wait'
      try {
        let dateQuery = dateRange ? `?range=${scheduledFor}*${scheduledTo}` : `?date=${specificDate}` 
        let query = `${dateQuery}&school=${school}&page=${page}&limit=${pageLimit}&year=${schoolYear}&email=${customerEmail}&classroom=${classroom}&campus=${campus}&grade=${grade}&isLate=${isLate}`
        let res = await axios.get(BASE_URL + '/report/school' + query, { 
          responseType: 'blob', 
          headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': `Bearer ${token}`
          },
        })
        if (res.status === 200) {
          const temp = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a', {'id': 'download-school-report'})
          link.href = temp
          link.setAttribute('download', `school_reports-${specificDate}.pdf`) //or any other extension
          document.body.appendChild(link)
          link.click()
          document.body.style.cursor = 'default'
       }
      } catch(error) {
        document.body.style.cursor = 'default'
        setReportError('schoolReports: ' + error.message)
      }
    }

    const mealMessages = async () => {
      document.body.style.cursor = 'wait'
      try{
        let dateQuery = dateRange ? `?range=${scheduledFor}*${scheduledTo}` : `?date=${specificDate}` 
        let query = `${dateQuery}&school=${school}&page=${page}&limit=${pageLimit}&year=${schoolYear}&email=${customerEmail}&classroom=${classroom}&campus=${campus}&grade=${grade}&isLate=${isLate}`
        let res = await axios.get(BASE_URL + '/report/messages' + query, { 
          responseType: 'blob', 
          headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': `Bearer ${token}`
          },
        })
        if (res.status === 200) {
          const temp = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a', {'id': 'download-message-report'})
          link.href = temp
          link.setAttribute('download', `messages_report-${specificDate}.html`) //or any other extension
          document.body.appendChild(link)
          link.click()
          document.body.style.cursor = 'default'
       }
      } catch(error) {
        document.body.style.cursor = 'default'
        setReportError('mealMessages: ' + error.message)
      }
    }


    const bagLabel  = async () => {
      document.body.style.cursor = 'wait'
      try{
        let dateQuery = dateRange ? `?range=${scheduledFor}*${scheduledTo}` : `?date=${specificDate}` 
        let query = `${dateQuery}&school=${school}&page=${page}&limit=${pageLimit}&year=${schoolYear}&email=${customerEmail}&classroom=${classroom}&campus=${campus}&grade=${grade}&isLate=${isLate}`
        let res = await axios.get(BASE_URL + '/report/baglabel' + query, {
          responseType: 'blob', 
          headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': `Bearer ${token}`
          },
        })
        if (res.status === 200) {
          const temp = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a', {'id': 'download-baglabel-report'})
          link.href = temp
          link.setAttribute('download', `baglabels-${specificDate}.html`) //or any other extension
          document.body.appendChild(link)
          link.click()
          document.body.style.cursor = 'default'
        } 
      } catch(error) {
        document.body.style.cursor = 'default'
        setReportError('Bag Labels Error: ' + error.message)
      }
    }

    const foodLabels = async () => {
      document.body.style.cursor = 'wait'
      try {
        let dateQuery = dateRange ? `?range=${scheduledFor}*${scheduledTo}` : `?date=${specificDate}` 
        let query = `${dateQuery}&school=${school}&page=${page}&limit=${pageLimit}&year=${schoolYear}&email=${customerEmail}&classroom=${classroom}&campus=${campus}&grade=${grade}&isLate=${isLate}`
        let res = await axios.get(BASE_URL + '/report/foodreports' + query, { 
            responseType: 'blob', 
            headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
            'Authorization': `Bearer ${token}`
            },
        })
        if (res.status === 200) {
          const temp = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a', {'id': 'download-food-report'})
          link.href = temp
          link.setAttribute('download', `food_labels-${specificDate}.html`) //or any other extension
          document.body.appendChild(link)
          link.click()
          document.body.style.cursor = 'default'
       }
      }catch(error){
        document.body.style.cursor = 'default'
        setReportError('Food Labels Error:: ' + error.message)
      }
    }

    const getInstructionLabels = async () => {
      try {
        let dateQuery = dateRange ? `?range=${scheduledFor}*${scheduledTo}` : `?date=${specificDate}` 
        let query = `${dateQuery}&school=${school}&page=${page}&limit=${pageLimit}&year=${schoolYear}&email=${customerEmail}&classroom=${classroom}&campus=${campus}&grade=${grade}&isLate=${isLate}`
        let res = await axios.get(BASE_URL + '/report/instructions' + query, { 
          responseType: 'blob', 
          headers: {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
          'Authorization': `Bearer ${token}`
          },
        })
        if (res.status === 200) {
          const temp = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a', {'id': 'download-instructions-report'})
          link.href = temp
          link.setAttribute('download', `instructions-${specificDate}.html`) //or any other extension
          document.body.appendChild(link)
          link.click()
          document.body.style.cursor = 'default'
        }
      } catch(error){
        document.body.style.cursor = 'default'
        setReportError('Instructions Report Error: ' + error.message)
      }
    }

    const getSchoolYears = async () => {
      let res = await axios.get(BASE_URL + '/school_years/', config)
      if (res.status === 200) {
        setAllSchoolYears(res.data)
      }
    }

    const getActiveSchoolYear = async () => {
      let storedSchoolYear = localStorage.getItem('selectedSchoolYear')
      storedSchoolYear = JSON.parse(storedSchoolYear)
      if (storedSchoolYear) {
        setSchoolYear(storedSchoolYear.id)
      }
    }


    useEffect(() => {
      getCampuses();
    }, [school,
        schoolYear
        ]);

    useEffect(() => {
      getActiveSchoolYear()
    }, [])

    useEffect(() => {
        getData()
        setIsLoaded(true)
      }, [
        page,
        pageLimit,
        isLate,
        schoolYear,
        scheduledFor,
        scheduledTo,
        specificDate,
        campus,
        classroom,
        grade,
        mealCreatedAt,
      ])


      const handlePageChange = (event, value) => {
        setPage(value);
      }
    
    if (error) {
        return <div>Error: {error}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>
    } else return (<>
        <UserHeader />
        <Container className='mt--7' fluid>
            <Row>
            <div className='col'>
                <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className='justify-content-between align-items-center'>
                    <h3 className="ml-4">Meals</h3>
                    <div style={{ display: 'flex '}}>
                    <Col  xl='8'>
                      <ResultsPerPage
                        pageLimit={pageLimit}
                        setPageLimit={setPageLimit}
                        page={page}
                        setPage={setPage}
                        rowAmount={meals.length}
                      />
                    </Col>
                    <UncontrolledDropdown className='mr-4'>
                          <DropdownToggle
                          className="btn"
                          role="button"
                          size="lg"
                          color="primary"
                          onClick={(e) => e.preventDefault()}
                          >
                            Reports
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                          <DropdownItem
                          onClick={(e) => dailyKitchenReport()}
                          >
                              Daily Total Report
                          </DropdownItem>
                          <DropdownItem
                              onClick={async (e) => {
                                schoolReports()
                              }}
                          >
                              School Report
                          </DropdownItem>
                          <DropdownItem
                              onClick={async (e) => {
                                bagLabel()
                              }}
                          >
                              Bag Labels
                          </DropdownItem>
                          <DropdownItem
                              onClick={async (e) => {
                                foodLabels()
                              }}
                          >
                            Food Labels
                          </DropdownItem>
                          <DropdownItem
                              onClick={async (e) => {
                                mealMessages()
                              }}
                          >
                              Meal message Labels
                          </DropdownItem>
                          <DropdownItem
                             onClick={async (e) => {
                              getInstructionLabels()
                            }}
                          >
                            Meal Instruction labels
                          </DropdownItem>
                          {/* <DropdownItem
                              onClick={async (e) => {
                                schoolWeeklyReport()
                              }}
                          >
                              School Weekly Report
                          </DropdownItem> */}
                          </DropdownMenu>
                    </UncontrolledDropdown>
                    </div>
                  </Row>
                  <Row>
                  <div className="text-warning ml-3">{(reportError) ? <p>{reportError}</p> : ''}</div>
                  </Row>
                </CardHeader>
                <Form>
                    <div>
                      <Row className='align-items-baseline justify-content-start'>
                      <Col xl='2' className='pl-5 py-2'>
                              <Col>
                                <Label check>
                                      <Input type="checkbox" onChange={() => { 
                                        setDateRange(!dateRange)
                                        setDate('')
                                        setToDate('')
                                        setSpfDate('')
                                        }} value={!dateRange}/>
                                      Date Range
                                </Label>
                              </Col>
                      </Col>
                      </Row>
                        <Row>
                              <FormGroup row className="col m-0 justify-content-center">
                            {
                            !dateRange ?  <Col xl='2' className='p-0'>
                            <Col>
                              <label className=" form-control-label" htmlFor="basic-url">
                              Delivery Date
                              </label>
                              <InputGroup>
                              <Input
                                id="exampleSelect"
                                name="from date"
                                type="date"
                                placeholder='From date'
                                value={specificDate}
                                defaultValue={specificDate}
                                onChange = {e => {
                                    e.preventDefault()
                                    setSpfDate(e.target.value)
                                }}
                              >
                              </Input>
                              </InputGroup>
                            </Col> 
                          </Col>  :(<>
                            <Col xl='3' className='p-0'>
                                  <Col>
                                    <label className=" form-control-label" htmlFor="basic-url">
                                      From Date
                                    </label>
                                    <InputGroup>
                                    <Input
                                      id="exampleSelect"
                                      name="from date"
                                      type="date"
                                      placeholder='From date'
                                      value={scheduledFor}
                                      onChange = {e => {
                                          e.preventDefault()
                                          setDate(e.target.value)
                                      }}
                                    >
                                    </Input>
                           
                                    </InputGroup>
                                  </Col>
                            </Col>
                            <Col xl='3' className='p-0'>
                                  <Col>
                                    <label className=" form-control-label" htmlFor="basic-url">
                                    To Date
                                    </label>
                                    <InputGroup>
                                    <Input
                                      id="exampleSelect"
                                      name="from date"
                                      type="date"
                                      placeholder='From date'
                                      value={scheduledTo}
                                      onChange = {e => {
                                          e.preventDefault()
                                          setToDate(e.target.value)
                                      }}
                                    >
                                    </Input>
                                    </InputGroup>
                                  </Col>
                             </Col>
                            </>) 
                            }
                            <Col xl='2'>
                                <label className=" form-control-label" htmlFor="basic-url">
                                    School Year
                                </label>
                                <InputGroup>
                                  <Input
                                      id="category-dropdown"
                                      name="category"
                                      type="select"
                                      value={schoolYear}
                                      onChange = {e => {
                                        e.preventDefault()
                                        const newSchoolYear = e.target.value;
                                        setSchoolYear(newSchoolYear)
                                        // getData()
                                        setPage(1)
                                        if (school) {
                                          getCampuses(school, newSchoolYear)
                                        }
                                      }}
                                  >
                                    <option value="undefined">
                                        Select a School Year
                                    </option>
                                  {allSchoolYears.map((sch) => <option key={sch.id} value={sch.id}>{sch.name}</option>)}
                                  </Input>
                              </InputGroup>
                            </Col>
                            <Col xl='2'>
                                <label className="form-control-label" htmlFor="basic-url">
                                  Is Late
                                </label>
                                <InputGroup>
                                  <Input
                                      id="category-dropdown"
                                      name="category"
                                      type="select"
                                      value={isLate}
                                      onChange = {e => {
                                        e.preventDefault()
                                        setIsLate(e.target.value)
                                        setPage(1)
                                      }}
                                  >
                                    <option value="">
                                      All
                                    </option>
                                    <option value="1">
                                      True
                                    </option>
                                    <option value="0">
                                      False
                                    </option>
                                  </Input>
                              </InputGroup>
                              </Col>
                              <Col xl='2'>
                                <label className=" form-control-label" htmlFor="created_at">
                                  Order By
                                </label>
                                <InputGroup>
                                  <Input
                                  id="created_at"
                                  type="select"
                                  onChange = {e => {
                                    setMealsCreatedAt(e.target.value)
                                  }}
                                  value={mealCreatedAt}
                                  >
                                    <option key='Newest' value='desc'>Newest</option>
                                    <option key='Oldest' value='asc'>Oldest</option>
                                </Input>
                                </InputGroup>
                              </Col> 
                      </FormGroup>
                      </Row>
                      <br></br>
                      <Row class="align-items-baseline justify-content-start">
                              <FormGroup row className="col m-0 justify-content-center">
                        <Col xl='2'>
                                <label className=" form-control-label" htmlFor="basic-url">
                                    School
                                </label>
                                <InputGroup>
                                  <Input
                                      id="category-dropdown"
                                      name="category"
                                      type="select"
                                      value={school}
                                      onChange = {e => {
                                      e.preventDefault()
                                        setSchool(e.target.value)
                                        setSchoolSelected(true)
                                        setPage(1)
                                        getGrades(e.target.value)
                                        getCampuses(e.target.value)
                                      }}
                                  >
                                    <option value="">
                                        Select a School
                                    </option>
                                  {schools.map((sch) => <option key={sch.id} value={sch.id}>{sch.name}</option>)}
                                  </Input>
                              </InputGroup>
                            </Col>
                            <Col xl='2'>
                                <label className=" form-control-label" htmlFor="basic-url">
                                    Campus
                                </label>
                                <InputGroup>
                                  <Input
                                      id="category-dropdown"
                                      name="campus"
                                      type="select"
                                      disabled={!schoolSelected} 
                                      value={campus}
                                      onChange = {e => {
                                      e.preventDefault()
                                      setCampus(e.target.value)
                                      setSelectedCampus(true)
                                      getClassrooms(e.target.value)
                                      setPage(1)
                                      }}
                                  >
                                    <option value="">
                                        Select a Campus
                                    </option>
                                  {school && campuses.map((campus) => <option key={campus.id} value={campus.id}>{campus.name}</option>)}
                                  </Input>
                              </InputGroup>
                            </Col> 
                            <Col xl='2'>
                                <label className=" form-control-label" htmlFor="basic-url">
                                    Grade
                                </label>
                                <InputGroup>
                                  <Input
                                      id="category-dropdown"
                                      name="grade"
                                      type="select"
                                      value={grade}
                                      disabled={!schoolSelected} 
                                      onChange = {e => {
                                      e.preventDefault()
                                      setGrade(e.target.value)
                                      setPage(1)
                                      }}
                                  >
                                    <option value="">
                                        Select a Grade
                                    </option>
                                  {grades.map((grade) => <option key={grade.id} value={grade.id}>{grade.name}</option>)}
                                  </Input>
                              </InputGroup>
                            </Col> 
                            <Col xl='2'>
                                <label className=" form-control-label" htmlFor="basic-url">
                                    Classroom
                                </label>
                                <InputGroup>
                                  <Input
                                      id="category-dropdown"
                                      name="classroom"
                                      type="select"
                                      value={classroom}
                                      disabled={!selectedCampus} 
                                      onChange = {e => {
                                        e.preventDefault()
                                        setClass(e.target.value)
                                        setPage(1)
                                      }}
                                  >
                                    <option value="">
                                        Select a Classroom
                                    </option>
                                  {classes.map((croom) => <option key={croom.id} value={croom.id}>{croom.class_number}</option>)}
                                  </Input>
                              </InputGroup>
                            </Col> 
                        </FormGroup>
                      </Row>
                    </div>
                  </Form>
                  <br></br>
                  <Row className=''>
                    <Col xl='2' className=""/>
                     
                  </Row>
                  <br></br>
                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr className='text-center'>
                        <th scope="col">ID</th>
                        <th scope="col">Product</th>
                        <th scope="col">Reciever</th>
                        <th scope="col">Delivery Date</th>
                        <th scope="col">More</th>
                      </tr>
                    </thead>
                      {meals.length > 0  ?
                      <>
                    <tbody>
                         {meals.map(meal => (
                      <tr key={meal.id} className='text-center'>
                          <td>{meal.id}</td>
                          <td>{meal.products.name}</td>
                          <td><Link to={`/admin/students/${meal.customer_id}`}>{meal.receiver_name}</Link></td>
                          <td>{Intl.DateTimeFormat('en-US').format(DateTime.fromSQL(meal.scheduled_for))}</td>
                          {/* <td>{Intl.DateTimeFormat('en-US').format(new Date(Date.parse(meal.scheduled_for)))}</td> */}
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                href="#pablo"
                                role="button"
                                size="sm"
                                color="success"
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem
                                  tag={Link}
                                  to={"/admin/meal/" + meal.id}
                                >
                                  View
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                    }
                    </tbody>
                      </> 
                       : <tbody><tr className='justify-content-center'><td className='mt-3'>No meals on file</td></tr></tbody>
                    }
                  </Table>
                  <CardFooter className="">
                  <Pagination count={numberPages} color="primary" onChange={handlePageChange}/>

                  {/* 
                        <nav aria-label="...">
                        <Pagination
                            className="pagination justify-content-end mb-0"
                            listClassName="justify-content-end mb-0"
                        >
                        <PaginationItem>
                            <PaginationLink
                                onClick={(e) => {
                                e.preventDefault();
                                let prevpage = +page - +1;
                                if (prevpage >= 1) setPage(prevpage)
                                }}
                            >
                                <i className="fas fa-angle-left" />
                                <span className="sr-only">Previous</span>
                            </PaginationLink>
                            </PaginationItem>
                            {[...Array(numberPages)].map((x, i) =>
                            <PaginationItem key={i} className={`${page === (i+1) ? "active" : ""}`}>
                                <PaginationItem className="active" />
                                <PaginationLink
                                onClick={(e) => { 
                                    setPage(e.target.innerHTML)
                                }}
                                >
                                {i + 1}
                                </PaginationLink>
                            </PaginationItem>
                            )}
                            <PaginationItem>
                            <PaginationLink
                                onClick={(e) => {
                                    e.preventDefault();
                                    let nextpage = +page + +1;
                                    if(nextpage <= numberPages) setPage(nextpage)
                                }}
                            >
                                <i className="fas fa-angle-right" />
                                <span className="sr-only">Next</span>
                            </PaginationLink>
                            </PaginationItem>
                        </Pagination>
                        </nav> */}
                    </CardFooter>
                </Card>
             </div>      
            </Row>
        </Container>
    </>)
}

export default MealsTable