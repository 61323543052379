

import UsersTableHeader from "components/Headers/UsersTableHeader.js"
import {
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    Col,
    Button,
    Modal
  } from "reactstrap"
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import ResultsPerPage from "components/ResultsPerPage.js";
import Skeleton from 'react-loading-skeleton'
import axios from 'axios'
import { ReactSession } from 'react-client-session';
import { Link } from "react-router-dom";

const SchoolClassroomTable = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL
  let { id } = useParams()
  let [ classrooms, setClassrooms ] = useState([])
  let [ isLoading, setLoad ] = useState(true)

  let [schoolYear, setSchoolYear] = useState("");
  const [schoolYearId, setSchoolYearId] = useState("");
  let [activeYear, setActiveYear] = useState("");
  let [className, setName] = useState("");
  let [classId, setClassID] = useState("");
  let [campus, setCampus] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState([""]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);


  let [page, setPage] = useState(1);
  let [pageLimit, setPageLimit] = useState(25);
  let [numberPages, setNumberPages] = useState(null);
  let [schoolYears, setSchoolYears] = useState([]);
  let { register, handleSubmit, reset, formState, control } = useForm();


  const token = ReactSession.get("token");
  let config = {
    headers: {
      'Authorization': `Bearer ${token}` 
    }
  }

  const getData = async () => {
    if (localStorage.getItem('selectedSchoolYear') != null && schoolYear === "") {
          setSchoolYear(JSON.parse(localStorage.getItem('selectedSchoolYear')))
          setSchoolYearId(JSON.parse(localStorage.getItem('selectedSchoolYear')).id)
    }
    getSchoolYears()
    let params = + "page=" + page + "&limit=" + pageLimit + "&school_year_id=" + schoolYearId + "&class_number=" + className + "&id=" + classId + "&campus_id=" + campus
    let data = await axios.get(BASE_URL  + '/schools/' + id + '/classroom/?' + params)
    if (data.status === 200) {
        setClassrooms(data.data.data)
        // setNumberPages(data.data.meta.last_page)
        setLoad(false)
    }
  } 

  const getSchoolYears = async () => {
    let res = await axios.get(BASE_URL + '/school_years/', config)
    if (res.status === 200) {
      setSchoolYears(res.data)
    }
  }

  
    useEffect(() => {
        getData()
        getSchoolYears()
    }, [page, pageLimit, schoolYear, className, classId, campus])
    return (
        <>
        <UsersTableHeader />
        <Container className="mt--7" fluid>
        <Card>
        <CardHeader>
              <Row className='align-items-center justify-content-between'>
              <h1 className="ml-4 mr-4"> School Classrooms </h1>
                {/* <Row className='pr-4'>
                  <Button 
                  color="success"
                  className='success' tag={Link} to='/admin/classrooms/create'>Create</Button>
                </Row> */}
              </Row>
            </CardHeader>
            <br></br>
            <Form>
                    <div className="pl-lg-4">
                        <Row>
                            {/* <Col > */}
                                <FormGroup row className="col">
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        School Year
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="schoolYear"
                                        type="select"
                                        value={schoolYear ? schoolYear.id : ""}
                                        onChange = {e => {
                                          setSchoolYear(e.target.value)
                                          setSchoolYearId(e.target.value)
                                          setCampus("")
                                          setPage(1)
                                        }}
                                        >
                                          <option disabled value="">
                                          {"Select a School Term"}
                                          </option>
                                        {schoolYears.map(year => (
                                          <option key={year.id} value={year.id}>
                                              {year.name}
                                          </option>
                                        ))}

                                        </Input>
                                      </InputGroup>
                                    </Col>
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Classroom
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="className"
                                        type="text"
                                        placeholder="Enter Classroom"
                                        value={className}

                                        onChange = {e => {
                                          setName(e.target.value)
                                        }}
                                        />
                                      </InputGroup>
                                    </Col>
                                    {/* <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        ID
                                      </label>
                                      <InputGroup>
                                        <Input
                                            id="exampleSelect"
                                            name="id"
                                            type="number"
                                            placeholder="Enter Teacher ID"
                                            value={classId}

                                            onChange = {e => {
                                             setClassID(e.target.value)
                                            }}
                                        />
                                    
                                      </InputGroup>
                                    </Col>   */}
                                </FormGroup>
                            {/* </Col>   */}
                        </Row>
                        <Row class="align-items-baseline">
                            <Col xl='4'>
                              <FormGroup row className="col">
                                <label className=" form-control-label" 
                                htmlFor="basic-url">
                                    Campus
                                </label>
                                <InputGroup>
                                  <Input
                                      id="category-dropdown"
                                      name="campus"
                                      type="select"
                                      value={campus}
                                      onChange = {e => {
                                        e.preventDefault()
                                        setCampus(e.target.value)
                                        setPage(1)
                                      }}
                                  >
                                    <option value="">
                                        Select a Campus
                                    </option>
                                    {[...new Set(classrooms.map((classroom) => classroom.campus.id))].map((id) => {
                                      const campus = classrooms.find((classroom) => classroom.campus.id === id).campus;
                                      return campus;
                                    }).filter((campus, index, arr) => arr.findIndex(c => c.id === campus.id) === index).map((campus) => (
                                      <option key={campus.id} value={campus.id}>
                                        {campus.name}
                                      </option>
                                    ))}
                                  </Input>
                                </InputGroup>
                              </FormGroup> 
                            </Col>
                            <Col>
                            <label className="form-control-label" htmlFor="basic-url">
                                    &#8205; 
                            </label>
                            <Col><ResultsPerPage pageLimit={pageLimit} setPageLimit={setPageLimit} page={page} setPage={setPage}/></Col>
                            </Col>
                        </Row>
                    </div>
                  </Form>
            <CardBody>
            {
            isLoading ?
            <>
             <Skeleton count={5}/>
            </>
            :   
             <Table>
                <thead className='thead-light'>
                    <tr className='text-center'>
                    <th>ID</th>
                    <th>Classroom</th>
                    <th>Campus</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                    {classrooms.map((classroom) => (
                      <tr key={classroom.id} className='text-center'>
                        <td>
                            {classroom.id}
                        </td>
                        <td>{classroom.class_number}</td>
                        <td>{classroom.campus.name}</td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              color="success"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={() => {
                                    setModalData(classroom); 
                                    setModalOpen(!modalOpen)}}
                              >
                                Edit
                              </DropdownItem>
                              <DropdownItem
                                onClick={(e) => {
                                  setModalData(classroom)
                                    fetch(BASE_URL  + '/classrooms/' + classroom.id + "/edit", {
                                      method: 'PUT',
                                      body: JSON.stringify({isDeleted: true}),
                                      headers: { 'Content-Type': 'application/json' },
                                    })
                                        .then(res => res.json())
                                        .then(
                                            (data) => {
                                                setPage(1)
                                                setIsLoaded(true);
                                                getData()
                                            },
                                            (error) => {
                                                setIsLoaded(true);
                                                setError(error);
                                            }
                                        )
                                }}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    ))}
                    <Modal
                        className="modal-dialog-centered"
                        size="sm"
                        isOpen={modalOpen} 
                        >
                        <div className="modal-body p-0">
                            <Card className="bg-secondary shadow border-0">
                            <CardBody className="px-lg-5 py-lg-5">
                                <Form role="form" onSubmit={handleSubmit(data => {
                                  console.log('sending changes')
                                  fetch(BASE_URL  + '/classrooms/' + modalData.id + "/edit", {
                                        method: 'PUT',
                                        body: JSON.stringify({classNumber: data.newName, deliveryStartTime: data.newStartTime, deliveryEndTime: data.newEndTime}),
                                        headers: { 'Content-Type': 'application/json' },
                                      })
                                      .then(res => res.json())
                                        .then(
                                            (data) => {
                                                fetch(BASE_URL + '/schools/' + id + '/classroom/?' + "page=" + page + "&limit=" + pageLimit + "&school_year_id=" + schoolYear + "&class_number=" + className + "&id=" + classId + "&campus_id=" + campus, config)
                                                    .then(res => res.json())
                                                    .then(
                                                        (data) => {
                                                            setClassrooms(data.data);
                                                            setIsLoaded(true);
                                                            reset({"name":""});
                                                            setModalOpen(!modalOpen);
                                                        },
                                                        (error) => {
                                                            setIsLoaded(true);
                                                            setError(error);
                                                        }
                                                    )
                                                setIsLoaded(true);
                                            },
                                            (error) => {
                                                setIsLoaded(true);
                                                setError(error);
                                            }
                                        )
                                  })}>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-username"
                                        >
                                        Edit Classroom: {modalData.class_number}
                                        </label>
                                        <div className="modal-body">
                                          <Row>
                                            <Col>
                                              <label
                                              className="form-control-label"
                                              htmlFor="class_number"
                                              >
                                              Class Number
                                              </label>
                                              <Controller
                                              name="newName"
                                              control={control}
                                              render={({ field }) => <Input {...field}  placeholder={modalData.class_number} />}
                                              />
                                            </Col>
                                          </Row>
                                          <br />
                                          {/* <Row className='justify-content-center align-items-betweeen'>
                                            <Col xl="6">
                                              <label
                                              className="form-control-label"
                                              htmlFor="start_time"
                                              >
                                              Delivery Start Time
                                            </label>
                                            <input
                                              name="newStartTime"
                                              className="form-control"
                                              id="end_time"
                                              type="time"
                                              placeholder={modalData.deliveryStartTime}
                                              {...register('end_time')}
                                              />
                                      
                                            </Col>
                                            <Col xl="6">
                                            <label
                                                className="form-control-label"
                                                htmlFor="end_time"
                                                >
                                                Delivery End Time
                                              </label>
                                              <input
                                              name="newEndTime"
                                              className="form-control"
                                              id="end_time"
                                              type="time"
                                              placeholder={modalData.deliveryEndTime}
                                              {...register('end_time')}
                                              />
                                            </Col>
                                          </Row> */}
                                      </div>
                                        <div className="modal-footer">
                                            <Button
                                                color="secondary"
                                                data-dismiss="modal"
                                                type="button"
                                                onClick={(e) => setModalOpen(!modalOpen)}
                                            >
                                            Close
                                            </Button>
                                            <Button
                                                color="primary"
                                                data-dismiss="modal"
                                                type="submit"
                                            >
                                            Save
                                            </Button>
                                        </div>
                                </Form>
                            </CardBody>
                            </Card>
                        </div>
                      </Modal>
                </tbody>
            </Table>
               }
            </CardBody>
            <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem className="disabled">
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>

                        
                        {[...Array(numberPages)].map((x, i) =>
                            <PaginationItem key={i} className={`${page === (i+1) ? "active" : ""}`}>
                            {/* <PaginationItem className="active"> */}
                            <PaginationLink
                              onClick={(e) => { 
                                  setPage(e.target.innerHTML)
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        )}

                
                        <PaginationItem>
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                   </CardFooter>
        </Card>
        </Container>
        </>
    ) 
}

export default SchoolClassroomTable