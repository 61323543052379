
// reactstrap components
import {
Card,
CardHeader,
CardBody,
ListGroup,
ListGroupItem,
Button,
Modal,
Col,
Container,
Row,
Form,
InputGroup,
Input,
} from "reactstrap";
// core components
import React, { useState, useEffect }  from 'react';
import UserHeader from "components/Headers/UserHeader.js";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { ReactSession } from 'react-client-session';
import { useHistory } from "react-router-dom";



const SidesTable = () => {
    const { handleSubmit, register, control, reset } = useForm();
    const BASE_URL = process.env.REACT_APP_BASE_URL
    const [error, setError] = useState(null);
    const [snackFormError, setSnackFormError] = useState(null);
    const [snackEditError, setSnackEditError] = useState(null);
    const [drinkFormError, setDrinkFormError] = useState(null);
    const [drinkEditError, setDrinkEditError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const[sortSnackOrder, setSortSnackOrder] = useState('asc')
    let [snacks, setSnacks] = useState([])
    let [drinks, setDrinks] = useState([])
    let [snackOnEdit, setSnack] = useState([])
    let [ drinkOnEdit, setDrink] = useState([])
    // Modal States
    let [snackFormOpen, openSnacksForm] = useState(false)
    let [drinkFormOpen, openDrinksForm] = useState(false)
    let [types, setTypes] = useState([]);
    let navigate = useHistory(); 


    const token = ReactSession.get("token");
    // console.log('AllergiesTable token: ' + token)

    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }

    const getData = async () => {
        getSnacks()
        getDrinks()
        getTypes()
        setIsLoaded(true)
    }

    const getTypes = async () => {
        try {
            let res = await axios.get(BASE_URL + '/types', config)
            setTypes(res.data)
        } catch (error) {
            // console.log(error.message)
            setError('getTypes: ' + error.message)
            setTypes([])
        }
    }

    const getSnacks = async () => {
        try{
            
            let res = await axios.get(BASE_URL + '/addons/snacks/admin', config)
            setSnacks(res.data)

        }catch(error){
            setError('getSnacks: ' + error.message)
            setSnacks([])
        }
    }

    const getDrinks = async () => {
        try{
            let res = await axios.get(BASE_URL + '/addons/drinks/admin', config)
            setDrinks(res.data)
        }catch(error){
            setError('getDrinks: ' + error.message)
            setDrinks([])
        }
        
    }

    const snackFormHandling = async (data, e) => {
        let snackData = {
            price: data.snack_price,
            name: data.snack_name,
            snackOrder: data.snack_order,
            typeId: data.snack_type
        }

        try{
            await axios.post(BASE_URL + '/addons/create/snack', snackData, config)
            openSnacksForm(false)
            getData()
            reset();
        }catch(error){
            setSnackFormError(error.message)
        }
        
    }

    const drinkFormHandling = async (data, e) => {
        let drinkData = {
            price: data.drink_price,
            name: data.drink_name,
            drinkOrder: data.drink_order,
            typeId: data.drink_type
        }
        try{
            await axios.post(BASE_URL + '/addons/create/drink', drinkData, config)
            openDrinksForm(false)
            reset();
            getData()
        }
        catch(error){
            setDrinkFormError(error.message)
        }
        
    }

    const editSnack = async (data, e) => {
        let snackData = {
            price: data.snack_price,
            name: data.snack_name,
            snackOrder: data.snack_order,
        }
        if (snackData.name === '') {
            setSnackEditError('Make sure to provide valid values.')
            return
        }
        try{
            await axios.put(BASE_URL +  `/addons/snacks/${snackOnEdit}/edit`, snackData, config)
            getData()
            setSnack([])
            reset({snack_name: '', snack_price: 1.0})
        }catch(error){
            setSnackEditError(error.message)
        }
       
    }

    const editDrink = async (data, e) => {
        let drinkData = {
            price: data.drink_price,
            name: data.drink_name,
            drinkOrder: data.drink_order,
        }
        if (drinkData.name === '') {
            setSnackEditError('Make sure to provide valid values.')
            return
        }
        try{
            await axios.put(BASE_URL +  `/addons/drinks/${drinkOnEdit}/edit`, drinkData, config)
            getData()
            setDrink([])
            reset({drink_name: '', drink_price: 1.0})
        }catch(error){
            setDrinkEditError(error.message)
        }
       
    }

    const toggleSnack = async (snackID) => {
        try{
            await axios.put(BASE_URL  + `/addons/snacks/${snackID}/toggle`, {}, config)
            getData()
        }catch(error){
            setSnackEditError(error.message)
        }
        
    }
    const toggleDrink = async (drinkID) => {
        try{
            await axios.put(BASE_URL  + `/addons/drinks/${drinkID}/toggle`, {}, config)
            getData()
        }catch(error){
            setDrinkEditError(error.message)
        }
        
    }

    useEffect(() => {
       getData()
    }, [sortSnackOrder])




    if (error) {
        return <div>Error: {error}</div>;
    } 
    else if(!isLoaded) {
        return <>Loading...</>
    } else return (<>
    <UserHeader />
    <Container className='mt--7' fluid>
        <Row>
            <Col xl='6'>
                <Card className='shadow'>
                    <CardHeader className="bg-white border-0">
                        <Row className='justify-content-between'>
                        <h2 className='ml-4'>Snacks</h2>
                      
                        <Button size='sm' color='success' className='mr-4' onClick={(e) => {
                                e.preventDefault()
                                openSnacksForm(true)
                                }} >
                                Add
                            </Button>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <div className="text-warning ml-3">{(snackEditError) ? <p>{snackEditError}</p> : ''}</div>
                        <Row className='justify-content-between'>
                            <Col sm='4' className='ml-4'>Snack Name</Col>
                            <Col>Rank</Col>
                            <Col>Price</Col>
                            <Col></Col>
                        </Row>

                        <ListGroup>
                        {snacks.map((snack) => {
                            return  <ListGroupItem key={snack.id}> 
                                    {(
                                    <Row className='justify-content-between'>
                                        <Col className='ml-4' md='4'><span >{snack.name} - {snack.type.type_name}</span></Col>
                                        <Col md='1'><span>{snack.sort_order}</span></Col>
                                        <Col className='ml-5'> <span>{snack.price === 0 ? null : `$${snack.price}`}</span></Col>
                                        <Col className='ml-2'> <Button size='sm' color='success' className='mr-4' value={snack.id} onClick={(e) => navigate.push('/admin/snack/'+  snack.id)} >
                                                view
                                            </Button></Col>
                                    
                                    </Row>
                                    )
                                    }
                                </ListGroupItem>
                         })}
                        </ListGroup>
                    </CardBody>
                </Card>
            </Col>
            <br></br>
            <br></br>
            <Col xl='6'>
                <Card className="bg-white border-0 shadow">
                    <CardHeader className="bg-white border-0">
                    <Row className='justify-content-between'>
                            <h2 className='ml-4'>Drinks</h2>
                            <Button size='sm' color='success' className='mr-4' onClick={(e) => {
                                e.preventDefault()
                                openDrinksForm(true)
                                }} >
                                Add
                            </Button>
                        </Row>
                    </CardHeader>
                    <CardBody>
                    <div className="text-warning ml-3">{(drinkEditError) ? <p>{drinkEditError}</p> : ''}</div>
                    <Row className='justify-content-between'>
                            <Col sm='4' className='ml-4'>Drink Name</Col>
                            <Col >Rank</Col>
                            <Col >Price</Col>
                            <Col ></Col>
                        </Row>

                    <ListGroup>
                    {drinks.map((drink) => {
                            return  <ListGroupItem key={drink.id}> 
                                    {(
                                    <Row className='justify-content-between'>
                                    <Col className='ml-4' md='4'><span>{drink.name} - {drink.type.type_name}</span></Col>
                                    <Col md='1'><span >{drink.sort_order}</span></Col>
                                    <Col className='ml-5'> <span>{drink.price === 0 ? null : `$${drink.price}`}</span></Col>
                                    <Col className='ml-2'><Button size='sm' color='success' className='mr-4' value={drink.id} onClick={(e) => navigate.push('/admin/drink/'+  drink.id)} >
                                        View
                                    </Button></Col>
                                    {/* <Row> 
                                    <span className='mr-4'>{drink.price === 0 ? null : `$${drink.price}`}</span>
                                    <Button size='sm' color='success' className='mr-4' value={drink.id} onClick={(e) => navigate.push('/admin/drink/'+  drink.id)} >
                                        View
                                    </Button>
                                    </Row> */}
                                    </Row>
                                    )
                        }
                        </ListGroupItem>
                        })}
                    </ListGroup>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        <Modal isOpen={snackFormOpen}>
            <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                Create a Snack
                </h3>
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => openSnacksForm(false)}
                >
                <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="text-warning ml-3">{(snackFormError) ? <p>{snackFormError}</p> : ''}</div>
            <Form onSubmit={handleSubmit(snackFormHandling)}>
                <div className="modal-body">
                    <Row className='align-items-center ml-3 mr-3'>
                        <Col lg="4">
                            <label
                            className="form-control-label"
                            htmlFor="snack_name"
                            >
                            Name
                            </label>
                            <input
                            className="form-control"
                            id="snack_name"
                            placeholder="Name"
                            type="text"
                            {...register('snack_name')}
                            required
                            />
                        </Col>
                        <Col lg="4">
                            <label
                            className="form-control-label"
                            htmlFor="snack_price"
                            >
                            Price
                            </label>
                            <input
                            className="form-control"
                            id="snack_price"
                            placeholder="0.0"
                            type="text"
                            {...register('snack_price')}
                            required
                            />
                        </Col>
                    </Row>
                    <Row className='align-items-center ml-3 mr-3'>
                    <Col lg="4">
                            <label
                            className="form-control-label"
                            htmlFor="snack_sort_order"
                            >
                            Order
                            </label>
                            <input
                            className="form-control"
                            id="snack_sort_order"
                            placeholder="0.0"
                            type="text"
                            {...register('snack_sort_order')}
                            required
                            />
                        </Col>
                        <Col lg="4">
                            <label
                            className="form-control-label"
                            htmlFor="snack_type"
                            >
                            Type
                            </label>
                            <select 
                                className="form-control"
                                name='snack_type'
                                id="snack_type"
                                {...register('snack_type')}
                                
                                >
                                
                                <option value="0">
                                        Select a Type
                                    </option>
                                {types.map((typ) => <option key={typ.id} value={typ.id}>{typ.type_name}</option>)}
                                
                            </select>
                        </Col>
                        
                    </Row>
                    <Row className='align-items-center ml-3 mr-3'>
                        <Col lg='4'>
                            <Button
                            className='mt-4'
                            color="primary"
                            data-dismiss="modal"
                            type="submit"
                            >
                            Save
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                </div>
            </Form>
        </Modal>
        <Modal isOpen={drinkFormOpen}>
            <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                Create a Drink
                </h3>
                <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={(e) => openDrinksForm(false)}
                >
                <span aria-hidden={true}>×</span>
                </button>
            </div>
            <div className="text-warning ml-3">{(drinkFormError) ? <p>{drinkFormError}</p> : ''}</div>

            <Form onSubmit={handleSubmit(drinkFormHandling)}>
                <div className="modal-body">
                    <Row className='align-items-center ml-3 mr-3'>
                        <Col lg="4">
                            <label
                            className="form-control-label"
                            htmlFor="drink_name"
                            >
                            Name
                            </label>
                            <input
                            className="form-control"
                            id="drink_name"
                            placeholder="Name"
                            type="text"
                            {...register('drink_name')}
                            required
                            />
                        </Col>
                        <Col lg="4">
                            <label
                            className="form-control-label"
                            htmlFor="drink_name"
                            >
                            Price
                            </label>
                            <input
                            className="form-control"
                            id="drink_name"
                            placeholder="Price"
                            type="text"
                            {...register('drink_price')}
                            required
                            />
                        </Col>
                        <Col lg="4">
                            <label
                            className="form-control-label"
                            htmlFor="drink_type"
                            >
                            Type
                            </label>
                            <select 
                                className="form-control"
                                name='drink_type'
                                id="drink_type"
                                {...register('drink_type')}
                                
                                >
                                
                                <option value="0">
                                        Select a Type
                                    </option>
                                {types.map((typ) => <option key={typ.id} value={typ.id}>{typ.type_name}</option>)}
                                
                            </select>
                        </Col>
                    </Row>
                    <Row className='align-items-center ml-3 mr-3'>
                        <Col lg='4'>
                        <Button
                            className='mt-4'
                            color="primary"
                            data-dismiss="modal"
                            type="submit"
                            >
                            Save
                            </Button>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                </div>
            </Form>
        </Modal>
    </Container>
    </>)

}


export default SidesTable