import UsersTableHeader from "components/Headers/UsersTableHeader.js"
import {
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Dropdown,
    DropdownToggle,
    Media,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    Table,
    Container,
    Row,
    Col,
    Button,
    Modal
  } from "reactstrap"
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import ResultsPerPage from "components/ResultsPerPage.js";
import Skeleton from 'react-loading-skeleton'
import axios from 'axios'
import { ReactSession } from 'react-client-session';
import { Link } from "react-router-dom";

const SchoolTeachersTable = () => {
    let { id } = useParams()
    let [ teachers, setTeachers ] = useState([])
    let [ isLoading, setLoad ] = useState(true)
    const BASE_URL = process.env.REACT_APP_BASE_URL

    const [schoolYear, setSchoolYear] = useState("");
    const [teachersName, setName] = useState("");
    const [teacherLast, setLast] = useState("");
    const [teacherID, setTeacherID] = useState("");
    const [address, setAddress] = useState("");
    const [weeklyOrders, setWeeklyOrders] = useState("");

    const [page, setPage] = useState(1);
    const [pageLimit, setPageLimit] = useState(25);
    const [numberPages, setNumberPages] = useState(null);
    const [schoolYears, setSchoolYears] = useState([]);
    const { register, handleSubmit, reset, formState, control } = useForm();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState([""]);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const token = ReactSession.get("token");

    let config = {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }

    const getData = async () => {
      if (localStorage.getItem('selectedSchoolYear') != null && schoolYear === "") {
        setSchoolYear(JSON.parse(localStorage.getItem('selectedSchoolYear')))
      }
        let params = + "page=" + page + "&limit=" + pageLimit + "&school_year_id=" + schoolYear.id + "&first_name=" + teachersName  + '&last_name=' + teacherLast  +  "&id=" + teacherID + "&address=" + address + "&has_weekly_orders=" + weeklyOrders
        let data = await axios.get(BASE_URL  + '/schools/' + id + '/teachers/?' + params)
        if (data.status === 200) {
            setTeachers(data.data.data)
            setNumberPages(data.data.meta.last_page)
            setLoad(false)
        }
    }
    
    const getSchoolYears = async () => {
      let res = await axios.get(BASE_URL + '/school_years/', config)
      if (res.status === 200) {
        setSchoolYears(res.data)
      }
    }
    const sendPutRequest = async (url, data, headers = {}) => {
      try {
          console.log(data);
          const response = await axios.put(url, data, { headers });
          return response;
      } catch (error) {
          console.error('Error occurred while sending PUT request:', error);
          return error;
      }
    };

    const handleFormSubmit = async (data) => {
      const url = `${BASE_URL}/teachers/${modalData.id}/edit`;
      const payload = {
          firstName: data.newFirstName,
          lastName: data.newLastName,
          phoneNumber: data.newPhoneNumber,
          email: data.newEmail
      };
      const headers = { 'Content-Type': 'application/json' };
      
      const response = await sendPutRequest(url, payload, headers);
  
      if (response && response.status === 200) {
          getData();
          setIsLoaded(true);
          window.location.reload();
      }
  };

    useEffect(() => {
        getData()
        getSchoolYears()
    }, [page, pageLimit, schoolYear, teachersName, teacherID, address, weeklyOrders, teacherLast])
    return (
        <>
        <UsersTableHeader />
        <Container className="mt--7" fluid>
        <Card>
            <CardHeader>
              <Row className='align-items-center justify-content-between'>
              <h1 className="ml-4 mr-4"> School Teachers </h1>
              </Row>
            </CardHeader>
            <br></br>
            <Form>
                    <div className="pl-lg-4">
                        <Row>
                                <FormGroup row className="col">
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        First Name
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="firstName"
                                        type="text"
                                        placeholder="Enter First Name"
                                        value={teachersName}

                                        onChange = {e => {
                                          setName(e.target.value)
                                        }}
                                        />
                                      </InputGroup>
                                    </Col>
                                    <Col >
                                      <label className=" form-control-label" htmlFor="basic-url">
                                        Last Name
                                      </label>
                                      <InputGroup>
                                        <Input
                                        id="exampleSelect"
                                        name="lastName"
                                        type="text"
                                        placeholder="Enter Last Name"
                                        value={teacherLast}

                                        onChange = {e => {
                                          setLast(e.target.value)
                                        }}
                                        />
                                      </InputGroup>
                                    </Col> 
                                </FormGroup>
                        </Row>
                    </div>
                  </Form>
                  <Row>
                    <Col>
                    <ResultsPerPage pageLimit={pageLimit} setPageLimit={setPageLimit} page={page} setPage={setPage}/>
                    </Col>
                  </Row>
            <CardBody>
            {
            isLoading ?
            <>
             <Skeleton count={5}/>
            </>
            :   
             <Table>
                <thead className='thead-light'>
                    <tr className='text-center'>
                    <th>ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Campus</th>
                    <th>Classroom</th>
                    <th></th>
                    </tr>
                </thead>
                <tbody>
                  {teachers.map((teacher) => (
                    <tr className='text-center'>
                      <td>
                          {teacher.id}
                      </td>
                      <td>{teacher.first_name}</td>
                      <td>{teacher.last_name}</td>
                      <td>{teacher.classroom.campus.name}</td>
                      <td>{teacher.classroom.class_number}</td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            role="button"
                            size="sm"
                            color="success"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              onClick={() => {
                                  setModalData(teacher); 
                                  setModalOpen(!modalOpen)}}
                            >
                              Edit
                            </DropdownItem>
                            <DropdownItem
                                onClick={(e) => {
                                  // setModalData(teacher)
                                  fetch(BASE_URL  + '/teachers/' + teacher.id + "/edit", {
                                    method: 'PUT',
                                    body: JSON.stringify({isDeleted: true}),
                                    headers: { 'Content-Type': 'application/json' },
                                  })
                                      .then(res => res.json())
                                      .then(
                                          (data) => {
                                              console.log(data)
                                              // setPage(1)
                                              // setIsLoaded(true);
                                              getData()
                                          },
                                          (error) => {
                                              setIsLoaded(true);
                                              setError(error);
                                          }
                                      )
                                }}
                              >
                                Delete
                              </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                  <Modal
                      className="modal-dialog-centered"
                      size="med"
                      isOpen={modalOpen} 
                      >
                      <div className="modal-body p-0">
                          <Card className="bg-secondary shadow border-0">
                          <CardBody className="px-lg-5 py-lg-5">
                              <Form role="form" onSubmit={handleSubmit(data => {
                                handleFormSubmit(data)
                              })}
                              >
                              <label
                                className="form-control-label"
                                htmlFor="input-username"
                                >
                                Edit Teacher: {modalData.first_name} {modalData.last_name}
                                </label>
                                <div className="modal-body">
                                    <Row>
                                    <Col lg="6">
                                    <label
                                    className="form-control-label"
                                    htmlFor="teacher_first_name"
                                    >
                                      First Name
                                    </label>
                                    <Controller
                                    name="newFirstName"
                                    control={control}
                                    render={({ field }) => <Input {...field}  placeholder={modalData.first_name} />}
                                      />
                                </Col>
                                <Col lg="6">
                                    <label
                                    className="form-control-label"
                                    htmlFor="teacher_last_name"
                                    >
                                    Last Name 
                                    </label>
                                    <Controller
                                    name="newLastName"
                                    control={control}
                                    render={({ field }) => <Input {...field}  placeholder={modalData.last_name} />}
                                      />
                                </Col>
                            </Row>
                          <br />
                            <Row>
                            <Col lg="6">
                                  <label
                                  className="form-control-label"
                                  htmlFor="teacher_phone"
                                  >
                                  Phone Number
                                  </label>
                                  <Controller
                                    name="newPhoneNumber"
                                    control={control}
                                    render={({ field }) => <Input {...field}  placeholder={modalData.phone_number} />}
                                      />
                            </Col>
                            <Col lg="6">
                                  <label
                                  className="form-control-label"
                                  htmlFor="teacher_email"
                                  >
                                  Email 
                                  </label>
                                  <Controller
                                    name="newEmail"
                                    control={control}
                                    render={({ field }) => <Input {...field}  placeholder={modalData.email} />}
                                      />
                            </Col>
                            </Row>
                            </div>
                            <div className="modal-footer">
                                <Button
                                    color="secondary"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={(e) => setModalOpen(!modalOpen)}
                                >
                                Close
                                </Button>
                                <Button
                                    color="primary"
                                    data-dismiss="modal"
                                    type="submit"
                                >
                                Save
                                </Button>
                            </div>
                            </Form>
                          </CardBody>
                          </Card>
                      </div>
                  </Modal>
                </tbody>
            </Table>
               }
            </CardBody>
            <CardFooter className="py-4">
                    <nav aria-label="...">
                      <Pagination
                        className="pagination justify-content-end mb-0"
                        listClassName="justify-content-end mb-0"
                      >
                        <PaginationItem className="disabled">
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            tabIndex="-1"
                          >
                            <i className="fas fa-angle-left" />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>

                        
                        {[...Array(numberPages)].map((x, i) =>
                            <PaginationItem key={i} className={`${page === (i+1) ? "active" : ""}`}>
                            {/* <PaginationItem className="active"> */}
                            <PaginationLink
                              onClick={(e) => { 
                                  setPage(e.target.innerText)
                              }}
                            >
                              {i + 1}
                            </PaginationLink>
                          </PaginationItem>
                        )}

                
                        <PaginationItem>
                          <PaginationLink
                            // href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-angle-right" />
                            <span className="sr-only">Next</span>
                          </PaginationLink>
                        </PaginationItem>
                      </Pagination>
                    </nav>
                   </CardFooter>
        </Card>
        </Container>
        </>
    ) 
}

export default SchoolTeachersTable