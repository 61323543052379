import axios from 'axios'

export default class HttpClient {
    constructor(baseUrl) {
        this.baseUrl = baseUrl
    }
    get(url, config) {
        return axios.get(this.baseUrl + url, config)
    }

    post(url, data, config) {
        return axios.post(this.baseUrl + url, data, config)
    }

    put(url, data, config) {
        return axios.put(this.baseUrl + url, data, config)
    }
}